import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  BarController
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  BarController
);

const LineDashBoardContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

type ImpactLineProps = {
  data_1?: number[];
  data_2?: number[];
  data_3?: number[];
  data_4?: number[];
  data_5?: number[];
  data_6?: number[];
};

export default function ImpactLine({ data_1 = [], data_2 = [], data_3 = [], data_4 = [], data_5 = [], data_6 = [] }: ImpactLineProps) {
  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  function filterData(data: number[]) {
    const firstIndexWithData = data.findIndex(value => value > 0);
    const lastIndexWithData = data.length - 1 - [...data].reverse().findIndex(value => value > 0);
    return firstIndexWithData >= 0 && lastIndexWithData >= 0 ? data.slice(firstIndexWithData, lastIndexWithData + 1) : [];
  }

  const filteredData1 = filterData(data_1);
  const filteredData2 = filterData(data_2);
  const filteredData3 = filterData(data_3);
  const filteredData4 = filterData(data_4);
  const filteredData5 = filterData(data_5);
  const filteredData6 = filterData(data_6);

  const startIndex = data_1.findIndex(value => value > 0);
  const endIndex = data_1.length - 1 - [...data_1].reverse().findIndex(value => value > 0);
  const filteredLabels = labels.slice(startIndex, endIndex + 1);

  const datasets = [
    {
      type: 'bar' as const,
      label: 'Plástico',
      data: filteredData2,
      backgroundColor: '#f72e03',
    },
    {
      type: 'bar' as const,
      label: 'Vidro',
      data: filteredData3,
      backgroundColor: '#37f703',
    },
    {
      type: 'bar' as const,
      label: 'Metais',
      data: filteredData4,
      backgroundColor: '#f7f303',
    },
    {
      type: 'bar' as const,
      label: 'Papel e Papelão',
      data: filteredData5,
      backgroundColor: '#0337f7',
    },
    {
      type: 'bar' as const,
      label: 'Orgânicos',
      data: filteredData6,
      backgroundColor: '#A52A2A',
    },
    {
      type: 'line' as const,
      label: 'Total Coletado',
      data: filteredData1,
      borderColor: '#000000',
      borderWidth: 2,
      pointBackgroundColor: '#000000',
      fill: false,
    }
  ].filter(dataset => dataset.data.length > 0); // Filtra para remover conjuntos de dados vazios

  const data = {
    labels: filteredLabels,
    datasets
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 15,
          padding: 20,
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 14
          }
        },
        title: {
          display: true,
          text: '2024',
          font: {
            size: 16,
            weight: 'bold'
          }
        }

      },
      y: {
        ticks: {
          font: {
            size: 14,
            weight: 'bold'
          },
          callback: function(value: number) {
            return `${value} Kg`; // Adiciona "Kg" aos valores do eixo Y
          }
        },
        title: {
          display: true,
          text: 'Total Destinado (Kg)',
          font: {
            size: 16,
            weight: 'bold'
          }
        }
      }
    }
  };

  return (
    <LineDashBoardContainer>
      <Chart type="bar" data={data} options={options} />
    </LineDashBoardContainer>
  );
}