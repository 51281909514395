import { ICollect, blankCollect } from './Collect';
import { ICompany, blankCompany } from './Company';
import { IMaterial } from './Material';
import { IRouteEvent, blankRouteEvent } from './RouteEvent';

export interface ICollectEvent {
  id: number;
  score?: number;
  score_by_collector?: number;
  sequence: number;
  status: number;
  executed_at?: string;
  obs?: string;
  materials: Array<IMaterial>;
  cancelation_reason?: string;
  weighing_id?: number;
  company_id: number;
  company?: ICompany;
  collect_id: number;
  collect?: ICollect;
  route_event_id: number;
  routeEvent?: IRouteEvent;
  mtr_number?: string;
}

export const blankCollectEvent: ICollectEvent = {
  id: 0,
  score: 0,
  score_by_collector: 0,
  sequence: 0,
  status: 0,
  executed_at: '',
  obs: '',
  materials: [],
  cancelation_reason: '',
  weighing_id: undefined,
  company_id: 0,
  company: blankCompany,
  collect_id: 0,
  collect: blankCollect,
  route_event_id: 0,
  routeEvent: blankRouteEvent,
  mtr_number: ''
};

export const collectEventStatus = [
  { id: 0, nameApk: 'Pendente', name: 'Agendada', color: '#35A1B7', iconUrl: 'MapMarkerAgendada.svg' },
  { id: 1, nameApk: 'Cancelada', name: 'Cancelada', color: '#730E18', iconUrl: 'MapMarkerCancelada.svg' },
  { id: 2, nameApk: 'Atrasada', name: 'Atrasada', color: '#FFD140', iconUrl: 'MapMarkerAtrasada.svg' },
  { id: 3, nameApk: 'Andamento', name: 'Em andamento', color: '#008300', iconUrl: 'MapMarkerAndamento.svg' },
  { id: 4, nameApk: 'Finalizada', name: 'Finalizada', color: '#133940', iconUrl: 'MapMarkerFinalizada.svg' },
  { id: 5, nameApk: 'Avaliada', name: 'Avaliada', color: '#008300', iconUrl: 'MapMarkerAvaliada.svg' },
  { id: 6, nameApk: 'Pesada', name: 'Pesada', color: '#133940', iconUrl: 'MapMarkerAvaliada.svg' }
];

export const cancelationReasons = [
  { id: 0, name: '' },
  { id: 1, name: 'Sem material' },
  { id: 2, name: 'Sem acesso' },
  { id: 3, name: 'Sem área de atendimento' },
  { id: 4, name: 'Outros' }
];

export const collectEventProps = Object.keys(blankCollectEvent);
