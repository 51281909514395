import api from './api';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { ICollect, blankCollect, collectProps } from '../models/Collect';
import { toast } from 'react-toastify';

export default class CollectService {
  static async getAll(): Promise<ICollect[]> {
    try {
      const response = await api.get('/collect');
      let allCollects = response.data as ICollect[];
      return allCollects;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas');
      return [];
    }
  }

  static async getById(id: number): Promise<ICollect> {
    try {
      const response = await api.get(`/collect/${id}`);
      let r = mapNullToString(response.data);
      return r;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas!');
      return blankCollect;
    }
  }

  static async getByRouteEventId(id: number): Promise<ICollect[]> {
    try {
      const response = await api.get(`/collect?route_event_id=${id}`);
      let allCollects = response.data as ICollect[];
      return allCollects;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas!');
      return [];
    }
  }
  static async getByRoute(route_id: number): Promise<ICollect[]> {
    try {
      const response = await api.get(`/routes/${route_id}/collects`);
      let allCollects = response.data as ICollect[];

      return allCollects;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas');
      return [];
    }
  }

  static async create({ id, ...collect }: ICollect): Promise<ICollect> {
    try {
      this.standarlizeCollects(collect);
      const response = await api.post('/collect', collect);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir coletas');
        return blankCollect;
      }
      toast.success('Coletas incluídas com sucesso!');
      return response.data as ICollect;
    } catch (error: any) {
      toast.error('Erro ao incluir coletas!');
      return blankCollect;
    }
  }
  static async update({ ...collect }: ICollect): Promise<boolean> {
    try {
      this.standarlizeCollects(collect);
      let response = await api.put(`/collect/${collect.id}`, collect);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar coletas!');
        return false;
      }
      toast.success('Coleta atualizada com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar coletas!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/collect/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir coletas!');
        return false;
      }
      toast.success('Coleta excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir coletas!');
      return false;
    }
  }

  static standarlizeCollects(r: ICollect | any): ICollect {
    r.company_id = Number(r.company_id);
    r.company_id = r.company_id || undefined;
    r.vehicle_id = Number(r.vehicle_id);
    r.vehicle_id = r.vehicle_id || undefined;
    return r;
  }
}
