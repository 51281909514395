export interface IContainer {
  id: number;
  name: string;
  img: string;
  codInea: number;
}

export const blankContainer: IContainer = {
  id: 0,
  name: '',
  img: '',
  codInea: 0
};

export const containerProps = Object.keys(blankContainer);
