import { Delete, Download } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import { Button, Stack, Typography } from '@mui/material';
import 'dayjs/locale/pt-br';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_TableInstance, type MRT_Row } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConfirmModal } from '../../components/ConfirmModal';
import { LoadWrapper, PageContainer, PageContent, PageFooter, PageHeader, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { IPositiveImpact } from '../../models/PositiveImpact';
import PositiveImpactService from '../../services/PositiveImpactService';
import ResidueService from '../../services/ResidueService';
import { IResidue } from '../../models/Residue';
import { ITreatment } from '../../models/Treatment';
import TreatmentService from '../../services/TreatmentService';
import { all } from 'axios';

export default function PositiveImpactPage() {
  const [allPositiveImpacts, setAllPositiveImpacts] = useState<IPositiveImpact[]>([]);
  const [allResidues, setAllResidues] = useState<IResidue[]>([]);
  const [allTreatments, setAllTreatments] = useState<ITreatment[]>([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const tableInstanceRef = useRef<MRT_TableInstance<IPositiveImpact>>(null);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  async function fetchData() {
    setLoading(true);
    let allResidues = await ResidueService.getAll();
    setAllResidues(allResidues);
    let allTreatments = await TreatmentService.getAll();
    setAllTreatments(allTreatments);
    let allPositiveImpacts = await PositiveImpactService.getAll();
    allPositiveImpacts.forEach((pi) => {
      pi.residue_name = allResidues.find((r) => r.id === pi.residue_id)?.description;
      pi.treatment_name = allTreatments.find((t) => t.id === pi.treatment_id)?.name;
    });
    setAllPositiveImpacts(allPositiveImpacts);

    console.log(allPositiveImpacts);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleNew() {
    navigate('/positive_impacts/0');
    fetchData();
  }

  // Exclui o bairro
  function handleDeleteConfirmation() {
    if (Object.keys(rowSelection).length === 0) {
      toast.error('Selecione um Impacto de Residuos para excluir');
      return;
    }
    setShowConfirmationModal(true);
  }

  // Busca o usuário no backend e abre o modal de edição
  async function handleEditRow(row: MRT_Row<IPositiveImpact>) {
    navigate(`/positive_impacts/${Number(row.id)}`);
  }

  //handle delete
  function handleDelete() {
    setLoading(true);
    for (let prop in rowSelection) {
      PositiveImpactService.delete(Number(prop));
    }
    setShowConfirmationModal(false);
    setTimeout(() => {
      fetchData();
    }, 300);
  }

  // Export to csv
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = allPositiveImpacts.map((c) => {
      return {
        id: c.id,
        residue_name: c.residue_name,
        treatment_name: c.treatment_name,
        avoid_co2: c.avoid_co2,
        ref_avoid_co2: c.ref_avoid_co2,
        water_saved: c.water_saved,
        ref_water_saved: c.ref_water_saved,
        energy_saved: c.energy_saved,
        ref_energy_saved: c.ref_energy_saved,
        oil_saved: c.oil_saved,
        ref_oil_saved: c.ref_oil_saved,
        minerals_saved: c.minerals_saved,
        ref_minerals_saved: c.ref_minerals_saved,
        mineral_name: c.mineral_name,
        price: c.price,
        ref_price: c.ref_price
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  const columns = useMemo<MRT_ColumnDef<IPositiveImpact>[]>(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        size: 5
      },
      {
        header: 'Residuo',
        accessorKey: 'residue_name'
      },
      {
        header: 'Tecnologia/Tratamento',
        accessorKey: 'treatment_name'
      },
      {
        header: 'CO2 Evitado',
        accessorKey: 'avoid_co2'
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>IMPACTO DOS RESÍDUOS</PageTitle>
        </PageHeader>
        <PageContent>
          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {allPositiveImpacts.length >= 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={allPositiveImpacts}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false } }}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNew} variant="contained">
                    NOVO IMPACTO
                  </Button>
                  <Button color="primary" onClick={handleDeleteConfirmation} variant="contained" startIcon={<Delete />}>
                    Excluir
                  </Button>
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained">
                    Exportar
                  </Button>
                </Stack>
              )}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
            />
          )}

          <ConfirmModal
            title="Deleção de Impacto de Residuos(s)"
            message="Deseja excluir o(s) Impacto(s)?"
            isOpen={showConfirmationModal}
            onConfirm={handleDelete}
            onCancel={() => setShowConfirmationModal(false)}
          />
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
