import api from './api';
import { IWeighing, blankWeighing, weighingProps } from '../models/Weighing';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class WeighingService {
  static async getAll(): Promise<IWeighing[]> {
    try {
      const response = await api.get('/weighing');
      let allWeighings = response.data as IWeighing[];
      // let role = getAuthUser().role;
      return allWeighings;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar pesagens');
      return [];
    }
  }
  static async getById(id: number): Promise<IWeighing> {
    try {
      const response = await api.get(`/weighing/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, weighingProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar pesagem!');
      return blankWeighing;
    }
  }

  static async create({ id, ...weighing }: IWeighing): Promise<IWeighing> {
    try {
      this.standarlizeWeighings(weighing);
      const response = await api.post('/weighing', weighing);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir pesagem');
        return blankWeighing;
      }
      toast.success('Pesagem incluída com sucesso!');
      return response.data as IWeighing;
    } catch (error: any) {
      toast.error('Erro ao incluir pesagem!');
      return blankWeighing;
    }
  }
  static async update({ ...weighing }: IWeighing): Promise<boolean> {
    try {
      this.standarlizeWeighings(weighing);
      let response = await api.put(`/weighing/${weighing.id}`, weighing);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar pesagem!');
        return false;
      }
      toast.success('Pesagem atualizada com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar pesagem!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/weighing/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir pesagem!');
        return false;
      }
      toast.success('Pesagem excluída com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir pesagem!');
      return false;
    }
  }

  static standarlizeWeighings(v: IWeighing | any): IWeighing {
    if (v.company_id === '0') {
      v.company_id = undefined;
    }
    if (!v.company_id) {
      v.company_id = undefined;
    }
    return v;
  }
}

export default WeighingService;
