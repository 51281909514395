import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Icon, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { IAddress, blankAddress } from '../models/Address';
import { BigTextField, MediumTextField, SmallTextField } from './TextFields';
import InputMask from 'react-input-mask';
import { getModelFromField } from '../utils/MyLib';
import { ZipService } from '../services/ZipService';
import { allStatesNames } from '../models/States';
import { toast } from 'react-toastify';
import { validateRequired } from '../utils/validations';

interface ModalProps {
  initialValue: IAddress;
  onClose: () => void;
  onSubmit: (address: IAddress) => void;
  open: boolean;
}

export default function AddressModal({ initialValue, onClose, onSubmit, open }: ModalProps) {
  const [errors, setErrors] = useState<any>({});
  const [address, setAddress] = useState<IAddress>(initialValue);

  useEffect(() => {
    setErrors({});
    setAddress(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(address: IAddress) {
    let errors: any = {};
    if (!validateRequired(address.zip_code)) errors.code = 'Obrigatorio';
    if (!validateRequired(address.street)) errors.class = 'Obrigatorio';
    if (!validateRequired(address.city)) errors.description = 'Obrigatorio';
    if (!validateRequired(address.neighborhood)) errors.description = 'Obrigatorio';
    if (!validateRequired(address.state)) errors.description = 'Obrigatorio';
    if (!validateRequired(address.number)) errors.description = 'Obrigatorio';

    return errors;
  }

  async function handleBlurZipCode(e: any) {
    let zipCode = e.target.value;
    let address = blankAddress;
    if (zipCode[9] !== '_') {
      address = await ZipService.getAddress(zipCode);
      if (address.street) {
        setAddress(address);
      } else {
      }
    }
  }

  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (model) {
      case 'address':
        setAddress({ ...address, [field]: value });
        break;
      default:
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(address);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onSubmit(address);
      onClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle textAlign="left">Endereço da coleta</DialogTitle>
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <Grid container>
          <Grid item xs={12} md={1.5}>
            <Stack>
              <InputMask mask="99.999-999" name="address.zip_code" value={address.zip_code} onChange={handleChange} onBlur={handleBlurZipCode} maskChar="">
                <SmallTextField label="CEP" error={errors.zip_code} helperText={errors.zip_code} sx={{ maxWidth: 200 }} />
              </InputMask>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Stack>
              <BigTextField key="address.street" label="Rua" name="address.street" value={address.street} onChange={handleChange} error={errors.street} helperText={errors.street} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={1.2}>
            <Stack>
              <SmallTextField
                key="address.number"
                label="Número"
                name="address.number"
                value={address.number}
                onChange={handleChange}
                error={errors.number}
                helperText={errors.number}
                sx={{ maxWidth: 200 }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={2.8}>
            <Stack>
              <BigTextField
                key="address.complement"
                label="Complemento"
                name="address.complement"
                value={address.complement}
                onChange={handleChange}
                error={errors.complement}
                helperText={errors.complement}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={3}>
            <Stack>
              <MediumTextField
                key="address.neighborhood"
                label="Bairro"
                name="address.neighborhood"
                value={address.neighborhood}
                onChange={handleChange}
                error={errors.neighborhood}
                helperText={errors.neighborhood}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={5}>
            <Stack>
              <MediumTextField key="address.city" label="Cidade" name="address.city" value={address.city} onChange={handleChange} error={errors.city} helperText={errors.city} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={2}>
            <Stack>
              <FormControl sx={{ maxWidth: 150, m: 1 }} margin="dense">
                <InputLabel>Estado</InputLabel>
                <Select key="address.state" label="Estado" name="address.state" value={address.state} onChange={handleChange} error={errors.address} placeholder="Estado">
                  {allStatesNames.map((state) => (
                    <MenuItem key={state.abbreviation} value={state.abbreviation}>
                      {state.abbreviation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
