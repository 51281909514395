import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { useState } from 'react';
import styled from 'styled-components';
import { type } from 'os';
import { formatDate } from '../utils/MyLib';

const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;

type Props = {
  selectedDates: string[];
  onDateChange: (date: string) => void;
  onRemoveClick: (index: number) => void;
};

export default function Date({ selectedDates, onDateChange, onRemoveClick }: Props) {
  // Crie um estado para armazenar as datas selecionadas
  // const [selectedDates, setSelectedDates] = useState<string[]>([]);

  // Função para atualizar as datas selecionadas
  const handleDateChange = (newDate: string) => {
    // setSelectedDates([...selectedDates, newDate]);
    const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
    onDateChange(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['StaticDatePicker']}>
        <DemoItem>
          <StaticDatePicker
            value={null}
            onChange={(date) => {
              if (date) {
                handleDateChange(date);
              }
            }}
          />
          <div className="selected-day-container">
            <div>
              <p style={{ fontWeight: '700' }}>Datas Selecionadas:</p>
            </div>
            <Separator></Separator>
            <div className="selected-day">
              {selectedDates.map((date, index) => (
                <div className="single-day-container">
                  <p key={index}>{formatDate(date)}</p>
                  <RemoveRoundedIcon key={index} sx={{ cursor: 'pointer' }} onClick={() => onRemoveClick(index)}></RemoveRoundedIcon>
                </div>
              ))}
            </div>
          </div>
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
