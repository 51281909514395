import api from './api';
import { IPositiveImpact, blankPositiveImpact, positiveImpactProps } from '../models/PositiveImpact';
import { mapNullToString, clearObj, standarlizeField } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class PositiveImpactService {
  static async getAll(): Promise<IPositiveImpact[]> {
    try {
      const response = await api.get('/positive-impact');
      let allPositiveImpacts = response.data as IPositiveImpact[];
      return allPositiveImpacts;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Impactos Positivoss');
      return [];
    }
  }
  static async getById(id: number): Promise<IPositiveImpact> {
    try {
      const response = await api.get(`/positive-impact/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, positiveImpactProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Impactos Positivos!');
      return blankPositiveImpact;
    }
  }

  static async create({ id, ...positiveImpact }: IPositiveImpact): Promise<IPositiveImpact> {
    try {
      this.standarlizePositiveImpact(positiveImpact);
      const response = await api.post('/positive-impact', positiveImpact);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir Impactos Positivos');
        return blankPositiveImpact;
      }
      toast.success('Impacto incluído com sucesso!');
      return response.data as IPositiveImpact;
    } catch (error: any) {
      toast.error('Erro ao incluir Impactos Positivos!');
      return blankPositiveImpact;
    }
  }
  static async update({ ...positiveImpact }: IPositiveImpact): Promise<boolean> {
    try {
      this.standarlizePositiveImpact(positiveImpact);
      let response = await api.put(`/positive-impact/${positiveImpact.id}`, positiveImpact);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar Impactos Positivos!');
        return false;
      }
      toast.success('Impacto atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar Impactos Positivos!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/positive-impact/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir Impactos Positivos!');
        return false;
      }
      toast.success('Impacto excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir Impactos Positivos!');
      return false;
    }
  }

  static standarlizePositiveImpact(c: IPositiveImpact | any): IPositiveImpact {
    c.avoid_co2 = Number(c.avoid_co2);
    c.water_saved = Number(c.water_saved);
    c.energy_saved = Number(c.energy_saved);
    c.oil_saved = Number(c.oil_saved);
    c.minerals_saved = Number(c.minerals_saved);
    c.price = Number(c.price);
    console.log(c);
    return c;
  }
}

export default PositiveImpactService;
