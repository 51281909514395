import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { baseImg } from '../../App';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { AvatarUpload } from '../../components/AvatarUpload';
import { ConfirmModal } from '../../components/ConfirmModal';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { BigTextField, MediumTextField } from '../../components/TextFields';
import { blankResidue, IResidue } from '../../models/Residue';
import { ImgUploadService } from '../../services/ImgUploadService';
import ResidueService from '../../services/ResidueService';
import { getModelFromField } from '../../utils/MyLib';
import { validateRequired } from '../../utils/validations';
import { IResidueState } from '../../models/ResidueState';
import { IResidueClass } from '../../models/ResidueClass';
import { IUnit } from '../../models/Unit';
import ResidueClassService from '../../services/ResidueClassService';
import ResidueStateService from '../../services/ResidueStateService';
import UnitService from '../../services/UnitService';

const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;

export default function ResidueForm() {
  const [residue, setResidue] = useState<IResidue>(blankResidue);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir a residuo?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allResidueStates, setAllResidueStates] = useState<IResidueState[]>([]);
  const [allResidueClasses, setAllResidueClasses] = useState<IResidueClass[]>([]);
  const [allResidueUnits, setAllResidueUnits] = useState<IUnit[]>([]);
  let img = residue.img ? baseImg + residue.img : '';
  let fileImage: File = new File([], '');

  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    try {
      let allResidueClasses = await ResidueClassService.getAll();
      setAllResidueClasses(allResidueClasses);
      let allResidueStates = await ResidueStateService.getAll();
      setAllResidueStates(allResidueStates);
      let allResidueUnits = await UnitService.getAll();
      setAllResidueUnits(allResidueUnits);

      let residue = blankResidue;
      if (Number(id)) {
        residue = await ResidueService.getById(Number(id));
      }
      setResidue(residue);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(residue: IResidue) {
    let errors: any = {};
    if (!validateRequired(residue.code)) errors.code = 'Obrigatorio';
    if (!validateRequired(residue.description)) errors.description = 'Obrigatorio';
    if (!residue.class_id) errors.class_id = 'Obrigatorio';
    if (!residue.unit_id) errors.unit_id = 'Obrigatorio';
    if (!residue.state_id) errors.state_id = 'Obrigatorio';
    return errors;
  }
  function saveImage(residue: IResidue, fileImage: File): void {
    if (fileImage.size > 0) {
      if (fileImage.size <= 1000000) {
        ImgUploadService.upload('residues', residue.id, fileImage);
      } else {
        toast.error('Imagem muito grande, tamanho máximo de 1mb');
      }
    }
  }
  async function save(residue: IResidue) {
    if (residue.id) {
      if (await ResidueService.update(residue)) {
        saveImage(residue, fileImage);
        toast.success('Resíduo salvo com sucesso');
      }
    } else {
      let newResidue = await ResidueService.create(residue);
      if (newResidue.id > 0) {
        residue.id = newResidue.id;
        saveImage(residue, fileImage);
        toast.success('Resíduo criado com sucesso');
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(residue);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      save(residue);
      navigate('/residues', { state: { reload: true } });
    }
  }

  function handleDeleteConfirmation(residue: IResidue) {
    setConfirmMessage('Deseja excluir "' + residue.description + '" ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await ResidueService.delete(residue.id)) {
      toast.success('Resíduo apagada com sucesso');
      residue.id = -1;
      navigate('/residues', { state: { reload: true } });
    }
  }

  //Get values on form inputs and put into the model Residue
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      default:
        setResidue({ ...residue, [field]: value });
        break;
    }
  }
  async function handleAvatarUpload(files: any) {
    fileImage = files[0];
  }
  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="lg">
          <DialogTitle textAlign="center" sx={{ textTransform: 'none' }}>
            REGISTRO DE TIPOS DE RESIDUO
          </DialogTitle>
          <Separator />
          <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Stack>
                    <FormControl sx={{ maxWidth: '100%', m: 1, paddingBottom: '10px' }} margin="dense">
                      Residuo
                      <AvatarUpload src={img} imgDefault="x" width={100} height={100} radius={50} handleUpload={handleAvatarUpload} />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Stack>
                      <InputMask mask={'99.99.99'} name="residue.code" value={residue.code} onChange={handleChange} maskChar=" ">
                        <MediumTextField label="Código Ibama" error={errors.code} helperText={errors.code} />
                      </InputMask>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack>
                      <FormControl sx={{ maxWidth: '100%', m: 1, paddingBottom: '10px' }} margin="dense">
                        <BigTextField
                          key="residue.description"
                          label="Descrição"
                          name="residue.description"
                          value={residue.description}
                          onChange={handleChange}
                          error={errors.description}
                          placeholder="Descrição"
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack>
                      <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
                        <InputLabel>Classe</InputLabel>
                        <Select key="residue.class_id" label="Classe" name="residue.class_id" value={residue.class_id} onChange={handleChange} error={errors.class_id} placeholder="Classe">
                          {allResidueClasses.map((r) => (
                            <MenuItem key={'r' + r.id} value={r.id}>
                              {r.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack>
                      <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
                        <InputLabel>Unidade</InputLabel>
                        <Select key="residue.unit_id" label="Unidade" name="residue.unit_id" value={residue.unit_id} onChange={handleChange} error={errors.unit_id} placeholder="Unidade">
                          {allResidueUnits.map((r) => (
                            <MenuItem key={'r' + r.id} value={r.id}>
                              {r.name} ({r.abreviation})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack>
                      <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
                        <InputLabel>Estado</InputLabel>
                        <Select key="residue.state_id" label="Estado" name="residue.state_id" value={residue.state_id} onChange={handleChange} error={errors.state_id} placeholder="Estado">
                          {allResidueStates.map((r) => (
                            <MenuItem key={'r' + r.id} value={r.id}>
                              {r.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button
              color="warning"
              disabled={residue.id === 0}
              onClick={() => {
                handleDeleteConfirmation(residue);
              }}>
              Apagar
            </Button>
            <Button
              onClick={() => {
                navigate('/residues');
              }}>
              Voltar
            </Button>
            <Button color="secondary" onClick={handleSubmit} variant="contained">
              Salvar
            </Button>
          </DialogActions>
          <ConfirmModal title="Deleção de Resíduo" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
