export interface INeighborhood {
  id: number;
  neighborhood: string;
  city: string;
  state: string;
  company_id: number;
  company_name?: string;
  lat?: string;
  lng?: string;
}

export const blankNeighborhood: INeighborhood = {
  id: 0,
  neighborhood: '',
  city: '',
  state: '',
  company_id: 0,
  company_name: '',
  lat: '',
  lng: ''
};

export const neighborhoodProps = Object.keys(blankNeighborhood);
