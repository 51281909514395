import api from './api';
import { ITreatment, blankTreatment, treatmentProps } from '../models/Treatment';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class TreatmentService {
  static async getAll(): Promise<ITreatment[]> {
    try {
      const response = await api.get('/treatment');
      let allTreatments = response.data as ITreatment[];
      return allTreatments;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Tecnologias de Tratamentos');
      return [];
    }
  }
  static async getById(id: number): Promise<ITreatment> {
    try {
      const response = await api.get(`/treatment/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, treatmentProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Tecnologias de Tratamento!');
      return blankTreatment;
    }
  }

  static async create({ id, ...treatment }: ITreatment): Promise<ITreatment> {
    try {
      this.standarlizeTreatment(treatment);
      const response = await api.post('/treatment', treatment);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir Tecnologias de Tratamento');
        return blankTreatment;
      }
      toast.success('Veículo incluído com sucesso!');
      return response.data as ITreatment;
    } catch (error: any) {
      toast.error('Erro ao incluir Tecnologias de Tratamento!');
      return blankTreatment;
    }
  }
  static async update({ ...treatment }: ITreatment): Promise<boolean> {
    try {
      this.standarlizeTreatment(treatment);
      let response = await api.put(`/treatment/${treatment.id}`, treatment);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar Tecnologias de Tratamento!');
        return false;
      }
      toast.success('Veículo atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar Tecnologias de Tratamento!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/treatment/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir Tecnologias de Tratamento!');
        return false;
      }
      toast.success('Veículo excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir Tecnologias de Tratamento!');
      return false;
    }
  }

  static standarlizeTreatment(c: ITreatment | any): ITreatment {
    c.codInea = Number(c.codInea);
    return c;
  }
}

export default TreatmentService;
