import api from './api';
import { IPlan, blankPlan, planProps } from '../models/Plan';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class PlanService {
  static async getAll(): Promise<IPlan[]> {
    try {
      const response = await api.get('/plans');
      let allPlans = response.data as IPlan[];
      return allPlans;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar planos');
      return [];
    }
  }
  static async getById(id: number): Promise<IPlan> {
    try {
      const response = await api.get(`/plans/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, planProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar plano!');
      return blankPlan;
    }
  }

  static async create({ id, ...plan }: IPlan): Promise<IPlan> {
    try {
      this.standarlizePlan(plan);
      const response = await api.post('/plans', plan);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir plano');
        return blankPlan;
      }
      toast.success('Veículo incluído com sucesso!');
      return response.data as IPlan;
    } catch (error: any) {
      toast.error('Erro ao incluir plano!');
      return blankPlan;
    }
  }
  static async update({ ...plan }: IPlan): Promise<boolean> {
    try {
      this.standarlizePlan(plan);
      let response = await api.put(`/plans/${plan.id}`, plan);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar plano!');
        return false;
      }
      toast.success('Veículo atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar plano!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/plans/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir plano!');
        return false;
      }
      toast.success('Veículo excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir plano!');
      return false;
    }
  }

  static standarlizePlan(v: IPlan | any): IPlan {
    v.price = Number(v.price);
    return v;
  }
}

export default PlanService;
