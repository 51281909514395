export interface ITreatment {
  id: number;
  name: string;
  link: string;
  codInea: number;
}

export const blankTreatment: ITreatment = {
  id: 0,
  name: '',
  link: '',
  codInea: 0
};

export const treatmentProps = Object.keys(blankTreatment);
