import {
  Avatar,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses
} from '@mui/material';
import styled from 'styled-components';
import { baseImg } from '../../App';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { IRouteEvent, blankRouteEvent, routeEventStatus } from '../../models/RouteEvent';
import RouteEventService from '../../services/RouteEventService';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { getModelFromField } from '../../utils/MyLib';
import { PageContainer } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { useNavigate, useParams } from 'react-router-dom';
import { INeighborhood } from '../../models/Neighborhood';
import { ICompany } from '../../models/Company';
import { IVehicle } from '../../models/Vehicle';
import { IUser } from '../../models/User';
import NeighborhoodService from '../../services/NeighborhoodService';
import VehicleService from '../../services/VehicleService';
import UserService from '../../services/UserService';
import CompanyService from '../../services/CompanyService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { ConfirmModal } from '../../components/ConfirmModal';
import RouteService from '../../services/RouteService';
import CollectEventService from '../../services/CollectEventService';
import RoutePath from '../../components/RoutePath';
import MapLeaflet from '../../components/MapLeaflet';
import { ICollectEvent, blankCollectEvent, collectEventStatus } from '../../models/CollectEvent';
import CardsContainerModal from '../../components/CardsContainerModal';
import CardsContainerTeste from '../../components/CardsContainerTeste';
import SubCardComponent from '../../components/SubCard';
import Switch from '@mui/material/Switch';
import CollectEventSubCard from '../CollectEvent/CollectEventSubCard';
import React from 'react';
import AddressBox from '../../components/AddressBox';
import ExpandableSection from '../../components/ExpandableSection';
import { IAddress, blankAddress } from '../../models/Address';
import AddressModal from '../../components/AddressModal';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

const Separator = styled.hr`
  border: 1px solid lightgray; /* Cor da linha branca */
`;

const P = styled.p`
  font-weight: 700;
`;

const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
`;

const SubCard = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 10px;
  min-width: 80%;
  border-radius: var(--borderRadius-m);
  background: var(--bgColor-1);
  box-shadow: var(--shadow-1);
  margin-top: 8px;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 12,
    border: '1px solid #dadde9'
  }
}));

//! O Formato do figma não comporta multiplas datas...

export default function RouteEventForm() {
  const [errors, setErrors] = useState<any>({});
  const [routeEvent, setRouteEvent] = useState<IRouteEvent>(blankRouteEvent);
  const [collectEvents, setCollectEvents] = useState<ICollectEvent[]>([]);
  const [modal, setModal] = useState(true);
  const [colorStatus, setColorStatus] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allCollectors, setAllCollectors] = useState<IUser[]>([]);
  const [allVehicles, setAllVehicles] = useState<IVehicle[]>([]);
  const [allCompanies, setAllCompanies] = useState<ICompany[]>([]);
  const [checked, setChecked] = useState(true);
  const [modalAddress, setModalAddress] = useState(false);
  const [address, setAddress] = useState<IAddress>(blankAddress);
  const [addressEdited, setAddressEdited] = useState(0);
  const [selectedCollectEventId, setSelectedCollectEventId] = useState<number>(0);
  const [isExpandInfo, setIsExpandInfo] = useState<boolean>(true);
  const [isExpandCollect, setIsExpandCollect] = useState<boolean>(true);

  const confirmMessage = 'Tem certeza que deseja apagar o evento de rota?';

  const colors = routeEventStatus.map((status) => status.color);
  // border: 2px solid ${(props) => colors[props.status]};

  const navigate = useNavigate();

  let { id } = useParams();
  let allNeighborhoods: INeighborhood[] = [];

  async function fetchData() {
    allNeighborhoods = await NeighborhoodService.getAll();
    let allVehicles = await VehicleService.getAll();
    setAllVehicles(allVehicles);
    let allCompanies = await CompanyService.getAll();
    allCompanies = allCompanies.filter((company) => company.type.includes('Coletadora'));
    setAllCompanies(allCompanies);
    let allCollectors = await UserService.getAll();
    allCollectors = allCollectors.filter((user) => user.role.name === 'Catador');
    setAllCollectors(allCollectors);

    try {
      let routeEvent = blankRouteEvent;
      if (!Number(id)) {
        navigate('/routes_events');
        return;
      }
      routeEvent = await RouteEventService.getById(Number(id));
      let company_addresss = await CompanyService.getAddress(routeEvent.company_id);
      company_addresss.id = undefined;
      routeEvent.address_start = routeEvent.address_start?.id ? routeEvent.address_start : company_addresss;
      routeEvent.address_end = routeEvent.address_end?.id ? routeEvent.address_end : company_addresss;

      try {
        routeEvent.city = routeEvent.address_start?.city;
        routeEvent.state = routeEvent.address_start?.state;
      } catch (e) {
        routeEvent.city = 'Cidade não encontrada';
        routeEvent.state = 'Estado não encontrado';
      }
      routeEvent.schedule.sort((a, b) => a.initial_time.localeCompare(b.initial_time));
      routeEvent.neighborhoods_name = routeEvent.schedule.map((s) => '[' + s.neighborhood_name + ']');
      routeEvent.packs_names = await RouteService.getPackNames(routeEvent.packs);
      let collectEvents = await CollectEventService.getByRouteEventId(routeEvent.id);
      collectEvents.sort((a, b) => a.sequence - b.sequence);
      routeEvent.collectEvents = collectEvents;

      routeEvent.schedule.map((s) => {
        let neighborhood = allNeighborhoods.find((n) => n.id === s.neighborhood_id);
        s.neighborhood_lat = neighborhood?.lat;
        s.neighborhood_lng = neighborhood?.lng;
      });

      setColorStatus(colors[routeEvent.status]);
      setRouteEvent(routeEvent);
      setCollectEvents(collectEvents);

      // Trocar pelo metodo que busque no servidor apenas as coletas daquela rota
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e: any) => {
    let { field } = getModelFromField(e.target.name);
    if (field === 'status') {
      setColorStatus(colors[e.target.value]);
    }
    setRouteEvent({ ...routeEvent, [field]: e.target.value });
  };
  function handleDate(date: Dayjs | null) {
    if (date) {
      setRouteEvent({ ...routeEvent, date: date.format('YYYY-MM-DD') });
    }
  }
  function validateErrors(routeEvent: IRouteEvent) {
    let errors: any = {};

    // if (!validateRequired(routeEvent.status)) errors.status = 'Status Obrigatorio';
    return errors;
  }
  const handleSave = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(routeEvent);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveRouteEvent(routeEvent);
    }
  };

  function handleDeleteConfirmation() {
    setShowConfirmationModal(true);
  }

  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await RouteEventService.delete(routeEvent.id)) {
      toast.success('Evento de Rota apagada com sucesso');
      // navigate('/routes_events');
      navigate(-1);
    }
  }

  async function handleEditAddress(addressEdited: number) {
    setAddressEdited(addressEdited);
    if (addressEdited === 0) {
      setAddress(routeEvent.address_start || blankAddress);
    } else {
      setAddress(routeEvent.address_end || blankAddress);
    }
    setModalAddress(true);
  }

  async function handleSaveAddress(address: IAddress) {
    if (addressEdited === 0) {
      setRouteEvent({ ...routeEvent, address_start: address });
    } else {
      setRouteEvent({ ...routeEvent, address_end: address });
    }
    setModalAddress(false);
  }

  async function saveRouteEvent(routeEvent: IRouteEvent) {
    if (routeEvent.id) {
      if (await RouteEventService.update(routeEvent)) {
        switch (routeEvent.status) {
          case 0: //Cancelada
            collectEvents.map((ce) => {
              if ([0, 2, 3].includes(ce.status)) {
                //Pendente, Atrasada, Andamento
                ce.status = 1;
                CollectEventService.update(ce);
              }
            });
            break;
          case 5: //Finalizada
            collectEvents.map((ce) => {
              if ([0, 2, 3].includes(ce.status)) {
                //Pendente, Atrasada, Andamento
                ce.status = 4; //Finalizada
                CollectEventService.update(ce);
              }
            });
            break;
          default:
            break;
        }
        toast.success('Evento de Rota salvo com sucesso');
        navigate('/routes_events?date=' + routeEvent.date);
      }
    } else {
      let newRouteEvent = await RouteEventService.create(routeEvent);
      if (newRouteEvent.id > 0) {
        routeEvent.id = newRouteEvent.id;
        toast.success('Evento de Rota criado com sucesso');
        navigate('/routes_events?date=' + routeEvent.date);
      }
    }
  }

  function handleSelectCollectEvent(id: number) {}
  const handleSwitch = (e: any) => {
    setChecked(e.target.checked);
  };

  //ok
  const collect: number[] = [];

  routeEvent.schedule.map((n) => {
    collect.push(collectEvents.filter((collectEvent) => collectEvent.collect?.address.neighborhood === n.neighborhood_name).length);
  });

  const handleIsExpandInfo = () => {
    setIsExpandInfo(!isExpandInfo);
  };

  const handleIsExpandCollect = () => {
    setIsExpandCollect(!isExpandCollect);
  };

  function sortCollectEvent(collectEvents: ICollectEvent[]) {
    collectEvents.map((ce, index) => {
      ce.sequence = index + 1;
      CollectEventService.update(ce);
    });
    setRouteEvent({ ...routeEvent, collectEvents: collectEvents });
  }
  function reorder<T>(List: T[], startIndex: number, endIndex: number) {
    const result = Array.from(List);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }
    const items = reorder(routeEvent.collectEvents || [], result.source.index, result.destination.index);
    sortCollectEvent(items);
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={modal} maxWidth="lg" disableEnforceFocus={true}>
          <DialogTitle display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
              <P>{routeEvent.name}</P>
              <p style={{ color: 'var(--preto-800, #464646)', fontSize: '12px', fontStyle: 'normal', fontWeight: '400' }}>ID: {routeEvent.id}</p>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="br">
              <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
                <DatePicker format="DD/MM/YYYY" label="Data" value={dayjs(routeEvent.date)} onChange={handleDate} />
              </FormControl>
            </LocalizationProvider>
            <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
              <InputLabel style={{ display: 'block', background: '#FFF', color: `${colorStatus}` }}>Status</InputLabel>
              <Select
                key="routeEvent.status"
                // style={{ border: `3px solid ${colorStatus}`, color: `${colorStatus}`, borderRadius: '6px' }}
                sx={{
                  '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                    border: `3px solid ${colorStatus}`,
                    borderRadius: '6px'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: `3px solid ${colorStatus}`
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: `3px solid ${colorStatus}`
                  },
                  color: `${colorStatus}`
                }}
                name="routeEvent.status"
                value={routeEvent.status}
                onChange={handleChange}
                error={errors.status}>
                {routeEventStatus.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
              <InputLabel>Privacidade</InputLabel>
              <Select key="routeEvent.privacy" label="Privacidade" name="routeEvent.privacy" value={routeEvent.privacy} onChange={handleChange} error={errors.privacy} placeholder="Privacidade">
                <MenuItem key="pr00" value={0}>
                  Pública
                </MenuItem>
                <MenuItem key="pr01" value={1}>
                  Privada
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: '10%' }} margin="dense" style={{ textAlign: 'right' }}>
              <CloseModal
                onClick={() => {
                  // navigate('/routes_events?date=' + routeEvent.date);
                  navigate(-1);
                }}
                style={{ cursor: 'pointer', marginTop: 1 }}
              />
            </FormControl>
          </DialogTitle>
          <Separator />
          <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <Grid container columnSpacing={2} rowSpacing={1} sx={{ marginBottom: '0.5rem' }}>
              <Grid item xs={12} md={12} sx={{ display: 'flex', width: '100%', gap: '0.5rem', alignItems: 'center' }}>
                <h4> INFORMAÇÕES</h4>
                <div onClick={handleIsExpandInfo} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <ExpandableSection isOpen={isExpandInfo} />
                </div>
              </Grid>
              {isExpandInfo ? (
                <>
                  <Grid item xs={12} md={6} spacing={2}>
                    <Grid container columnSpacing={2} rowSpacing={1}>
                      <Grid item xs={12} md={12}>
                        {/* {currentCompany.map((c) => (
                          <h4 key= </h4>
                        ))} */}
                        <h4>{`${routeEvent.company?.fantasy_name} - ${routeEvent.city}/${routeEvent.state}`}</h4>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }} size="small">
                          <InputLabel>Operador Responsável</InputLabel>
                          <Select
                            key="routeEvent.collector_id"
                            label="Operador Responsável"
                            name="routeEvent.collector_id"
                            value={routeEvent.collector_id}
                            onChange={handleChange}
                            error={errors.collector_id}
                            placeholder="Operador Responsável">
                            {allCollectors.map((collector) => (
                              <MenuItem key={'cl' + collector.id} value={collector.id}>
                                {collector.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }} size="small">
                          <InputLabel>Operador Auxiliar</InputLabel>
                          <Select
                            key="routeEvent.collector_aux_id"
                            label="Operador Auxiliar"
                            name="routeEvent.collector_aux_id"
                            value={routeEvent.collector_aux_id}
                            onChange={handleChange}
                            error={errors.collector_id}
                            placeholder="Operador Auxiliar">
                            {allCollectors.map((collector) => (
                              <MenuItem key={'cl' + collector.id} value={collector.id}>
                                {collector.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }} size="small">
                          <InputLabel>Veículo</InputLabel>
                          <Select
                            key="routeEvent.vehicle_id"
                            label="Veículo"
                            name="routeEvent.vehicle_id"
                            value={routeEvent.vehicle_id}
                            onChange={handleChange}
                            error={errors.collector_id}
                            placeholder="Veículo">
                            {allVehicles.map((vehicle) => (
                              <MenuItem key={'v' + vehicle.id} value={vehicle.id}>
                                {vehicle.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={11.35}>
                        <div
                          onDoubleClick={() => {
                            handleEditAddress(0);
                          }}>
                          <AddressBox address={routeEvent.address_start || blankAddress} title="Local de Partida" onClick={() => handleEditAddress(0)} />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={11.35}>
                        <div
                          onDoubleClick={() => {
                            handleEditAddress(1);
                          }}>
                          <AddressBox address={routeEvent.address_end || blankAddress} title="Local de Destino" onClick={() => handleEditAddress(1)} />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <TextField
                          key="routeEvent.obs"
                          name="routeEvent.obs"
                          label="Observações"
                          value={routeEvent.obs}
                          onChange={handleChange}
                          error={errors.name}
                          placeholder="Observações"
                          variant="outlined"
                          size="small"
                          fullWidth
                          multiline
                          sx={{
                            margin: '0.5rem'
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* ---------------- */}
                  <Grid item xs={12} md={6} spacing={2}>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} md={12}>
                        <CardsContainerModal height="200px" title="Área de Atendimento" openModal={() => console.log(true)} plusIcon="false">
                          {routeEvent.schedule.map((schedule, index) => (
                            <SubCardComponent
                              key={'scc' + index}
                              type={[]}
                              id={schedule.neighborhood_id || 0}
                              content={schedule.initial_time + ' - ' + schedule.end_time + ' ' + schedule.neighborhood_name + ' - ' + schedule.city}
                              onClick={() => {}}
                            />
                          ))}
                        </CardsContainerModal>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CardsContainerModal height="200px" title="Serviços" openModal={() => console.log(true)} plusIcon="false">
                          {routeEvent.packs_names?.map((pack, index) => (
                            <SubCardComponent key={'scc' + index} type={[]} id={0} content={pack} onClick={() => {}} />
                          ))}
                        </CardsContainerModal>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
            {/* Coletas */}
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={12} sx={{ display: 'flex', width: '100%', gap: '0.5rem', alignItems: 'center' }}>
                <h4>COLETAS</h4>
                <div onClick={handleIsExpandCollect} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <ExpandableSection isOpen={isExpandCollect} />
                </div>
              </Grid>
              {isExpandCollect ? (
                <>
                  <Grid item xs={12} md={4} spacing={2}>
                    <CardsContainerModal
                      height="550px"
                      title={'Total de Coletas: ' + routeEvent.collectEvents?.length}
                      openModal={() => navigate('/collects/0')}
                      sortIcon="true"
                      sort={() => {
                        // RouteEventService.sortCollects(routeEvent.id);
                        navigate('/routes_events/' + routeEvent.id);
                      }}
                      plusIcon={[0, 2, 3, 4].includes(routeEvent.status) ? 'true' : 'false'}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="colects" type="list" direction="vertical">
                          {(provided) => (
                            <article style={{ width: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
                              {routeEvent.collectEvents &&
                                routeEvent.collectEvents.map((ce, index) => (
                                  <CollectEventSubCard
                                    key={ce.id}
                                    index={index}
                                    status={ce.status}
                                    ce={ce}
                                    content={ce.collect ? ce.sequence + ' - ' + ce.collect.generator?.name + ' - ' + ce.collect.address.neighborhood : ''}
                                    onDoubleClick={() => navigate('/collects_events/' + ce.id)}
                                  />
                                ))}
                              {provided.placeholder}
                            </article>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </CardsContainerModal>
                  </Grid>
                  {/* ---------------- */}
                  <Grid item xs={12} md={8} spacing={2} sx={{ position: 'relative' }}>
                    <Switch checked={checked} onChange={handleSwitch} inputProps={{ 'aria-label': 'controlled' }} sx={{ position: 'absolute', right: '0.1rem', zIndex: '1' }}></Switch>

                    {checked ? (
                      <Grid item xs={12} md={12}>
                        {routeEvent.collectEvents && routeEvent.collectEvents.length > 0 && <MapLeaflet collectEvents={routeEvent.collectEvents || []} routeEvent={routeEvent} />}
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12}>
                        <RoutePath collectEvents={routeEvent.collectEvents || []} routeEvent={routeEvent} />
                      </Grid>
                    )}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: '1.25rem' }}>
            {/* <Button
              color="warning"
              disabled={routeEvent.id === 0}
              onClick={() => {
                handleDeleteConfirmation();
              }}>
              Apagar
            </Button> */}

            <Button
              onClick={() => {
                // navigate('/routes_events?date=' + routeEvent.date);
                navigate(-1);
              }}>
              Voltar
            </Button>
            <Button color="secondary" onClick={handleSave} variant="contained">
              Salvar
            </Button>
          </DialogActions>
          <ConfirmModal title="Deleção de Evento de Rota " message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
          <AddressModal initialValue={address} open={modalAddress} onClose={() => setModalAddress(false)} onSubmit={handleSaveAddress}></AddressModal>
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
//okk
