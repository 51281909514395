import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Select, MenuItem, Stack, InputLabel, FormControl } from '@mui/material';

import { INeighborhood, blankNeighborhood } from '../../models/Neighborhood';
import { toast } from 'react-toastify';
import { validateComany, validateRequired } from '../../utils/validations';
import { CompanyService } from '../../services/CompanyService';
import { CityService, StatesService, ICities } from '../../services/ZipService';
import styled from 'styled-components';
import { ICompany } from '../../models/Company';
import { BigTextField, SmallTextField } from '../../components/TextFields';
import { getAuthUser } from '../../services/auth';
import { get } from 'http';

const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;

interface ModalProps {
  value: INeighborhood;
  onClose: () => void;
  onSubmit: (neighborhood: INeighborhood) => void;
  open: boolean;
}

export const NeighborhoodModal = ({ open, value, onClose, onSubmit }: ModalProps) => {
  const company_id = getAuthUser().company?.id ? getAuthUser().company.id : 0;
  const [neighborhood, setNeighborhood] = useState<INeighborhood>({ ...blankNeighborhood, company_id: company_id });
  const [errors, setErrors] = useState<any>({});
  const [companiesData, setCompaniesData] = useState<ICompany[]>([]);
  const [statesData, setStatesData] = useState<string[]>([]);
  const [citiesData, setCitiesData] = useState<ICities[]>([]);

  async function fetchCompanies() {
    let allCompanies = await CompanyService.getAll();
    setCompaniesData(allCompanies);
  }

  //Get all States
  async function fetchAllStates() {
    let states = await StatesService.getStates();
    setStatesData(states.map((state) => state.sigla));
  }

  async function fetchAllCities(state: string) {
    let cities = await CityService.getCities(state);
    setCitiesData(cities);
  }

  useEffect(() => {
    fetchAllCities(neighborhood.state);
  }, [neighborhood.state]);

  useEffect(() => {
    fetchCompanies();
    fetchAllStates();
  }, []);

  useEffect(() => {
    setErrors({});
    setNeighborhood(value);
  }, [value]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(neighborhood: INeighborhood) {
    let errors: any = {};
    if (!validateRequired(neighborhood.state)) errors.state = 'Obrigatorio';
    if (!validateRequired(neighborhood.city)) errors.city = 'Obrigatorio';
    if (!validateRequired(neighborhood.neighborhood)) errors.neighborhood = 'Obrigatorio';
    if (!validateComany(neighborhood.company_id)) errors.company_id = 'Obrigatorio';
    return errors;
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(neighborhood);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onSubmit(neighborhood);
      onClose();
    }
  };

  //make a function to take a substring up dot position and return the inital string
  function getModelFromField(field: string) {
    let index = field.indexOf('.');
    return { model: field.substring(0, index), field: field.substring(index + 1, field.length) };
  }

  //Get values on form inputs and put into the model Neighborhood
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    setNeighborhood({ ...neighborhood, [field]: value });
    return;
  }

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle textAlign="center">EDIÇÃO DE BAIRROS</DialogTitle>
      <Separator />
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
              INFORMAÇÕES DO BAIRRO
            </Typography>

            <Grid item xs={12} md={12}>
              <Stack>
                <FormControl sx={{ maxWidth: '100%', m: 1, paddingTop: '8px' }} margin="dense">
                  <InputLabel>Empresa</InputLabel>
                  <Select
                    key="neighborhood.company_id"
                    label="Empresa"
                    name="neighborhood.company_id"
                    value={neighborhood.company_id}
                    onChange={handleChange}
                    error={errors.comany_id}
                    placeholder="Empresa">
                    {companiesData.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.fantasy_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack>
                <FormControl sx={{ maxWidth: '100%', m: 1, paddingTop: '8px' }} margin="dense">
                  <InputLabel>Estado</InputLabel>
                  <Select key="neighborhood.state" label="Estado" name="neighborhood.state" value={neighborhood.state} onChange={handleChange} error={errors.state} placeholder="Estado">
                    {statesData.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack>
                <FormControl sx={{ maxWidth: '100%', m: 1, paddingTop: '8px' }} margin="dense">
                  <InputLabel>Cidade</InputLabel>
                  <Select key="neighborhood.city" label="Cidade" name="neighborhood.city" value={neighborhood.city} onChange={handleChange} error={errors.city} placeholder="Cidade">
                    {citiesData.map((city) => (
                      <MenuItem key={city.id} value={city.nome}>
                        {city.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack>
                <FormControl sx={{ maxWidth: '100%', m: 0, paddingBottom: '10px' }} margin="dense">
                  <BigTextField key="neighborhood.neighborhood" label="Bairro" name="neighborhood.neighborhood" value={neighborhood.neighborhood} onChange={handleChange} error={errors.neighborhood} />
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack>
                <FormControl sx={{ maxWidth: '100%', m: 0, paddingBottom: '10px' }} margin="dense">
                  <SmallTextField key="neighborhood.lng" label="Longitude" name="neighborhood.lng" value={neighborhood.lng} onChange={handleChange} error={errors.lng} />
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack>
                <FormControl sx={{ maxWidth: '100%', m: 0 }} margin="dense">
                  <SmallTextField key="neighborhood.lat" label="Latitude" name="neighborhood.lat" value={neighborhood.lat} onChange={handleChange} error={errors.lat} />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
