import { IResidueClass, blankResidueClass } from './ResidueClass';
import { IResidueState, blankResidueState } from './ResidueState';
import { IUnit, blankUnit } from './Unit';

export interface IResidue {
  id: number;
  code: string;
  description: string;
  level: number;
  img?: string;
  class_id: number;
  class?: IResidueClass;
  unit_id: number;
  unit?: IUnit;
  state_id: number;
  state?: IResidueState;
  onu_number?: string;
}

export const blankResidue: IResidue = {
  id: 0,
  code: '',
  description: '',
  level: 3,
  img: '',
  unit_id: 0,
  unit: blankUnit,
  class_id: 0,
  class: blankResidueClass,
  state_id: 0,
  state: blankResidueState,
  onu_number: ''
};

export const residueProps = Object.keys(blankResidue);
