import { IAddress, blankAddress } from './Address';
import { ICompany, blankCompany } from './Company';
import { IUser, blankUser } from './User';
import { IVehicle, blankVehicle } from './Vehicle';

export interface IRoute {
  id: number;
  name: string;
  observation: string;
  active: boolean;
  recurrent: boolean;
  weekdays: Array<number>;
  dates: Array<string>;
  schedule: Array<INeighborhoodSchedule>;
  packs: Array<number>; //pack_id: number
  privacy?: number; //0-Pública, 1-Privada

  company_id: number;
  company?: ICompany;

  vehicle_id: number;
  vehicle?: IVehicle;

  collector_id: number;
  collector?: IUser;

  collector_aux_id: number;
  collector_aux?: IUser;

  address_start?: IAddress;
  address_end?: IAddress;

  //Atributos auxilizares (não fazem parte da api)
  company_name?: string;
  neighborhoods_name?: Array<string>;
  packs_names?: Array<string>;
  vehicle_capacity?: number;
  city?: string;
  state?: string;
  days?: string;
}

export interface INeighborhoodSchedule {
  neighborhood_id?: number;
  neighborhood_name?: string;
  initial_time: string;
  end_time: string;
  city?: string;
  neighborhood_lat?: string;
  neighborhood_lng?: string;
}

export const blankNeighborhoodSchedule: INeighborhoodSchedule = {
  neighborhood_id: 0,
  neighborhood_name: '',
  initial_time: '',
  end_time: ''
};

export const blankRoute: IRoute = {
  id: 0,
  name: '',
  observation: '',
  active: true,
  recurrent: true,
  weekdays: [],
  dates: [],
  schedule: [],
  packs: [],
  privacy: 0,
  company_id: 0,
  company: blankCompany,
  vehicle_id: 0,
  vehicle: blankVehicle,
  collector_id: 0,
  collector: blankUser,
  collector_aux_id: 0,
  collector_aux: blankUser,
  address_start: blankAddress,
  address_end: blankAddress
};

export const routeProps = Object.keys(blankRoute);
