export interface IResidueState {
  id: number;
  name: string;
  codInea: number;
  abreviation: string;
}

export const blankResidueState: IResidueState = {
  id: 0,
  name: '',
  codInea: 0,
  abreviation: ''
};

export const residueStateProps = Object.keys(blankResidueState);
