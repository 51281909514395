import React from 'react';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, plugins } from 'chart.js';
import { display, positions } from '@mui/system';
import { point } from 'leaflet';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieDashBoardContainer = styled.div`
  margin: auto;
  width: 20rem;
  height: 20rem;
`;

type PieDashBoardProps = {
  labels?: string[];
  values?: number[];
};

export default function PieDashBoard({ labels, values }: PieDashBoardProps) {
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Quantidade do resíduo',
        data: values,
        backgroundColor: ['#A642F4', '#FF6D01', '#34A853', '#FBBC04', '#FF0000', '#00FFFF'],
        hoverOffset: 4
      }
    ]
  };
  return (
    <PieDashBoardContainer>
      <Pie data={data} />
    </PieDashBoardContainer>
  );
}
