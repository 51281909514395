import { IAddress, blankAddress } from '../models/Address';

const token = process.env.REACT_APP_BRASILABERTO_TOKEN;

type IStates = {
  id: number;
  sigla: string;
  nome: string;
  regiao: {
    id: number;
    sigla: string;
    nome: string;
  };
};

export type ICities = {
  id: number;
  nome: string;
  microrregiao?: {
    id: number;
    nome: string;
    mesorregiao: {
      id: number;
      nome: string;
      UF: IStates;
    };
  };
};

type INeighborhood = {
  id: number;
  name: string;
};

export class ZipService {
  static async getAddress(zip_code: string): Promise<IAddress> {
    let address: IAddress = blankAddress;
    try {
      zip_code = zip_code.replace('-', '').replace('.', '');
      await fetch(`https://viacep.com.br/ws/${zip_code}/json/`)
        .then((response) => response.json())
        .then((data) => {
          if (data.erro) {
            return address;
          }
          address = {
            ...address,
            zip_code: data.cep,
            street: data.logradouro,
            neighborhood: data.bairro,
            city: data.localidade,
            state: data.uf
          };
        });
    } catch (error) {
      console.log(error);
      return address;
    }
    return address;
  }
}

export class StatesService {
  static async getStates(): Promise<IStates[]> {
    try {
      let states: IStates[] = [];
      await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
        .then((response) => response.json())
        .then((data) => {
          states = data;
        });

      return states;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}

export class CityService {
  static async getCities(state: string): Promise<ICities[]> {
    try {
      let cities: ICities[] = [];
      await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
        .then((response) => response.json())
        .then((data) => {
          cities = data;
        });

      return cities;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}

export class NeighborhoodsService {
  static async getNeighborhoods(city_id: string): Promise<INeighborhood[]> {
    try {
      let neighborhood: INeighborhood[] = [];
      //http://enderecos.metheora.com/api/cidade/${city_id}/bairros/
      await fetch(`https://api.brasilaberto.com/v1/districts-by-ibge-code/${city_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          neighborhood = data.result;
        });
      return neighborhood.filter((n) => n.name);
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}
