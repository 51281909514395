import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import styled from 'styled-components';

const Separator = styled.hr`
  border: 1px solid green; /* Cor da linha branca */
`;

type ConfirmModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  message: string;
  title?: string;
};

export const ConfirmModal = ({ title, message, isOpen, onCancel, onConfirm }: ConfirmModalProps) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle textAlign="left">
        <h2>{title}</h2>
      </DialogTitle>
      <Separator />
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <Box
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem'
          }}>
          <h3>{message}</h3>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onCancel}>Cancelar</Button>
        <Button color="secondary" onClick={onConfirm} variant="contained">
          Apagar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
