import React from 'react';
import { Typography } from '@material-ui/core';
import { IAddress } from '../models/Address';
import { Accordion, AccordionDetails, AccordionSummary, Container, TextField } from '@mui/material';
import { Edit } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  address: IAddress;
  title?: string;
  onClick?: () => void;
};

export default function AddressBox({ address, title, onClick }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '8px'
      }}>
      {title ? (
        <>
          <TextField key="address.street" name="address.street" label={title} value={`${address.street}, ${address.number}`} placeholder={title} variant="outlined" size="small" fullWidth />
        </>
      ) : (
        <>
          <Typography variant="body2">
            <h5>
              Rua: {address.street} Número: {address.number}{' '}
            </h5>
            <h5>Bairro: {address.neighborhood}</h5>
            <h5>
              Cidade: {address.city} / {address.state}
            </h5>
            <h5>Cep: {address.zip_code}</h5>
          </Typography>
        </>
      )}
    </div>
  );
}
