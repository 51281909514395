import api from './api';
import { INeighborhood, blankNeighborhood, neighborhoodProps } from '../models/Neighborhood';
import { toast } from 'react-toastify';
import { mapNullToString, clearObj, capitalizeObj } from '../utils/MyLib';
import { getAuthUser } from './auth';

export class NeighborhoodService {
  static async getAll(): Promise<INeighborhood[]> {
    try {
      const response = await api.get('/neighborhoods');
      let allNeighborhood = response.data as INeighborhood[];
      return allNeighborhood;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar bairros');
      return [];
    }
  }
  static async getById(id: number): Promise<INeighborhood> {
    try {
      const response = await api.get(`/neighborhoods/${id}`);
      let n = mapNullToString(response.data);
      clearObj(n, neighborhoodProps);
      return n;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar bairros');
      return blankNeighborhood;
    }
  }
  static async create({ id, ...neighborhood }: INeighborhood): Promise<INeighborhood> {
    try {
      this.standarlizeNeighborhoodr(neighborhood);
      const response = await api.post('/neighborhoods', neighborhood);
      if (response.status !== 201) {
        toast.error('Erro ao incluir área de atuação!');
        return blankNeighborhood;
      }
      toast.success('Área de atuação incluída com sucesso!');
      return response.data as INeighborhood;
    } catch (error: any) {
      toast.error('Erro ao incluir área de atuação!');
      return blankNeighborhood;
    }
  }
  static async update(neighborhood: INeighborhood): Promise<boolean> {
    try {
      this.standarlizeNeighborhoodr(neighborhood);
      const response = await api.put(`/neighborhoods/${neighborhood.id}`, neighborhood);
      if (response.status !== 200) {
        toast.error('Erro ao atualizar área de atuação!');
        return false;
      }
      toast.success('Área de atuação atualizada com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao incluir área de atuação!');
      return false;
    }
  }
  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/neighborhoods/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao excluir área de atuação!');
        return false;
      }
      toast.success('Área de atuação excluída com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir área de atuação!');
      return false;
    }
  }

  static standarlizeNeighborhoodr(n: INeighborhood | any): INeighborhood {
    if (n.company_id === '0') {
      n.company_id = undefined;
    }
    if (!n.company_id) {
      n.company_id = undefined;
    }
    return capitalizeObj(n);
  }
}

export default NeighborhoodService;
