import api from './api';
import { IUnit, blankUnit, unitProps } from '../models/Unit';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class UnitService {
  static async getAll(): Promise<IUnit[]> {
    try {
      const response = await api.get('/unit');
      let allUnits = response.data as IUnit[];
      return allUnits;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar unidades');
      return [];
    }
  }
  static async getById(id: number): Promise<IUnit> {
    try {
      const response = await api.get(`/unit/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, unitProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar unidade!');
      return blankUnit;
    }
  }

  static async create({ id, ...unit }: IUnit): Promise<IUnit> {
    try {
      this.standarlizeUnit(unit);
      const response = await api.post('/unit', unit);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir unidade');
        return blankUnit;
      }
      toast.success('Unidade incluído com sucesso!');
      return response.data as IUnit;
    } catch (error: any) {
      toast.error('Erro ao incluir unidade!');
      return blankUnit;
    }
  }
  static async update({ ...unit }: IUnit): Promise<boolean> {
    try {
      this.standarlizeUnit(unit);
      let response = await api.put(`/unit/${unit.id}`, unit);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar unidade!');
        return false;
      }
      toast.success('Unidade atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar unidade!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/unit/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir unidade!');
        return false;
      }
      toast.success('Unidade excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir unidade!');
      return false;
    }
  }

  static standarlizeUnit(c: IUnit | any): IUnit {
    c.codInea = Number(c.codInea);
    c.codInea = c.codInea || undefined;
    return c;
  }
}

export default UnitService;
