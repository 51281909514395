import { useEffect, useState } from 'react';
import { Stack, Container } from '@mui/material';
import { getAuthUser } from '../services/auth';
import admin_empresa from '../assets/icons/admin_empresa.png';
import admin_siri from '../assets/icons/admin_siri.png';
import catador from '../assets/icons/catador.png';
import gerador_comercial from '../assets/icons/gerador_comercial.png';
import operador_empresa from '../assets/icons/operador_empresa.png';
import usuario_gerador from '../assets/icons/usuario_gerador.png';

type Props = {
  name?: string;
  value: string;
  onChange: (role: string) => void;
};

export const CardTypeUser = ({ name, value, onChange }: Props) => {
  const [choice, setChoice] = useState('');

  const cards = [
    { title: 'Admin Siri', value: 'Super Admin', icon: admin_siri },
    { title: 'Admin Empresa', value: 'Admin', icon: admin_empresa },
    { title: 'Operador Empresa', value: 'Operador', icon: operador_empresa },
    { title: 'Catador', value: 'Catador', icon: catador },
    { title: 'Gerador Comercial', value: 'Gerador Comercial', icon: gerador_comercial },
    { title: 'Usuário Gerador', value: 'Gerador', icon: usuario_gerador }
  ];

  useEffect(() => {
    setChoice(value);
  }, [value]);

  const normal = {
    backgroundColor: 'var(--black-0)'
  };

  const blocked = {
    opacity: 0.15,
    pointerEvents: 'none'
  };

  const selected = {
    // backgroundColor: '#133940',
    backgroundColor: 'var(--primary-0)',
    // color: '#ffffff',
    borderRadius: 'var(--borderRadius-m)',
    border: 'none',
    transition: 'var(--transition)'
  };

  const imgNormal = {
    width: '80%',
    height: '80%',
    margin: 'auto',
    objectPosition: 'center',
    maxWidth: 48,
    maxHeight: 48
  };

  const imgSelected = {
    width: '80%',
    height: '80%',
    objectPosition: 'center',
    maxWidth: 48,
    maxHeight: 48,
    // backgroundColor: '#ffffff',
    margin: 'auto'
  };

  const handleChoice = (str: string) => {
    onChange(str);
    setChoice(str);
  };

  function styleCard(value: string): any {
    if (value === choice) {
      return selected;
    }
    if (getAuthUser().role.name !== 'Super Admin' && ['Super Admin', 'Admin'].includes(value)) {
      return blocked;
    }
    return normal;
  }

  // backgroundColor={choice === 'Gerador' ? '#3f51b5' : '#3f51b5'}
  return (
    <>
      <Stack direction="row" sx={{ gap: '5px' }}>
        {cards.map((card) => (
          <Container key={card.title} style={styleCard(card.value)} onClick={() => handleChoice(card.value)} sx={{ fontSize: { xs: 8, md: 10, xl: 12 } }}>
            <Stack direction="column" spacing={2} sx={{ cursor: 'pointer' }}>
              <img style={choice === card.value ? imgSelected : imgNormal} src={card.icon} alt={card.icon} />
            </Stack>
            <Stack textAlign="center" sx={{ cursor: 'pointer' }}>
              {card.title}
            </Stack>
          </Container>
        ))}
      </Stack>
    </>
  );
};
