import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Select, MenuItem, Stack, InputLabel, FormControl } from '@mui/material';

import { INeighborhood, blankNeighborhood } from '../../models/Neighborhood';
import { toast } from 'react-toastify';
import { validateComany, validateRequired } from '../../utils/validations';
// import { getAuthUser } from '../../services/auth';
import { CompanyService } from '../../services/CompanyService';
import { CityService, NeighborhoodsService, StatesService, ICities } from '../../services/ZipService';
import styled from 'styled-components';
import { ICompany } from '../../models/Company';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormGroup, FormLabel, FormControlLabel } from '@material-ui/core';
import { BigTextField } from '../../components/TextFields';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;

interface ModalProps {
  value: INeighborhood;
  onClose: () => void;
  onSubmit: (neighborhood: INeighborhood[]) => void;
  open: boolean;
  neighborhoodsData: INeighborhood[];
}

export const NeighborCreationModal = ({ open, value, onClose, onSubmit, neighborhoodsData }: ModalProps) => {
  const [neighborhood, setNeighborhood] = useState<INeighborhood>(blankNeighborhood);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [allCompanies, setAllCompanies] = useState<ICompany[]>([]);
  const [statesData, setStatesData] = useState<string[]>([]);
  const [citiesData, setCitiesData] = useState<ICities[]>([]);
  const [allNeighborhoodsData, setAllNeighborhoodsData] = useState<string[]>([]);
  const [selectedCityId, setSelectedCityId] = useState<string>('');
  const [neighborhoodsList, setNeighborhoodsList] = useState<INeighborhood[]>([]); //stores the array of selected neighborhoods
  const [manualRegister, setManualRegister] = useState(false);

  useEffect(() => {
    setErrors({});
    setNeighborhood(value);
  }, [value]);

  //Get all companies
  async function fetchCompanies() {
    let allCompanies = await CompanyService.getAll();
    allCompanies = allCompanies.filter((company) => company.type.includes('Coletadora'));
    setAllCompanies(allCompanies);
  }

  //Get all States
  async function fetchAllStates() {
    let states = await StatesService.getStates();
    setStatesData(states.map((state) => state.sigla));
  }

  useEffect(() => {
    fetchCompanies();
    fetchAllStates();
  }, []);

  async function fetchAllCities(state: string) {
    let cities = await CityService.getCities(state);
    setCitiesData(cities);
  }
  useEffect(() => {
    fetchAllCities(neighborhood.state);
  }, [neighborhood.state]);

  async function fetchAllNeighborhoods(id: string) {
    if (id !== '') {
      let neighborhoods = await NeighborhoodsService.getNeighborhoods(id);
      setAllNeighborhoodsData(neighborhoods.map((district) => district.name));
    }
  }

  useEffect(() => {
    fetchAllNeighborhoods(selectedCityId);
  }, [selectedCityId]);

  //Setting the neighborhoodsList acording to the company id and city selected.
  function handleCompanyChange(id: number) {
    let city = neighborhood.city;
    setNeighborhoodsList(neighborhoodsData.filter((neighborhood) => neighborhood.company_id === id && neighborhood.city === city));
  }

  useEffect(() => {
    handleCompanyChange(neighborhood.company_id);
  }, [neighborhood.company_id, neighborhood.city]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  //TODO verify the verification
  function validateErrors(neighborhoods: INeighborhood[]) {
    let errors: any = {};
    neighborhoods.map((neighborhood) => {
      if (!validateRequired(neighborhood.state)) errors.state = 'Obrigatorio';
      if (!validateRequired(neighborhood.city)) errors.city = 'Obrigatorio';
      if (!validateComany(neighborhood.company_id)) errors.company_id = 'Obrigatorio';
    });
    return errors;
  }

  const handleManualRegister = () => {
    setManualRegister(!manualRegister);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(neighborhoodsList);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onSubmit(neighborhoodsList);
      onClose();
    }
  };

  //make a function to take a substring up dot position and return the inital string
  function getModelFromField(field: string) {
    let index = field.indexOf('.');
    return { model: field.substring(0, index), field: field.substring(index + 1, field.length) };
  }

  //Get values on form inputs and put into the model Neighborhood
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    if (field === 'city') {
      const id = citiesData.find((c) => c.nome === value)?.id;
      setSelectedCityId(String(id));
      setNeighborhood({ ...neighborhood, [field]: value });
      return;
    }
    setNeighborhood({ ...neighborhood, [field]: value });
    return;
  }

  async function handleBlur(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    console.log(e);
    if (manualRegister) {
      if (field === 'neighborhood') {
        let neighbor = { ...neighborhood, [field]: value };
        setNeighborhoodsList([...neighborhoodsList, neighbor]);
      }
    }
  }

  //handle neighborhood select changes
  async function handleNeighborhoodChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    //if a value exists in nighborhoods list then remove it
    if (neighborhoodsList.find((neighborhood) => neighborhood.neighborhood === value)) {
      setNeighborhoodsList([...neighborhoodsList.filter((neighborhood) => neighborhood.neighborhood !== value)]);
    } else {
      let neighbor = { ...neighborhood, [field]: value };
      setNeighborhoodsList([...neighborhoodsList, neighbor]);
    }
  }

  function isMarked(e: any) {
    if (neighborhoodsList.find((neighborhood) => neighborhood.neighborhood === e)) {
      return true;
    }
    return false;
  }

  //TODO make the selection list prettier
  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle textAlign="center" sx={{ textTransform: 'none' }}>
        Registrar zonas de atendimento a
      </DialogTitle>
      <Separator />
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          {manualRegister ? (
            <Grid container spacing={2}>
              <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                Selecione a empresa
              </Typography>

              <Grid item xs={12} md={6}>
                <Stack>
                  <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      key="neighborhood.company_id"
                      label="Empresa"
                      name="neighborhood.company_id"
                      value={neighborhood.company_id}
                      onChange={handleChange}
                      error={errors.company_id}
                      placeholder="Empresa">
                      {allCompanies.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.fantasy_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                Informações da empresa
              </Typography>

              <Grid item xs={12} md={4}>
                <Stack>
                  <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                    <InputLabel>Estado</InputLabel>
                    <Select key="neighborhood.state" label="Estado" name="neighborhood.state" value={neighborhood.state} onChange={handleChange} error={errors.state} placeholder="Estado">
                      {statesData.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack>
                  <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                    <InputLabel>Cidade</InputLabel>
                    <Select key="neighborhood.city" label="Cidade" name="neighborhood.city" value={neighborhood.city} onChange={handleChange} error={errors.city} placeholder="Cidade">
                      {citiesData.map((city) => (
                        <MenuItem key={city.id} value={city.nome}>
                          {city.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack>
                  <BigTextField
                    key="neighborhood.neighborhood"
                    label="Bairro"
                    name="neighborhood.neighborhood"
                    value={neighborhood.neighborhood}
                    onChange={handleChange}
                    error={errors.neighborhood}
                    onBlur={handleBlur}
                  />
                </Stack>
              </Grid>
              {/* <Grid item xs={12} md={1.5}>
                <Stack>
                  <FormControl>
                    <SmallTextField key="neighborhood.lng" label="Longitude" name="neighborhood.lng" value={neighborhood.lng} onChange={handleChange} error={errors.lng} />
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} md={1.5}>
                <Stack>
                  <FormControl>
                    <SmallTextField key="neighborhood.lat" label="Latitude" name="neighborhood.lat" value={neighborhood.lat} onChange={handleChange} error={errors.lat} />
                  </FormControl>
                </Stack>
              </Grid> */}
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                Selecione a empresa
              </Typography>

              <Grid item xs={12} md={6}>
                <Stack>
                  <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      key="neighborhood.company_id"
                      label="Empresa"
                      name="neighborhood.company_id"
                      value={neighborhood.company_id}
                      onChange={handleChange}
                      error={errors.comany_id}
                      placeholder="Empresa">
                      {allCompanies.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          {company.fantasy_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                Informações da empresa
              </Typography>

              <Grid item xs={12} md={6}>
                <Stack>
                  <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                    <InputLabel>Estado</InputLabel>
                    <Select key="neighborhood.state" label="Estado" name="neighborhood.state" value={neighborhood.state} onChange={handleChange} error={errors.state} placeholder="Estado">
                      {statesData.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack>
                  <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                    <InputLabel>Cidade</InputLabel>
                    <Select key="neighborhood.city" label="Cidade" name="neighborhood.city" value={neighborhood.city} onChange={handleChange} error={errors.city} placeholder="Cidade">
                      {citiesData.map((city) => (
                        <MenuItem key={city.id} value={city.nome}>
                          {city.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12}>
                <Stack>
                  <FormControl margin="dense">
                    <FormLabel>
                      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                        Bairros <AddCircleRoundedIcon sx={{ color: 'var(--primary-400)', cursor: 'pointer' }} onClick={handleManualRegister}></AddCircleRoundedIcon>
                      </Typography>
                      <Typography variant="h6" sx={{ color: 'var(--primary-400)', cursor: 'pointer', fontSize: '15px' }} onClick={handleManualRegister}>
                        inserir novo bairro
                      </Typography>
                    </FormLabel>
                    <FormGroup aria-label="position" row>
                      {allNeighborhoodsData.map((neighborhood) => (
                        <FormControlLabel
                          key={neighborhood}
                          control={<Checkbox color="primary" name="neighborhood.neighborhood" checked={isMarked(neighborhood)} />}
                          label={neighborhood}
                          name="neighborhood.neighborhood"
                          onChange={handleNeighborhoodChange}
                          value={neighborhood}
                          style={{ width: '14rem' }}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button
          onClick={() => {
            onClose();
            setManualRegister(false);
          }}>
          Cancelar
        </Button>
        <Button
          color="secondary"
          onClick={(e) => {
            handleSubmit(e);
            setManualRegister(false);
          }}
          variant="contained">
          Salvar
        </Button>
        {/* <Button size="medium" color="primary" variant="contained" onClick={handleManualRegister}>
          {manualRegister ? `Registrar Automaticamente` : 'Registrar  Manualmente'}
        </Button> */}
        {/* <div style={{ display: 'flex', gap: '5px' }}>
          <Button onClick={onClose}>Cancelar</Button>
          <Button color="secondary" onClick={handleSubmit} variant="contained">
            Salvar
          </Button>
        </div> */}
      </DialogActions>
    </Dialog>
  );
};
