import React from 'react';
import BasicContainer from './BasicContainer';
import styled from 'styled-components';
import logo from '../assets/LogoSiri.png';

const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  flex-direction: 'row';
  height: 6vh;
  min-height: 3.5em;
  width: 100vw;
  background: var(--primary-700);
  box-shadow: var(--shadow-2);
`;

const Image = styled.img`
  margin-left: 1.4em;
  height: 40px;
  width: 40px;
`;

const Text = styled.p`
  font-family: sans-serif;
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  color: white;
`;

function MainHeader() {
  return (
    <Container>
      <BasicContainer flex={0.05} justify={'center'} alignItems={'flex-start'}>
        <Image alt="" src={logo}></Image>
      </BasicContainer>
      <BasicContainer flex={0.9}>
        <Text>Sistema de Gerenciamento de Resíduos</Text>
      </BasicContainer>
      <BasicContainer flex={0.05}></BasicContainer>
    </Container>
  );
}

export default MainHeader;
