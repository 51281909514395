import React from 'react';
import styled from 'styled-components';
import dash from './dashboardVilarejo.jpg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f5f5f5;
`;

const Image = styled.img`
  width: 100%;
  max-width: 700px;
  height: auto;
`;

export default function DashboardVilarejo() { 
  return (
    <Container>
      <Image src={dash} alt="Dashboard Vilarejo Praia Hotel" />
    </Container>
  );
};
