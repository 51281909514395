import React from 'react';
import { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { IPack } from '../../models/Pack';

import { PackService } from '../../services/PackService';

type Props = {
  initialValue: number;
  open: boolean;
  onSubmit: (residue: number) => void;
  onClose: () => void;
};
export default function RouteResidueModal({ open, initialValue, onClose, onSubmit }: Props) {
  const [allPacks, setAllPacks] = useState<IPack[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [residue, setResidue] = useState(0);

  async function fetchData() {
    let allPacks = await PackService.getAll();
    setAllPacks(allPacks);
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  async function handleChange(e: any) {
    setResidue(e.target.value);
  }

  function validateErrors(residue: Number) {
    let errors: any = {};
    if (!residue) errors.residue_id = 'Obrigatorio';
    return errors;
  }

  function handleSave(e: any) {
    e.preventDefault();
    const errors = validateErrors(residue);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onSubmit(residue);
      setResidue(0);
      onClose();
    }
  }

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Stack>
                <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                  <InputLabel>Pack</InputLabel>
                  <Select key="residue" label="Residuo" name="residue" value={residue} onChange={handleChange} placeholder="Material" error={errors.residue_id}>
                    {allPacks.map((n) => (
                      <MenuItem key={n.id} value={n.id}>
                        {n.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="secondary" onClick={handleSave} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
