import { toast } from 'react-toastify';
import api from './api';
export class ImgUploadService {
  static async upload(model: string, id: number, file: File): Promise<boolean> {
    try {
      console.log(model, id, file);
      await api.postForm(
        `/${model}/${id}/upload`,
        { file: file },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      toast.success('Imagem salva!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao salvar a imagem!');
      return false;
    }
  }
}
