import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const MainCards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
};

const CardsModalWeek = ({ children }: Props) => {
  return (
    <Container>
      <MainCards>{children}</MainCards>
    </Container>
  );
};

export default CardsModalWeek;
