import { useEffect, useMemo, useState, useRef } from 'react';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_Row, type MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Download } from '@mui/icons-material';
import PageStructure from '../../components/PageStructure';
import { PageContainer, PageContent, PageHeader, PageFooter, PageTitle, LoadWrapper } from '../../components/GlobalStyleds.jsx';
import CollectService from '../../services/CollectService';
import ReactLoading from 'react-loading';
import { Typography, Stack, Button, Chip } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import 'dayjs/locale/pt-br';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';
import CardsContainer from '../../components/CardsContainer';
import { ICollect } from '../../models/Collect';
import RoutesService from '../../services/RouteService';
import { IRoute } from '../../models/Route';
import { ICollectEvent, collectEventStatus } from '../../models/CollectEvent';
import dayjs from 'dayjs';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import { weeksdays } from '../../utils/MyLib';
import CollectEventService from '../../services/CollectEventService';
import { all } from 'axios';
import RouteEventsService from '../../services/RouteEventService';
import { IRouteEvent } from '../../models/RouteEvent';

export default function CollectEventPage() {
  const [collectsData, setCollectsData] = useState<ICollectEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const tableInstanceRef = useRef<MRT_TableInstance<ICollectEvent>>(null);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  let cardChoiced = 0;
  let allCollects: ICollect[] = [];
  let allRoutes: IRoute[] = [];
  let allRouteEvents: IRouteEvent[] = [];
  let allCollectEvents: ICollectEvent[] = [];

  const [cards, setCards] = useState([
    { id: 0, title: 'Total', content: 0 },
    { id: 1, title: 'Agendadas', content: 0 },
    { id: 2, title: 'Canceladas', content: 0 },
    { id: 3, title: 'Finalizada', content: 0 },
    { id: 4, title: 'Avalidada', content: 0 }
  ]);

  async function fetchData() {
    setLoading(true);

    allCollectEvents = await CollectEventService.getAll();
    cards[0].content = allCollectEvents.length;
    cards[1].content = allCollectEvents.filter((collectEvent) => collectEvent.status === 0).length;
    cards[2].content = allCollectEvents.filter((collectEvent) => collectEvent.status === 1).length;
    cards[3].content = allCollectEvents.filter((collectEvent) => collectEvent.status === 4).length;
    cards[4].content = allCollectEvents.filter((collectEvent) => collectEvent.status === 5).length;

    switch (cardChoiced) {
      case 1:
        allCollectEvents = allCollectEvents.filter((collectEvent) => collectEvent.status === 0);
        break;
      case 2:
        allCollectEvents = allCollectEvents.filter((collectEvent) => collectEvent.status === 1);
        break;
      case 3:
        allCollectEvents = allCollectEvents.filter((collectEvent) => collectEvent.status === 4);
        break;
      case 4:
        allCollectEvents = allCollectEvents.filter((collectEvent) => collectEvent.status === 5);
        break;
    }
    setCards([...cards]);
    setLoading(false);
    setCollectsData(allCollectEvents);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function handleClickFilterCard(id: number) {
    cardChoiced = id;
    fetchData();
  }

  function handleEditRow(row: MRT_Row<ICollectEvent>) {
    // setSelected(row.original);
    navigate(`/collects_events/${row.original.id}`);
  }

  function handleNew(): void {
    navigate('/collects/0');
  }

  //TODO: Implementar exportação
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = collectsData.map((r) => {
      return {
        id: r.id,
        status: r.status
        // route_name: r.route?.name,
        // generator_name: r.generator?.name,
        // city: r.address.city,
        // request_date: r.request_date,
        // neighborhood: r.address.neighborhood
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  const columns = useMemo<MRT_ColumnDef<ICollectEvent>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => <Chip label={collectEventStatus[row.original.status].name} style={{ background: collectEventStatus[row.original.status].color, color: 'white' }} />,
        size: 5
      },

      {
        accessorKey: 'collect.generator.name',
        header: 'Gerador',
        size: 25
      },
      {
        accessorKey: 'routeEvent.name',
        header: 'Rota associada',
        size: 10
      },

      {
        accessorKey: 'routeEvent.date',
        header: 'Data',
        size: 25
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>HISTÓRICO DE COLETAS</PageTitle>
          <CardsContainer
            cards={cards}
            choiced={cardChoiced}
            onChange={(id) => {
              handleClickFilterCard(id);
            }}></CardsContainer>
        </PageHeader>
        <PageContent>
          {collectsData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhuma coleta encontrada</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 300,
                  height: 50
                }}
                color="primary"
                onClick={handleNew}
                variant="contained"
                startIcon={<AccountCircle />}>
                Criar Coleta
              </Button>
            </Stack>
          )}

          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {collectsData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={collectsData}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false, city: false } }}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  {/* <Button color="primary" onClick={handleNew} variant="contained" startIcon={<AccountCircle />}>
                    Novo
                  </Button> */}
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Exportar
                  </Button>
                </Stack>
              )}
            />
          )}
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
