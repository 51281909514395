import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiTextField: {
      root: {
        backgroundColor: 'red'
      }
    }
  }
});

export const BigTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  fullWidth: true,
  margin: theme.spacing(1)
}));

export const MediumTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  maxWidth: 300,
  margin: theme.spacing(1)
}));

export const SmallTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  maxWidth: 150,
  margin: theme.spacing(1)
}));

export const LoginTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: 400
}));

export const BorderNoneTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  margin: theme.spacing(1)
}));
