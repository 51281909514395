import { useEffect, useMemo, useState, useRef } from 'react';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_Row, type MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Download } from '@mui/icons-material';
import PageStructure from '../../components/PageStructure';
import { PageContainer, PageContent, PageHeader, PageFooter, PageTitle, LoadWrapper } from '../../components/GlobalStyleds.jsx';
import CollectService from '../../services/CollectService';
import ReactLoading from 'react-loading';
import { Typography, Stack, Button, Chip } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import 'dayjs/locale/pt-br';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';
import CardsContainer from '../../components/CardsContainer';
import { ICollect, collectStatus } from '../../models/Collect';
import RoutesService from '../../services/RouteService';
import { IRoute } from '../../models/Route';
import { ICollectEvent, collectEventStatus } from '../../models/CollectEvent';
import dayjs from 'dayjs';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import { weeksdays } from '../../utils/MyLib';
import CollectEventService from '../../services/CollectEventService';

export default function CollectPage() {
  const [collectsData, setCollectsData] = useState<ICollect[]>([]);
  const [loading, setLoading] = useState(true);
  const tableInstanceRef = useRef<MRT_TableInstance<ICollect>>(null);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();
  const [cardChoiced, setCardChoiced] = useState(0);

useEffect(() => {
  const shouldReload = sessionStorage.getItem('shouldReload');
  if (shouldReload) {
    fetchData(); // Recarrega os dados
    sessionStorage.removeItem('shouldReload'); // Limpa o sinalizador após o uso
  }
}, []);

  let allCollects: ICollect[] = [];
  let allRoutes: IRoute[] = [];
  let allCollectEvents: ICollectEvent[] = [];

  const [cards, setCards] = useState([
    { id: 0, title: 'Todas', content: 0 },
    { id: 1, title: 'Recorrentes', content: 0 },
    { id: 2, title: 'Pontuais', content: 0 },
    { id: 3, title: 'Solicitadas', content: 0 },
    { id: 4, title: 'Canceladas', content: 0 },
    { id: 5, title: 'Sem áreas de atendimento', content: 0 }
  ]);

  async function fetchData() {
    setLoading(true);
    allCollects = await CollectService.getAll();
    allCollectEvents = await CollectEventService.getAll();
    // allCollects = allCollects.filter((collect) => collect.recurrent === true);
    allRoutes = await RoutesService.getAll();
    cards[0].content = allCollects.length;
    cards[1].content = allCollects.filter((collect) => collect.recurrent).length;
    cards[2].content = allCollects.filter((collect) => !collect.recurrent).length;
    cards[3].content = allCollects.filter((collect) => collect.recurrent && collect.status === 0).length;
    cards[4].content = allCollects.filter((collect) => collect.recurrent && collect.status === 2).length;
    cards[5].content = allCollects.filter((collect) => collect.status === 3).length;
    switch (cardChoiced) {
      case 0:
        break;
      case 1:
        allCollects = allCollects.filter((collect) => collect.recurrent);
        break;
      case 2:
        allCollects = allCollects.filter((collect) => !collect.recurrent);
        break;
      case 3:
        allCollects = allCollects.filter((collect) => collect.recurrent && collect.status === 0);
        break;
      case 4:
        allCollects = allCollects.filter((collect) => collect.recurrent && collect.status === 2);
        break;
      case 5:
        allCollects = allCollects.filter((collect) => collect.status === 3);
        break;
    }
    setCards([...cards]);
    setLoading(false);
    setCollectsData(allCollects);
  }

  useEffect(() => {
    fetchData();
  }, [cardChoiced]);

  async function handleClickFilterCard(id: number) {
    setCardChoiced(id);
    // fetchData();
  }

  function handleEditRow(row: MRT_Row<ICollect>) {
    // setSelected(row.original);
    navigate(`/collects/${row.original.id}`);
  }

  function handleNew(): void {
    navigate('/collects/0');
  }

  //TODO: Implementar exportação
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = collectsData.map((r) => {
      return {
        id: r.id,
        status: r.status,
        route_name: r.route?.name,
        generator_name: r.generator?.name,
        city: r.address.city,
        request_date: r.request_date,
        neighborhood: r.address.neighborhood
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  function getStatusIcon(collect: ICollect) {
    // if (!collect.recurrent) {
    //   let collectEvent = allCollectEvents.find((ce) => ce.collect && ce.collect.id === collect.id);
    //   if (collectEvent && collect.status !== 3) {
    //     return <Chip label={collectEventStatus[collectEvent.status].name} style={{ borderRadius: 20, background: collectEventStatus[collectEvent.status].color, color: 'white' }} variant="outlined" />;
    //   }
    // }
    return <Chip label={collectStatus[collect.status].name} style={{ background: collectStatus[collect.status].color, color: 'white' }} />;
  }

  // async function handleActive(e: any) {
  //   for (var prop in rowSelection) {
  //     let route = await CollectService.getById(Number(prop));
  //     route.active = e.target.name === 'btnActive' ? true : false;
  //     await CollectService.update(route);
  //   }
  //   fetchData();
  // }
  function colorRecurrent(recurrent: boolean) {
    //
    return recurrent ? 'visible' : 'hidden';
  }

  const columns = useMemo<MRT_ColumnDef<ICollect>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => getStatusIcon(row.original),
        size: 5
      },
      {
        acessorKey: 'active',
        header: 'Recorrente',
        Cell: ({ row }) => (
          <div style={{ textAlign: 'left' }}>
            <CheckCircleOutlineSharpIcon sx={{ color: 'green', visibility: colorRecurrent(row.original.recurrent) }} />{' '}
          </div>
        ),
        size: 5
      },
      {
        accessorKey: 'generator.name',
        header: 'Gerador',
        size: 25
      },
      {
        accessorKey: 'route.name',
        header: 'Rota associada',
        size: 10
      },

      // {
      //   accessorKey: 'address.city',
      //   header: 'Cidade',
      //   size: 25
      // },

      {
        accessorKey: 'address.neighborhood',
        header: 'Bairro',
        size: 25
      },
      {
        accessorKey: 'dates',
        header: 'Dias',
        Cell: ({ row }) => (!row.original.recurrent ? dayjs(row.original.dates[0]).format('DD/MM/YYYY') : row.original.weekdays.map((weekday) => weeksdays[weekday]).join(', ')),
        size: 25
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>COLETAS REQUISITADAS</PageTitle>
          <CardsContainer
            cards={cards}
            choiced={cardChoiced}
            onChange={(id) => {
              handleClickFilterCard(id);
            }}></CardsContainer>
        </PageHeader>
        <PageContent>
          {collectsData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhuma coleta encontrada</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 300,
                  height: 50
                }}
                color="primary"
                onClick={handleNew}
                variant="contained"
                startIcon={<AccountCircle />}>
                Criar Coleta
              </Button>
            </Stack>
          )}

          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {collectsData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={collectsData}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false, city: false } }}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNew} variant="contained" startIcon={<AccountCircle />}>
                    Novo
                  </Button>
                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Exportar
                  </Button>
                </Stack>
              )}
            />
          )}
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
