import api from './api';
import { IRoute, blankRoute, routeProps } from '../models/Route';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';
import { PackService } from './PackService';

export default class RoutesService {
  static async getAll(filter?: string): Promise<IRoute[]> {
    try {
      const response = await api.get('/routes' + (filter ? `?${filter}` : ''));
      let allRoutes = response.data as IRoute[];
      return allRoutes;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar rotas');
      return [];
    }
  }
  static async getById(id: number): Promise<IRoute> {
    try {
      const response = await api.get(`/routes/${id}`);
      let r = response.data;
      // r = mapNullToString(r);
      clearObj(r, routeProps);
      return r;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar rota!');
      return blankRoute;
    }
  }

  static async create({ id, ...route }: IRoute): Promise<IRoute> {
    try {
      this.standarlizeRoutes(route);
      const response = await api.post('/routes', route);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir rota');
        return blankRoute;
      }
      toast.success('Rotas incluídas com sucesso!');
      return response.data as IRoute;
    } catch (error: any) {
      toast.error('Erro ao incluir rota!');
      return blankRoute;
    }
  }
  static async update({ ...route }: IRoute): Promise<boolean> {
    try {
      this.standarlizeRoutes(route);
      let response = await api.put(`/routes/${route.id}`, route);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar a rota!');
        return false;
      }
      toast.success('Rota atualizada com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar rota!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/routes/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir rota!');
        return false;
      }
      toast.success('Rota excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir rota!');
      return false;
    }
  }

  static standarlizeRoutes(r: IRoute | any): IRoute {
    r.company_id = Number(r.company_id);
    r.company_id = r.company_id || undefined;
    r.vehicle_id = Number(r.vehicle_id);
    r.vehicle_id = r.vehicle_id || undefined;
    r.collector_id = Number(r.collector_id);
    r.collector_id = r.collector_id || undefined;
    r.collector_aux_id = Number(r.collector_aux_id);
    r.collector_aux_id = r.collector_aux_id || undefined;
    r.privacy = Number(r.privacy);
    r.privacy = r.privacy || 0;
    return r;
  }

  static async getPackNames(packs: number[]): Promise<Array<string>> {
    let allPacks = await PackService.getAll();
    let packs_name: Array<string> = [];
    packs?.forEach((pack_id, index) => {
      let composition = 'Não Encontrado!';
      let pack = allPacks.find((p) => p.id === pack_id);
      if (pack) {
        composition = pack.name;
      }
      packs_name.push(composition);
    });
    return packs_name;
  }
}
