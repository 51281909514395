import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Rating, Select, TextField, Typography } from '@mui/material';

import styled from 'styled-components';

import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { ICollectEvent, blankCollectEvent, collectEventStatus } from '../../models/CollectEvent';
import StarIcon from '@mui/icons-material/Star';

import { useEffect, useState } from 'react';
import CollectEventService from '../../services/CollectEventService';

import ReactLoading from 'react-loading';

import { useNavigate, useParams } from 'react-router-dom';
import CollectService from '../../services/CollectService';
import PageStructure from '../../components/PageStructure';
import { PageContainer } from '../../components/GlobalStyleds';
import { ConfirmModal } from '../../components/ConfirmModal';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';
import RouteEventsService from '../../services/RouteEventService';
import { blankCollect } from '../../models/Collect';
import { IRouteEvent, blankRouteEvent } from '../../models/RouteEvent';
import { baseImg } from '../../App';
import CardsContainerModal from '../../components/CardsContainerModal';
import SubCardComponent from '../../components/SubCard';
import { getModelFromField } from '../../utils/MyLib';
import ContainerService from '../../services/ContainerService';
import ResidueService from '../../services/ResidueService';
import { IMaterial, blankMaterial } from '../../models/Material';
import CollectMaterialModal from '../Collect/CollectMaterialModal';
import { IResidue } from '../../models/Residue';
import { IComposition } from '../../models/Pack';
import PackService from '../../services/PackService';

const Separator = styled.hr`
  border: 1px solid lightgray; /* Cor da linha branca */
`;

const P = styled.p`
  font-weight: 700;
`;

const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`;

const SubCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-width: 80%;
  border-radius: var(--borderRadius-m);
  background: var(--bgColor-1);
  box-shadow: var(--shadow-1);
  margin-top: 8px;
`;

export default function CollectEventForm() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [collectEvent, setCollectEvent] = useState<ICollectEvent>(blankCollectEvent);
  const [allRouteEvents, setAllRouteEvents] = useState<IRouteEvent[]>([]);
  const [open, setOpen] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [colorStatus, setColorStatus] = useState('');
  const [material, setMaterial] = useState<IMaterial>(blankMaterial);
  const [collectModalOperation, setCollectModalOperation] = useState('new');
  const [modalMaterial, setModalMaterial] = useState(false);
  const [allCompositions, setAllCompositions] = useState<IComposition[]>([]);

  const confirmMessage = 'Tem certeza que deseja apagar a coleta?';

  const navigate = useNavigate();

  let { id } = useParams();
  let collectEventId = Number(id);
  let imagem = '';
  const colors = collectEventStatus.map((status) => status.color);

  async function getComposition(packs: number[] | undefined) {
    let composition: IComposition[] = [];
    let allPacks = await PackService.getAll();
    packs?.forEach((residue_id) => {
      let pack = allPacks.find((r) => r.id === residue_id);
      if (pack) {
        composition = [...composition, ...pack.composition];
      }
    });
    return composition;
  }

  async function fetchData() {
    if (!collectEventId) return;
    setLoading(true);
    let collectEvent = (await CollectEventService.getById(collectEventId)) || blankCollectEvent;
    if (!collectEvent.collect) {
      collectEvent.collect = await CollectService.getById(collectEvent.collect_id);
    }
    let allCompositions = await getComposition(collectEvent.routeEvent?.packs);
    setAllCompositions(allCompositions);
    let allRouteEvents = await RouteEventsService.getAll();
    allRouteEvents = allRouteEvents.filter((routeEvent) => routeEvent.date >= dayjs().format('YYYY-MM-DD') || routeEvent.id === collectEvent.route_event_id);
    setAllRouteEvents(allRouteEvents);
    if (collectEvent.materials)
      collectEvent.materials.map((m) => {
        m.container_id = m.container_id || 1;
        m.size = m.size || 'Pequena';
        m.quantity = m.quantity || 1;
        m.weight = m.weight || 0;
        m.unit_id = m.unit_id || 1;
        m.state_id = m.state_id || 1;
        m.treatment_id = m.treatment_id || 1;
        m.batch_number = m.batch_number || '';
      });
    setCollectEvent(collectEvent);
    imagem = collectEvent.collect?.generator?.img ? baseImg + collectEvent.collect?.generator?.img : '';
    setColorStatus(colors[collectEvent.status]);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function validateErrors(collectEvent: ICollectEvent) {
    let errors: any = {};
    return errors;
  }

  const handleChange = (e: any) => {
    let { field } = getModelFromField(e.target.name);
    if (field === 'status') {
      setColorStatus(colors[e.target.value]);
    }
    setCollectEvent({ ...collectEvent, [field]: e.target.value });
  };
  function handleNewMaterial() {
    setCollectModalOperation('new');
    setMaterial(blankMaterial);
    setModalMaterial(true);
  }
  function handleIncludeMaterial(m: IMaterial): void {
    let newMaterials = collectEvent.materials;
    let material = collectEvent.materials.find((mat) => mat.residue_id === m.residue_id);

    if (material && m.weight) {
      if (collectModalOperation === 'new') material.weight += Number(m.weight);
      else material.weight = Number(m.weight);
      material.container_id = m.container_id;
      material.quantity = m.quantity;
      material.size = m.size;
      material.unit_id = m.unit_id;
      material.state_id = m.state_id;
      material.treatment_id = m.treatment_id;
      material.batch_number = m.batch_number;
      material.residue_name = m.residue_name;
      material.container_name = m.container_name;
      material.unit_name = m.unit_name;
      material.unit_abreviation = m.unit_abreviation;
      material.state_name = m.state_name;
      material.treatment_name = m.treatment_name;
    } else {
      newMaterials.push(m);
    }
    setErrors({});
    setCollectEvent({ ...collectEvent, materials: newMaterials });
  }
  function handleEditMaterial(id: number) {
    let mat = collectEvent.materials.find((m) => m.residue_id === id);
    if (!mat) {
      return;
    }
    setCollectModalOperation('edit');
    setMaterial(mat);
    setModalMaterial(true);
  }
  function handleDeleteMaterial(id: number) {
    let materials = collectEvent.materials;
    materials = collectEvent.materials.filter((m) => m.residue_id !== id);
    setCollectEvent({ ...collectEvent, materials: materials });
  }

  const handleSave = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(collectEvent);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveCollectEvent(collectEvent);
    }
  };

  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await CollectService.delete(collectEvent.id)) {
      toast.success('Coleta apagada com sucesso');
      navigate(-1);
    }
  }

  async function saveCollectEvent(collectEvent: ICollectEvent) {
    if (collectEvent.id) {
      if (await CollectEventService.update(collectEvent)) {
        toast.success('Coleta salva com sucesso');
        navigate(-1);
      }
    } else {
      let newCollectEvent = await CollectEventService.create(collectEvent);
      if (newCollectEvent.id > 0) {
        collectEvent.id = newCollectEvent.id;
        toast.success('Coleta criada com sucesso');
        navigate(-1);
      }
    }
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={open} maxWidth="lg">
          <DialogTitle display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <RowTitle>
              <Avatar src={imagem} alt="Avatar" sx={{ height: 56, width: 56 }} />
              <P>{collectEvent.collect?.generator?.name}</P>
            </RowTitle>
            <RowTitle>
              {/* <Typography component="legend">Avaliação</Typography> */}
              <Chip label={dayjs(collectEvent.routeEvent?.date).format('DD/MM/YYYY')} style={{ background: collectEventStatus[collectEvent.status].color, color: 'white' }} />
              <FormControl sx={{ m: 0 }}>
                <InputLabel style={{ display: 'block', background: '#FFF', color: `${colorStatus}`, border: `2px solid ${colorStatus}`, borderRadius: '3px' }}>Status</InputLabel>
                <Select
                  key="collectEvent.status"
                  style={{ border: `3px solid ${colorStatus}`, color: `${colorStatus}`, borderRadius: '6px' }}
                  name="collectEvent.status"
                  value={collectEvent.status}
                  onChange={handleChange}
                  error={errors.status}>
                  {collectEventStatus.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Rating name="collectEvent.score" value={collectEvent.score} size="large" onChange={handleChange} />
              <CloseModal
                onClick={() => {
                  navigate(-1);
                }}
                style={{ cursor: 'pointer', marginTop: 1 }}
              />
            </RowTitle>
          </DialogTitle>
          <Separator />
          <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <Grid container spacing={5}>
              <Grid item container xs={12} md={6} rowSpacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <P>Id</P>
                    <Chip label={collectEvent.id} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <P>Estado</P>
                    <Chip label={collectEvent.collect?.address?.state} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <P>Cidade</P>
                    <Chip label={collectEvent.collect?.address?.city} />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <P>Endereço da coleta</P>
                    <Chip
                      label={`${collectEvent.collect?.address?.street}, ${collectEvent.collect?.address?.number}, ${collectEvent.collect?.address?.neighborhood}, ${collectEvent.collect?.address?.zip_code}`}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <P>Contato</P>
                    <Chip label={collectEvent.collect?.generator?.phone_number} />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }} disabled={collectEvent.status === 4}>
                    <InputLabel>Rota</InputLabel>
                    <Select
                      key="collectEvent.route_event_id"
                      label="Rota"
                      name="collectEvent.route_event_id"
                      value={collectEvent.route_event_id}
                      onChange={handleChange}
                      error={errors.privacy}
                      placeholder="Rota">
                      {allRouteEvents.map((re) => (
                        <MenuItem key={re.id} value={re.id}>
                          {re.name + ' - ' + dayjs(re.date).format('DD/MM/YYYY')}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <P>Observações</P>
                  <TextField variant="outlined" fullWidth multiline rows={2} key="collectEvent.obs" name="collectEvent.obs" value={collectEvent.obs} onChange={handleChange} error={errors.status} />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} textAlign={'start'}>
                <CardsContainerModal height="175px" title="Materiais" openModal={() => handleNewMaterial()} plusIcon="true" error={errors.materials}>
                  {collectEvent.materials &&
                    collectEvent.materials.map((m) => (
                      <SubCardComponent
                        key={m.residue_id}
                        type={['delete']}
                        id={m.residue_id}
                        content={' ' + m.residue_name + ' - (' + m.quantity + ') ' + m.container_name + ' ' + m.size}
                        value={m}
                        format={2}
                        onDelete={handleDeleteMaterial}
                        onClick={handleEditMaterial}
                      />
                    ))}
                </CardsContainerModal>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button
              onClick={() => {
                navigate(-1);
              }}>
              Cancelar
            </Button>
            <Button color="secondary" onClick={handleSave} variant="contained">
              Salvar
            </Button>
          </DialogActions>
          <CollectMaterialModal initialValue={material} allCompositions={allCompositions} open={modalMaterial} onClose={() => setModalMaterial(false)} onSubmit={handleIncludeMaterial} />
          <ConfirmModal title="Deleção de Evento de Rota " message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
