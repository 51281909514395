import React from 'react';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  ArcElement, 
  Tooltip, 
  Legend, 
  LineController, 
  LineElement, 
  PointElement, 
  CategoryScale, 
  LinearScale 
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement, 
  Tooltip, 
  Legend, 
  ChartDataLabels,
  LineController, 
  LineElement, 
  PointElement, 
  CategoryScale, 
  LinearScale
);

const PieDashBoardContainer = styled.div`
  width: auto;
  height: auto;
  margin: auto;
`;

type PieDashBoardProps = {
  labels?: string[];
  values?: number[];
};

export default function ImpactPie({ labels = [], values = [] }: PieDashBoardProps) {
  const colorMap: { [key: string]: string } = {
    'Plásticos': '#f72e03',
    'Vidro': '#34A853',
    'Metais': '#FBBC04',
    'Papel': '#0337f7',
    'Total Coletado': '#000000',
    'Outros': '#00FFFF'
  };

  const backgroundColors = labels.map(label => colorMap[label] || '#888888');

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Quantidade do resíduo',
        data: values,
        backgroundColor: backgroundColors,
        hoverOffset: 4
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          boxWidth: 20,
          padding: 15,
          color: '#333',
          font: {
            size: 16,
            weight: 'bold' as const
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
            const index = tooltipItem.dataIndex;
            const label = tooltipItem.label || labels[index] || "Resíduo";
            const value = dataset.data[index] || 0;
            const total = dataset.data.reduce((acc: number, curr: number) => acc + curr, 0);
            const percentage = total ? ((value / total) * 100).toFixed(2) : 0;
            return `${label}: ${Math.round(value)} Kg (${percentage}%)`;
          }
        }
      },
      datalabels: {
        color: 'black',
        font: {
          size: 12,
          weight: 'bold' as const
        },
        formatter: (value: number) => `${Math.round(value)} Kg`,
        anchor: (context: any): 'end' | 'center' => {
          const value = context.dataset.data[context.dataIndex];
          return value < 5 ? 'end' : 'center';
        },
        align: (context: any): 'start' | 'center' => {
          const value = context.dataset.data[context.dataIndex];
          return value < 5 ? 'start' : 'center';
        },
        offset: (context: any) => (context.dataset.data[context.dataIndex] < 5 ? 10 : 0),
        clip: false
      }
    }
  };

  return (
    <PieDashBoardContainer>
      <Pie data={data} options={options} />
    </PieDashBoardContainer>
  );
}