import { useEffect, useState } from 'react';
import styled, { styled as sx } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import {
  Grid,
  Select,
  MenuItem,
  Stack,
  InputLabel,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  Container,
  TextField,
  DialogContent,
  DialogTitle
} from '@mui/material';

import CardsModalWeek from '../../components/CardsModalWeek';
import Date from '../../components/Date';
import SubCardComponent from '../../components/SubCard';

import { INeighborhoodSchedule, IRoute, blankNeighborhoodSchedule, blankRoute } from '../../models/Route';
import { IUser } from '../../models/User';
import { ICompany } from '../../models/Company';
import { IVehicle } from '../../models/Vehicle';

import { toast } from 'react-toastify';

import { UserService } from '../../services/UserService';
import { CompanyService } from '../../services/CompanyService';
import { VehicleService } from '../../services/VehicleService';

import { BigTextField } from '../../components/TextFields';
import RouteService from '../../services/RouteService';
import { validateRequired } from '../../utils/validations';
import CardsContainerModal from '../../components/CardsContainerModal';
import { INeighborhood } from '../../models/Neighborhood';
import NeighborhoodService from '../../services/NeighborhoodService';
import RouteNeighborhoodModal from './RouteNeighborhoodModal';
import RouteResidueModal from './RouteResidueModal';
import { getModelFromField } from '../../utils/MyLib';
import { IResidue } from '../../models/Residue';
import ResidueService from '../../services/ResidueService';
import { IPack } from '../../models/Pack';
import PackService from '../../services/PackService';
import './RouteForm.css';
import { ConfirmModal } from '../../components/ConfirmModal';
import CollectService from '../../services/CollectService';
import { ICollect } from '../../models/Collect';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { textAlign } from '@mui/system';
import { getAuthUser, protectForm } from '../../services/auth';
import { IRouteEvent } from '../../models/RouteEvent';
import RouteEventService from '../../services/RouteEventService';
import { weekday } from '../../utils/MyLib';
import ExpandableSection from '../../components/ExpandableSection';
import AddressBox from '../../components/AddressBox';
import { IAddress, blankAddress } from '../../models/Address';
import AddressModal from '../../components/AddressModal';

const CardsContent = sx.div`
  display: flex;
  width: 60px;
  height: 50px;
  padding: 11px 15px;
  background-color: var(--bgColor-1);
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius-p);
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  p {
    font-weight: 700;
  }
`;
const Separator = styled.hr`
  border: 1px solid lightgray; /* Cor da linha branca */
`;

export default function RouteForm() {
  const [route, setRoute] = useState<IRoute>(blankRoute);
  const [errors, setErrors] = useState<any>({});
  const [modal, setModal] = useState(true);
  const [modalResidue, setModalResidue] = useState(false);
  const [modalNeighborhood, setModalNeighborhood] = useState(false);
  const [allCompanies, setAllCompanies] = useState<ICompany[]>([]);
  const [allVehicles, setAllVehicles] = useState<IVehicle[]>([]);
  const [allCollectors, setAllCollectors] = useState<IUser[]>([]);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir a rota?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [collects, setCollects] = useState<ICollect[]>([]);
  const [routesEvents, setRoutesEvents] = useState<IRouteEvent[]>([]);
  const [modalAddress, setModalAddress] = useState(false);
  const [address, setAddress] = useState<IAddress>(blankAddress);
  const [addressEdited, setAddressEdited] = useState(0);
  const [isExpandInfo, setIsExpandInfo] = useState<boolean>(true);
  const [isExpandRoute, setIsExpandRoute] = useState<boolean>(true);
  const [isExpandLog, setIsExpandLog] = useState<boolean>(true);

  const navigate = useNavigate();

  let allNeighborhoods: INeighborhood[] = [];
  let allResiduesType: IResidue[] = [];
  let allPacks: IPack[] = [];

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    let company = await protectForm('/routes');
    route.company_id = company.id;
    try {
      let allCollectors = await UserService.getAll();
      allCollectors = allCollectors.filter((user) => user.role.name === 'Catador');
      setAllCollectors(allCollectors);

      allNeighborhoods = await NeighborhoodService.getAll();
      // let allCompanies = await CompanyService.getAll();
      // allCompanies = allCompanies.filter((company) => company.type === 'Coletadora');
      let allCompanies = [getAuthUser().company];
      setAllCompanies(allCompanies);
      let allVehicles = await VehicleService.getAll();
      setAllVehicles(allVehicles);
      let route = blankRoute;
      let collects: ICollect[] = [];
      let routesEvents: IRouteEvent[] = [];
      if (Number(id)) {
        route = await RouteService.getById(Number(id));
        collects = await CollectService.getByRoute(route.id);
        collects.filter((collect) => collect.route_id === route.id);
        routesEvents = await RouteEventService.getByRoute(route.id);
      }

      let company_addresss = await CompanyService.getAddress(route.company_id);
      company_addresss.id = undefined;
      route.address_start = route.address_start?.id ? route.address_start : company_addresss;
      route.address_end = route.address_end?.id ? route.address_end : company_addresss;

      route.schedule.map((schedule) => {
        let neighborhood = allNeighborhoods.find((neighborhood) => neighborhood.id === schedule.neighborhood_id);
        if (neighborhood) {
          schedule.neighborhood_name = neighborhood.neighborhood;
        }
        return schedule;
      });
      route.schedule.sort((a, b) => a.initial_time.localeCompare(b.initial_time));

      setCollects(collects);
      setRoutesEvents(routesEvents);

      route.packs_names = await RouteService.getPackNames(route.packs);
      setRoute(route);

      // Trocar pelo metodo que busque no servidor apenas as coletas daquela rota
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(route: IRoute) {
    let errors: any = {};
    if (!validateRequired(route.name)) errors.name = 'Obrigatorio';
    if (!validateRequired(route.observation)) errors.observation = 'Obrigatorio';
    if (!route.company_id) errors.company_id = 'Obrigatorio';
    if (!route.vehicle_id) errors.vehicle_id = 'Obrigatorio';
    if (!route.collector_id) errors.collector_id = 'Obrigatorio';

    return errors;
  }

  async function saveRoute(route: IRoute) {
    if (route.id) {
      if (await RouteService.update(route)) {
        toast.success('Rota salva com sucesso');
      }
    } else {
      let newRoute = await RouteService.create(route);
      if (newRoute.id > 0) {
        route.id = newRoute.id;
        toast.success('Rota criada com sucesso');
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(route);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveRoute(route);
      navigate('/routes');
    }
  }

  function handleDeleteConfirmation(route: IRoute) {
    setConfirmMessage('Deseja excluir a rota ' + route.name + ' ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await RouteService.delete(route.id)) {
      toast.success('Rota apagada com sucesso');
      route.id = -1;
      navigate('/routes');
    }
  }

  //Get values on form inputs and put into the model Route
  function handleChange(e: any) {
    let { field } = getModelFromField(e.target.name);
    let value = e.target.value;
    if (field === 'name') {
      value = value.toUpperCase();
    }

    if (field === 'recurrent') {
      value = value === 'true' ? true : false;
    }
    // if (field === 'company_id') {
    //   setRoute({ ...route, [field]: value, company: allCompanies.find((company) => company.id === value) });
    //   return;
    // }
    setRoute({ ...route, [field]: value });
  }

  const handleDateChange = (newDate: string) => {
    const dates = route.dates;
    if (dates.includes(newDate)) {
      return;
    }
    dates.push(newDate);
    dates.sort();
    setRoute({ ...route, dates: dates });
  };

  const handleRemoveClick = (index: number) => {
    const newDates = route.dates;
    newDates.splice(index, 1);
    setRoute({ ...route, dates: newDates });
  };

  const handleClick = (day: number) => {
    let weekdays = route.weekdays;
    if (weekdays.includes(day)) {
      // Se o dia já estiver selecionado, remova-o da lista de seleção
      weekdays = weekdays.filter((selectedDay) => selectedDay !== day);
      setRoute({ ...route, weekdays: weekdays });
    } else {
      // Se o dia não estiver selecionado, adicione-o à lista de seleção
      weekdays.push(day);
      weekdays.sort();
      setRoute({ ...route, weekdays: weekdays });
    }
  };

  function handleIncludeNeighborhood(neighborhoodSchedule: INeighborhoodSchedule) {
    let newSchedule = route.schedule;
    let i = route.schedule.find((schedule) => schedule.neighborhood_id === neighborhoodSchedule.neighborhood_id);
    if (i) {
      toast.error('Bairro já adicionado!');
      return;
    }
    newSchedule.push(neighborhoodSchedule);
    newSchedule.sort((a, b) => a.initial_time.localeCompare(b.initial_time));
    setRoute({ ...route, schedule: newSchedule });
    setModalNeighborhood(false);
  }

  async function handleIncludePack(pack_id: number) {
    if (route.packs.includes(pack_id)) {
      setModalResidue(false);
      return;
    }
    let packs_names = await RouteService.getPackNames([...route.packs, pack_id]);
    setRoute({ ...route, packs: [...route.packs, pack_id], packs_names: packs_names });
    setModalResidue(false);
  }

  async function handleEditAddress(addressEdited: number) {
    setAddressEdited(addressEdited);
    if (addressEdited === 0) {
      setAddress(route.address_start || blankAddress);
    } else {
      setAddress(route.address_end || blankAddress);
    }
    setModalAddress(true);
  }

  async function handleSaveAddress(address: IAddress) {
    if (addressEdited === 0) {
      setRoute({ ...route, address_start: address });
    } else {
      setRoute({ ...route, address_end: address });
    }
    setModalAddress(false);
  }

  function handleDeleteSchedule(neighborhood_id: number) {
    setRoute({ ...route, schedule: route.schedule.filter((schedule) => schedule.neighborhood_id !== neighborhood_id) });
  }

  function handleDeletePack(pack_id: number) {
    setRoute({ ...route, packs: route.packs.filter((p) => p !== pack_id) });
  }
  function handleEditCollect(collect_id: number) {}

  const handleIsExpandInfo = () => {
    setIsExpandInfo(!isExpandInfo);
  };

  const handleIsExpandRoute = () => {
    setIsExpandRoute(!isExpandRoute);
  };

  const handleIsExpandLog = () => {
    setIsExpandLog(!isExpandLog);
  };

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={modal} maxWidth="lg" disableEnforceFocus={true}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <PageTitle>FORMULARIO DE ROTA</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/routes');
              }}
            />
          </DialogTitle>
          <Separator />
          <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container columnSpacing={2} rowSpacing={1} sx={{ marginBottom: '0.5rem' }}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', width: '100%', gap: '0.5rem', alignItems: 'center' }}>
                  <h4>ROTA</h4>
                  <div onClick={handleIsExpandRoute} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <ExpandableSection isOpen={isExpandInfo} />
                  </div>
                </Grid>
                {isExpandRoute && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={12}>
                          <Stack>
                            <BigTextField
                              inputProps={{ style: { fontSize: 24 } }}
                              key="route.name"
                              variant="standard"
                              label="Nome da Rota"
                              name="route.name"
                              value={route.name}
                              onChange={handleChange}
                              error={errors.name}
                              helperText={errors.name}
                              placeholder="Nome"
                              focused
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={12} flexDirection={'column'}>
                          <Stack>
                            <p style={{ fontWeight: '700', display: 'flex', padding: '5px 0 4px 0' }}>Privacidade da Rota</p>
                            <RadioGroup key="route.privacy" name="route.privacy" row value={route.privacy} onChange={handleChange}>
                              <FormControlLabel key="rp01" value={0} label="Pública" control={<Radio />} />
                              <FormControlLabel key="rp02" value={1} label="Privada" control={<Radio />} />
                            </RadioGroup>
                          </Stack>
                          <Stack>
                            <p style={{ fontWeight: '700', display: 'flex', padding: '5px 0 4px 0' }}>Tipo de Rota</p>
                            <RadioGroup key="route.recurrent" name="route.recurrent" row value={route.recurrent} onChange={handleChange}>
                              <FormControlLabel key="rr01" value={true} label="Recorrente" control={<Radio />} />
                              <FormControlLabel key="rr02" value={false} label="Extraordinária" control={<Radio />} />
                            </RadioGroup>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {route.recurrent && <p style={{ fontWeight: '700', display: 'flex', padding: '5px 0 4px 0' }}>Dias da coleta</p>}
                          <div className="week-date-container">
                            {route.recurrent && (
                              <CardsModalWeek>
                                {weekday.map((day, index) => (
                                  <CardsContent
                                    key={'cc' + index}
                                    onClick={() => handleClick(index)}
                                    style={{
                                      backgroundColor: route.weekdays.includes(index) ? 'var(--primary-700)' : 'var(--bgColor-1)',
                                      color: route.weekdays.includes(index) ? 'var(--black-0)' : 'var(--black-600)'
                                    }}>
                                    <p>{day}</p>
                                  </CardsContent>
                                ))}
                              </CardsModalWeek>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {!route.recurrent && (
                        <div className="week-date-container">
                          <Date selectedDates={route.dates} onDateChange={handleDateChange} onRemoveClick={handleRemoveClick}></Date>
                        </div>
                      )}
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={12} sx={{ display: 'flex', width: '100%', gap: '0.5rem', alignItems: 'center' }}>
                  <h4>INFORMAÇÕES</h4>
                  <div onClick={handleIsExpandInfo} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <ExpandableSection isOpen={isExpandInfo} />
                  </div>
                </Grid>
                {isExpandInfo && (
                  <>
                    <Grid item xs={12} md={6} spacing={2}>
                      <Grid container columnSpacing={2} rowSpacing={1}>
                        <Grid item xs={12} md={10}>
                          <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }} size="small">
                            <InputLabel>Operador Responsável</InputLabel>
                            <Select
                              key="route.collector_id"
                              label="Operador Responsável"
                              name="route.collector_id"
                              value={route.collector_id}
                              onChange={handleChange}
                              error={errors.collector_id}
                              placeholder="Operador Responsável">
                              {allCollectors.map((collector) => (
                                <MenuItem key={'cl' + collector.id} value={collector.id}>
                                  {collector.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }} size="small">
                            <InputLabel>Operador Auxiliar</InputLabel>
                            <Select
                              key="route.collector_aux_id"
                              label="Operador Auxiliar"
                              name="route.collector_aux_id"
                              value={route.collector_aux_id}
                              onChange={handleChange}
                              error={errors.collector_id}
                              placeholder="Operador Auxiliar">
                              {allCollectors.map((collector) => (
                                <MenuItem key={'cl' + collector.id} value={collector.id}>
                                  {collector.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }} size="small">
                            <InputLabel>Veículo</InputLabel>
                            <Select key="route.vehicle_id" label="Veículo" name="route.vehicle_id" value={route.vehicle_id} onChange={handleChange} error={errors.collector_id} placeholder="Veículo">
                              {allVehicles.map((vehicle) => (
                                <MenuItem key={'v' + vehicle.id} value={vehicle.id}>
                                  {vehicle.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={11.35}>
                          <div
                            onDoubleClick={() => {
                              handleEditAddress(0);
                            }}>
                            <AddressBox address={route.address_start || blankAddress} title="Local de Partida" onClick={() => handleEditAddress(0)} />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={11.35}>
                          <div
                            onDoubleClick={() => {
                              handleEditAddress(1);
                            }}>
                            <AddressBox address={route.address_end || blankAddress} title="Local de Destino" onClick={() => handleEditAddress(1)} />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={11}>
                          <TextField
                            key="route.observation"
                            name="route.observation"
                            label="Observações"
                            value={route.observation}
                            onChange={handleChange}
                            error={errors.name}
                            placeholder="Observações"
                            variant="outlined"
                            size="small"
                            fullWidth
                            multiline
                            sx={{
                              margin: '0.5rem'
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ---------------- */}
                    <Grid item xs={12} md={6} spacing={2}>
                      <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={12}>
                          <CardsContainerModal height="180px" title="Área de Atendimento" openModal={() => setModalNeighborhood(true)} plusIcon="true">
                            {route.schedule.map((schedule, index) => (
                              <SubCardComponent
                                key={'scc' + index}
                                type={['delete']}
                                id={schedule.neighborhood_id || 0}
                                content={schedule.initial_time + ' - ' + schedule.end_time + ' ' + schedule.neighborhood_name + ' [' + schedule.city + ']'}
                                onDelete={handleDeleteSchedule}
                              />
                            ))}
                          </CardsContainerModal>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <CardsContainerModal height="180px" title="Serviços" openModal={() => setModalResidue(true)} plusIcon="true">
                            {route.packs.map((pack, index) => (
                              <SubCardComponent
                                key={'pack' + index}
                                id={pack}
                                type={['delete']}
                                content={route.packs_names && route.packs_names[index] ? route.packs_names[index] : ''}
                                onDelete={handleDeletePack}
                              />
                            ))}
                          </CardsContainerModal>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={12} sx={{ display: 'flex', width: '100%', gap: '0.5rem', alignItems: 'center' }}>
                  <h4>LOGÍSTICA</h4>
                  <div onClick={handleIsExpandLog} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <ExpandableSection isOpen={isExpandInfo} />
                  </div>
                </Grid>
                {isExpandLog && (
                  <>
                    <Grid item xs={12} md={6}>
                      <CardsContainerModal height="450px" plusIcon="false" title="Coletas Associadas" openModal={() => {}}>
                        {collects.map((collect, index) => (
                          <SubCardComponent
                            key={'collect' + index}
                            id={collect.id}
                            type={['edit']}
                            onEdit={handleEditCollect}
                            content={collect.generator ? collect.id + ' - ' + collect.generator.name + ' (' + collect.address.neighborhood + ')' : '' + collect.id}
                          />
                        ))}
                      </CardsContainerModal>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CardsContainerModal height="450px" plusIcon="false" title="Eventos de Rotas" openModal={() => {}}>
                        {routesEvents.map((route, index) => (
                          <SubCardComponent
                            key={'collect' + index}
                            id={route.id}
                            type={['edit']}
                            content={route.id ? route.id + ' - ' + route.date + ' (' + route.status + ')' : '' + route.id}
                            onEdit={(id) => {
                              navigate('/routes_events/' + id);
                            }}
                          />
                        ))}
                      </CardsContainerModal>
                    </Grid>
                  </>
                )}
              </Grid>

              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={route.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(route);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/routes');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
          <RouteNeighborhoodModal
            initialValue={blankNeighborhoodSchedule}
            city=""
            company_id={route.company_id}
            open={modalNeighborhood}
            onClose={() => setModalNeighborhood(false)}
            onSubmit={handleIncludeNeighborhood}></RouteNeighborhoodModal>
          <RouteResidueModal initialValue={0} open={modalResidue} onClose={() => setModalResidue(false)} onSubmit={handleIncludePack}></RouteResidueModal>
          <AddressModal initialValue={address} open={modalAddress} onClose={() => setModalAddress(false)} onSubmit={handleSaveAddress}></AddressModal>
          <ConfirmModal title="Deleção de Rotas" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
