export interface IWallet {
  id: number;
  balance: number;
  pix: string;
}

export const blankWallet: IWallet = {
  id: 0,
  balance: 0,
  pix: ''
};
