export interface IAccess {
  conditions: any;
  fields: string[];
  permissions: string[];
  system_entity: { name: string };
}
export interface IRole {
  name: string;
  access: IAccess[];
}
export const blankRole: IRole = {
  name: 'Gerador',
  access: [{ conditions: null, fields: [], permissions: ['CREATE', 'READ', 'UPDATE', 'DELETE'], system_entity: { name: 'User' } }]
};
export const allRolesNames = [
  { name: 'Super Admin', access: null },
  { name: 'Admin', access: null },
  { name: 'Operador', access: null },
  { name: 'Catador', access: null },
  { name: 'Gerador', access: null },
  { name: 'Gerador Comercial', access: null }
];
