import { IAddress, blankAddress } from './Address';
import { IWallet, blankWallet } from './Wallet';
import { IRole, blankRole } from './Role';
import { ICompany, blankCompany } from './Company';
import { IPlan, blankPlan } from './Plan';

export interface IUser {
  id: number;
  name: string;
  email: string;
  type: number; // 0-'Física' | 1-'Jurídica'
  document: string;
  active: number; //-1:Rejeitado, 0-Solicitado, 1-Ativo
  // cpf: string;
  password: string;
  phone_number: string;
  // active: boolean;
  img: string;
  wallet: IWallet;
  role: IRole;
  company_id?: number;
  company_name?: string;
  job_title?: string;
  address: IAddress;
  created_at: Date;
  updated_at: Date;
  password2?: string;
  cnh?: string;
  cnh_expiration?: string;
  plan?: IPlan;
}

export const blankUser: IUser = {
  id: 0,
  name: '',
  email: '',
  type: 0,
  document: '',
  active: 1,
  password: '',
  password2: '',
  phone_number: '',
  img: '',
  wallet: blankWallet,
  role: blankRole,
  company_id: 1,
  company_name: '',
  job_title: '',
  address: blankAddress,
  created_at: new Date(),
  updated_at: new Date(),
  cnh: '',
  cnh_expiration: ''
  // plan: blankPlan
};
export const UserStatus = [
  { id: 0, name: 'Solicitado' },
  { id: 1, name: 'Ativo' },
  { id: -1, name: 'Rejeitado' },
  { id: -2, name: 'Apagado' }
];

export const userProps = Object.keys(blankUser);
