import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EndFlag } from '../assets/EndFlag.svg';
import { ReactComponent as StartFlag } from '../assets/StartFlag.svg';
import { ReactComponent as ClockIcon } from '../assets/clock.svg';
import { Chip } from '@mui/material';
import { ICollectEvent } from '../models/CollectEvent';
import { IRouteEvent } from '../models/RouteEvent';

const PathCotainer = styled.div`
  width: 100%;
  height: 550px;
  border-radius: 6px;
  border: 2px solid var(--black-50);
  background: var(--black-50);
`;

const ContentContainer = styled.div`
  width: 70%;
  height: 100%;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  z-index: 0;
`;

const Start = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MainContentContainer = styled.div`
  display: flex;
  overflow-y: scroll;
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
  max-height: 400px;
`;

const Line = styled.div<{ minHeight: string }>`
  display: flex;
  flex-direction: column;
  background: var(--primary-700);
  width: 5px;
  min-height: ${(props) => props.minHeight};
  position: relative;
`;

const NeighborhoodMarker = styled.div<{ top: string }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--azul-700, #133940);
  background: var(--preto-0, #fff);
  position: absolute;
  z-index: 1;
  left: 0px;
  top: ${(props) => props.top};
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  left: -35px;
  padding: 0px 10px;
  max-height: 400px;
  position: relative;
`;

const ChipContainer = styled.div<{ top: string }>`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: ${(props) => props.top};
`;

const ClockContainer = styled.div<{ top: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: ${(props) => props.top};
`;

const NeighborhoodName = styled.p<{ top: string }>`
  position: absolute;
  z-index: 1;
  left: 30px;
  top: ${(props) => props.top};
`;

const End = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const cityLocation = 150;

type Props = {
  collectEvents: ICollectEvent[];
  routeEvent: IRouteEvent;
};
type IRoutePath = {
  time: string;
  neighborhood: string;
  qty: number;
};

export default function RoutePath({ collectEvents, routeEvent }: Props) {
  let routePath: IRoutePath[] = [];
  routeEvent.schedule.map((r) => {
    let qty = collectEvents.filter((c) => c.collect?.address.neighborhood === r.neighborhood_name).length;
    if (qty) routePath.push({ time: r.initial_time, neighborhood: r.neighborhood_name || '', qty: qty });
  });

  return (
    <PathCotainer>
      <ContentContainer>
        <Start>
          <StartFlag style={{ width: '40px', height: '40px' }}></StartFlag>
        </Start>
        <MainContentContainer>
          <LineContainer>
            <Line minHeight={routePath.length > 0 ? 200 + (routePath.length + 1) * 120 + 'px' : '400px'}></Line>
          </LineContainer>
          <LocationContainer>
            {routePath.map((r, index) => (
              <>
                <ClockContainer key={index} top={index * cityLocation + 20 + 'px'}>
                  <ClockIcon style={{ width: '20px', height: '20px' }}></ClockIcon>
                  <p>{r.time}</p>
                </ClockContainer>
                <NeighborhoodMarker key={index} top={index * cityLocation + 60 + 'px'}></NeighborhoodMarker>
                <NeighborhoodName key={index} top={index * cityLocation + 60 + 'px'}>
                  {r.neighborhood}
                </NeighborhoodName>
                <ChipContainer key={index} top={index * cityLocation + 100 + 'px'}>
                  <Chip
                    label={`${r.qty} Coletas`}
                    sx={{
                      borderRadius: 'var(--borderRadius-xg)',
                      border: '1px solid var(--primary-700)',
                      background: '#FFF',
                      height: 'auto'
                    }}
                  />
                </ChipContainer>
              </>
            ))}
          </LocationContainer>
        </MainContentContainer>
        <End>
          <EndFlag style={{ width: '40px', height: '40px' }}></EndFlag>
        </End>
      </ContentContainer>
    </PathCotainer>
  );
}
