export interface IAddress {
  id?: number;
  street: string;
  complement: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string;
  lat: string;
  lng: string;
}

export const blankAddress: IAddress = {
  street: '',
  complement: '',
  number: '',
  neighborhood: '',
  city: '',
  state: 'RJ',
  zip_code: '',
  lat: '',
  lng: ''
};
