import React from 'react';
import styled from 'styled-components';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SortIcon from '@mui/icons-material/Sort';
import { display } from '@mui/system';

const Container = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-right: 40px;
  flex-direction: column;
  width: 100%;
`;

export const MainCard = styled.div<{ height?: string; error?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(props) => (props.error ? 'var(--red-700)' : 'var(--black-300)')};
  width: 100%;
  height: ${(props) => props.height || '175px'};
  border-radius: var(--borderRadius-m);
  align-items: center;
  overflow-y: hidden;
`;

const CardsHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.p`
  padding-left: 16px;
  font-weight: 700;
`;
const Error = styled.p`
  color: var(--red-700);
  font-weight: 700;
`;

const Line = styled.div`
  width: 100%;
  border: 1px solid #e8e8e8;
  margin: 5px 0;
`;
const CardsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 95%;
  height: 88%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  gap: 6px;
`;

type Props = {
  height?: string;
  title: string;
  plusIcon?: string;
  sortIcon?: string;
  children: React.ReactNode;
  error?: string;
  openModal: () => void;
  sort?: () => void;
};

const CardsContainerModal = ({ height, title, children, openModal, sort, plusIcon, sortIcon, error }: Props) => {
  return (
    <Container>
      <MainCard height={height} error={error}>
        <CardsHeader>
          <div style={{ display: 'flex' }}>
            {sortIcon === 'true' && <SortIcon sx={{ color: 'var(--primary-400)', cursor: 'pointer' }} onClick={sort} />}
            <Title>{title}</Title>
            <Error>{error}</Error>
          </div>

          {plusIcon === 'true' && <AddCircleRoundedIcon sx={{ color: 'var(--primary-400)', cursor: 'pointer' }} onClick={openModal} />}
        </CardsHeader>
        <Line />
        <CardsContent>{children}</CardsContent>
      </MainCard>
    </Container>
  );
};

export default CardsContainerModal;
