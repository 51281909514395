import { create } from 'zustand';

type CollectStore = {
  collect_id: number;
  setSelectedCollect: (id: number) => void;
  unsetSelectedCollect: () => void;
};

const useCollectStore = create<CollectStore>((set) => ({
  collect_id: 0,
  setSelectedCollect: (id) => set((state) => ({ collect_id: id })),
  unsetSelectedCollect: () => set({ collect_id: 0 })
}));

export default useCollectStore;
