import './Autentication.css';
import logo from '../../assets/logo3.png';
import { LoginTextField } from '../../components/TextFields';

import { useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validateRequired } from '../../utils/validations';

export default function ConfirmPasswordPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IForm>({ email: '', pin: '', password: '', password2: '' });
  const [errors, setErrors] = useState<any>({});
  interface IForm {
    email: string;
    pin: string;
    password: string;
    password2: string;
  }

  async function handleChange(e: any) {
    let field = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [field]: value });
  }
  function validateErrors(formData: IForm) {
    let errors: any = {};
    if (!validateEmail(formData.email)) errors.email = 'Email invalido';
    if (!validateRequired(formData.pin)) errors.pin = 'Pin Obrigatorio';
    if (!validateRequired(formData.password)) errors.password = 'Preencha a senha!';
    if (formData.password.length < 6) errors.password = 'A senha deve conter no mínimo 6 caracteres!';
    if (formData.password !== formData.password2) errors.password2 = 'Senhas não conferem!';
    return errors;
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const errors = validateErrors(formData);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await toast.promise(api.post('users/pw/reset', formData), {
          pending: 'Aguarde...',
          success: 'Senha Alterada!',
          error: 'Email não encontrado!'
        });
        navigate('/login');
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit}>
        <div className="login-container">
          <img className="login-img" src={logo} alt="Siri Logo"></img>
          <h1>Esquecí a senha</h1>
          <p>Informe o email, a nova senha e o pin que foi enviado por email! </p>

          <LoginTextField key="email" label="Email" name="email" value={formData.email} onChange={handleChange} error={errors.email} helperText={errors.email} />
          <LoginTextField key="pin" label="Pin" name="pin" value={formData.pin} onChange={handleChange} error={errors.pin} helperText={errors.pin} />
          <LoginTextField key="password" label="Senha" type="password" name="password" value={formData.password} onChange={handleChange} error={errors.password} helperText={errors.password} />
          <LoginTextField key="password2" label="Senha" type="password" name="password2" value={formData.password2} onChange={handleChange} error={errors.password2} helperText={errors.password2} />
          <button type="submit" className="login-button">
            REDEFINIR SENHA
          </button>
          <a href="login">Retornar</a>
        </div>
      </form>
    </div>
  );
}
