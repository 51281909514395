import api from './api';
import { collectResiduesProps, ICollectResidues, blankCollectResidues } from '../models/CollectResidues';
import { toast } from 'react-toastify';

export class CollectResiduesService {
  static async getAll(): Promise<ICollectResidues[]> {
    try {
      const response = await api.get('/reports/collect_residues');
      let allResidues = response.data as ICollectResidues[];
      return allResidues;
    } catch (error: any) {
      // toast.error(error.message || 'Erro ao buscar Impactos Positivoss');
      return [];
    }
  }

  static async getById(id: number): Promise<ICollectResidues[]> {
    try {
      const response = await api.get(`/reports/collect_residues/${id}`);
      let allResidues = response.data as ICollectResidues[];
      return allResidues;
    } catch (error: any) {
      // toast.error(error.message || 'Erro ao buscar Impactos Positivos!');
      return [];
    }
  }

    static async getByMonth(id: number, init_date: string, end_date: string): Promise<ICollectResidues[]> {
    try {
      const response = await api.get(`/reports/collect_residues/${id}?init_date=${init_date}&end_date=${end_date}`);
      let allPositiveImpacts = response.data as ICollectResidues[];
      return allPositiveImpacts;
    } catch (error: any) {
      // toast.error(error.message || 'Erro ao buscar Impactos Positivoss');
      return [];
    }
  }

  static standarlizePositiveImpact(v: ICollectResidues | any): ICollectResidues {
    return v;
  }
}

export default CollectResiduesService;
