import { IAddress, blankAddress } from './Address';
import { ICollectEvent } from './CollectEvent';
import { ICompany, blankCompany } from './Company';
import { INeighborhoodSchedule, IRoute, blankRoute } from './Route';
import { IUser, blankUser } from './User';
import { IVehicle, blankVehicle } from './Vehicle';

export interface IRouteEvent {
  id: number;
  name: string;
  date: string;
  status: number;
  privacy: number; //0-Pública, 1-Privada
  started_at?: string;
  finished_at?: string;
  lat: string;
  lng: string;
  obs?: string;
  schedule: Array<INeighborhoodSchedule>;
  packs: Array<number>; //pack_id: number

  company_id: number;
  company?: ICompany;
  destinator_id?: number;
  destinator?: ICompany;
  route_id: number;
  route: IRoute;
  vehicle_id: number;
  vehicle?: IVehicle;
  collector_id: number;
  collector?: IUser;
  collector_aux_id: number;
  collector_aux?: IUser;
  address_start?: IAddress;
  address_end?: IAddress;
  //0-Planejada, 1-Cancelada, 2-Atrasada, 3-Em andamento, 4-Aguardando conclusão, 5-Finalizada
  //Atributos auxilizares (não fazem parte da api)

  collectEvents?: ICollectEvent[];
  qty?: number;

  neighborhoods_name?: Array<string>;
  packs_names?: Array<string>;
  city?: string;
  state?: string;
}

export const blankRouteEvent: IRouteEvent = {
  id: 0,
  name: '',
  date: '',
  status: 1,
  privacy: 0,
  started_at: '',
  finished_at: '',
  lat: '',
  lng: '',
  obs: '',
  schedule: [],
  packs: [],
  company_id: 0,
  company: blankCompany,
  destinator_id: 0,
  destinator: blankCompany,
  route_id: 0,
  route: blankRoute,
  vehicle_id: 0,
  vehicle: blankVehicle,
  collector_id: 0,
  collector: blankUser,
  collector_aux_id: 0,
  collector_aux: blankUser,
  address_start: blankAddress,
  address_end: blankAddress,

  collectEvents: [],
  qty: 0
};
export const routeEventStatus = [
  { id: 0, name: 'Cancelada', color: '#730E18', background: '#E5F5F9' },
  { id: 1, name: 'Pendente', color: '#35A1B7', background: '#E5F5F9' },
  { id: 2, name: 'Aceita', color: '#513D00', background: '#FFFAEA' },
  { id: 3, name: 'Em andamento', color: '#17B51C', background: '#E6F6E5' },
  { id: 4, name: 'Pausada', color: '#FFD140', background: '#E5F5F9' },
  { id: 5, name: 'Finalizada', color: '#964b00', background: '#E5F5F9' },
  { id: 6, name: 'Pesada', color: '#133940', background: '#E5F5F9' }
];
export const routeEventProps = Object.keys(blankRouteEvent);
// export const routeEventStatus = [
//   { id: 0, name: 'Planejada', color: '#35A1B7', background: '#E5F5F9' },
//   { id: 1, name: 'Cancelada', color: '#730E18', background: '#E5F5F9' },
//   { id: 2, name: 'Confirmada', color: '#513D00', background: '#FFFAEA' },
//   { id: 3, name: 'Em andamento', color: '#17B51C', background: '#E6F6E5' },
//   { id: 4, name: 'Aguardando conclusão', color: '#735700', background: '#E5F5F9' },
//   { id: 5, name: 'Finalizada', color: '#133940', background: '#E5F5F9' },
//   { id: 6, name: 'Pausada', color: '#FFD140', background: '#E5F5F9' },
//   { id: 7, name: 'Aguardando Segregação', color: '#FFD140', background: '#E5F5F9' },
//   { id: 8, name: 'Em segregação', color: '#FFD140', background: '#E5F5F9' },
//   { id: 9, name: 'Em estoque', color: '#FFD140', background: '#E5F5F9' },
//   { id: 10, name: 'Destinada parcialmente', color: '#133940', background: '#E5F5F9' },
//   { id: 11, name: 'Destinada', color: '#133940', background: '#E5F5F9' }
// ];
