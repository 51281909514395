import PageStructure from '../components/PageStructure';
import { PageContainer, PageContent, PageHeader, PageFooter, PageTitle } from '../components/GlobalStyleds.jsx';

export default function NotFoundPage() {
  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>Erro</PageTitle>
        </PageHeader>
        <PageContent>Página não encontrada</PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
