import { AccountCircle, Download } from '@mui/icons-material';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import 'dayjs/locale/pt-br';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_Row, type MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { baseImg } from '../../App';
import { ConfirmModal } from '../../components/ConfirmModal';
import { LoadWrapper, PageContainer, PageContent, PageFooter, PageHeader, PageTitle } from '../../components/GlobalStyleds.jsx';
import PageStructure from '../../components/PageStructure';
import { ICompany } from '../../models/Company';
import { IVehicle, blankVehicle } from '../../models/Vehicle';
import CompanyService from '../../services/CompanyService';
import VehicleService from '../../services/VehicleService';
import { getAuthUser } from '../../services/auth';
import { formatDate } from '../../utils/MyLib';
import { VehicleModal } from './VehicleModal';
import './VehiclePage.css';

export default function VehiclePage() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [vehiclesData, setVehiclesData] = useState<IVehicle[]>([]);
  const [loading, setLoading] = useState(true);
  const [vehicle, setVehicle] = useState<IVehicle>(blankVehicle);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir o veículo?');

  const tableInstanceRef = useRef<MRT_TableInstance<IVehicle>>(null);
  const [rowSelection, setRowSelection] = useState({});

  let allVehicles: IVehicle[] = [];
  let allCompanies: ICompany[] = [];

  async function fetchData() {
    setLoading(true);
    allCompanies = await CompanyService.getAll();
    allVehicles = await VehicleService.getAll();
    allVehicles.map((v) => {
      v.company = allCompanies.find((company) => company.id === v.company_id);
      return v;
    });
    setVehicle(blankVehicle);
    setLoading(false);
    setVehiclesData(allVehicles);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [reload, setReload] = useState(false);  

  useEffect(() => {
    fetchData();
  }, [reload]);

  // Function called when the modal closes with confirm button
  function handleSave(vehicle: IVehicle, operation: string): void {
    const index = vehiclesData.findIndex((c) => c.id === vehicle.id);
    switch (operation) {
      case 'create':
        vehiclesData.push(vehicle);
        break;
      case 'update':
        vehiclesData.splice(index, 1);
        break;
      case 'delete':
        vehiclesData.splice(index, 1);
        break;
      default:
        break;
    }
    setCreateModalOpen(false);
    setVehiclesData([...vehiclesData]);
    fetchData();
  }

  // Abre o modal de criação de um novo veículo
  function handleNewRow() {
    setVehicle({ ...blankVehicle, company_id: getAuthUser().company_id, company: getAuthUser().company });
    setCreateModalOpen(true);
  }

  // Busca o veículo no backend e abre o modal de edição
  async function handleEditRow(row: MRT_Row<IVehicle>) {
    setLoading(true);
    let v = await VehicleService.getById(row.getValue('id'));
    if (v.id >= 0) {
      setVehicle(v);
      setLoading(false);
      setCreateModalOpen(true);
    }
  }

  // Exclui o veículo
  function handleDelete() {
    setShowConfirmationModal(false);
    setLoading(true);
    VehicleService.delete(vehicle.id).then((result) => {
      const index = vehiclesData.findIndex((c) => c.id === vehicle.id);
      vehiclesData.splice(index, 1);
      setLoading(false);
    });
  }

  // Exclui o veículo
  function handleDeleteConfirmation(row: MRT_Row<IVehicle>) {
    setConfirmMessage('Deseja excluir o veículo ' + row.getValue('name') + '?');
    setShowConfirmationModal(true);
  }

  //TODO: Implementar exportação
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = vehiclesData.map((v) => {
      return {
        id: v.id,
        name: v.name,
        description: v.description,
        capacity: v.capacity,
        created_at: v.created_at
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  async function handleActive(e: any) {
    for (var prop in rowSelection) {
      let vehicle = await VehicleService.getById(Number(prop));
      vehicle.status = e.target.name === 'btnActive' ? 0 : 1;
      await VehicleService.update(vehicle);
    }
    fetchData();
  }

  const columns = useMemo<MRT_ColumnDef<IVehicle>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        acessorKey: 'Status',
        header: 'Status',
        Cell: ({ row }) => (!row.original.status ? <CheckCircleOutlineSharpIcon sx={{ color: 'green' }} /> : <span />),
        size: 20
      },

      {
        accessorKey: 'name',
        id: 'name', //id is still required when using accessorFn instead of accessorKey
        header: 'Nome do Veículo',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}>
            <Avatar
              sx={{
                height: '30px',
                width: '30px',
                bgcolor: 'transparent'
              }}
              src={row.original.img ? baseImg + row.original.img : ''}
            />

            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        )
      },
      {
        accessorKey: 'type',
        header: 'Veículo',
        size: 50
      },
      {
        accessorKey: 'fuel',
        header: 'Combustivel'
      },
      {
        accessorKey: 'company.fantasy_name',
        header: 'Empresa'
      },
      {
        accessorKey: 'capacity',
        header: 'Capacidade',
        Cell: ({ cell }) => cell.getValue() + ' Kg'
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>CENTRAL DE VEÍCULOS</PageTitle>
        </PageHeader>
        <PageContent>
          {vehiclesData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhum veículo encontrado</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 300,
                  height: 50
                }}
                color="primary"
                onClick={handleNewRow}
                variant="contained"
                startIcon={<AccountCircle />}>
                Novo
              </Button>
            </Stack>
          )}

          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {vehiclesData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={vehiclesData}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false } }}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNewRow} variant="contained" startIcon={<AccountCircle />}>
                    Novo
                  </Button>
                  <Button name="btnActive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Ativar
                  </Button>
                  <Button name="btnInactive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Desativar
                  </Button>

                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Exportar
                  </Button>
                </Stack>
              )}
              renderDetailPanel={({ row }) => (
                <Grid container rowSpacing={0} alignItems="center">
                  {/* 1 */}
                  <Grid item xs={1} alignItems="center" justifyContent="center">
                    <Avatar sx={{ height: 70, width: 70 }} src={row.original.img} />
                  </Grid>
                  <Grid item xs={4} alignContent="left" sx={{ marginBottom: 2, paddingRight: 2 }}>
                    <ListItemText primaryTypographyProps={{ style: { fontSize: '1.1rem', fontWeight: 'bold', wordWrap: 'break-word' } }} primary={row.original.name} />
                  </Grid>
                  <Grid item xs={1} alignContent="left">
                    <ListItemText
                      secondaryTypographyProps={{ style: { fontSize: '1.1rem', color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      secondary={row.original.id}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Data criação"
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      primary={formatDate(row.original.created_at)}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Última Edição"
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      primary={formatDate(row.original.updated_at)}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText
                      secondary="Status"
                      primary={row.original.status ? 'Em operação ' : 'Em manutenção'}
                      primaryTypographyProps={{ style: { color: 'var(--preto-400, #A4A4A4)', fontWeight: '400' } }}
                      sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    />
                  </Grid>
                  {/* 2 */}
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Nome do Veículo" primary={row.original.name} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Descrição" primary={row.original.description} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Tipo de Veículo" primary={row.original.type} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Tipo de Combustível" primary={row.original.fuel} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Capacidade (Kg)" primary={row.original.capacity} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                  <Grid item xs={2} alignContent="left">
                    <ListItemText secondary="Consumo (Km/L)" primary={row.original.consumption} sx={{ display: 'flex', flexDirection: 'column-reverse' }} />
                  </Grid>
                </Grid>
              )}
            />
          )}

          <VehicleModal value={vehicle} open={createModalOpen} onClose={() => setCreateModalOpen(false)} onSubmit={handleSave} />
          <ConfirmModal title="Deleção de Empresas" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
