import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#133940',
      light: '#484848',
      dark: '#484848',
      contrastText: '#fff'
    },
    secondary: {
      main: '#226775',
      light: '#484848',
      dark: '#484848',
      contrastText: '#fff'
    },
    contrastThreshold: 3
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});
