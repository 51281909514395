import api from './api';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';
import { ICollectEvent, blankCollectEvent, collectEventProps } from '../models/CollectEvent';

export default class CollectEventService {
  static async getAll(): Promise<ICollectEvent[]> {
    try {
      const response = await api.get('/collect-events');
      let allCollectEvents = response.data as ICollectEvent[];
      return allCollectEvents;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas');
      return [];
    }
  }

  static async getById(id: number): Promise<ICollectEvent> {
    try {
      const response = await api.get(`/collect-events/${id}`);
      let r = response.data;
      r = mapNullToString(r);
      clearObj(r, collectEventProps);
      return r;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas!');
      return blankCollectEvent;
    }
  }

  static async getByCollectId(id: number): Promise<ICollectEvent[]> {
    try {
      const response = await api.get(`/collect-events?collect_id=${id}`);
      let allCollectEvents = response.data as ICollectEvent[];
      return allCollectEvents;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas');
      return [];
    }
  }

  static async sortCollects(id: number): Promise<ICollectEvent[]> {
    try {
      const response = await api.get(`/collect-events/${id}/sort`);
      let r = response.data;
      return r;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao ordenar as coletas desse evento de rota!');
      return [];
    }
  }

  static async getByRouteEventId(id: number): Promise<ICollectEvent[]> {
    try {
      const response = await api.get(`/collect-events?route_event_id=${id}`);
      let allCollectEvents = response.data as ICollectEvent[];
      return allCollectEvents;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas');
      return [];
    }
  }

  static async create({ id, ...collectEvent }: ICollectEvent): Promise<ICollectEvent> {
    try {
      this.standarlize(collectEvent);
      const response = await api.post('/collect-events', collectEvent);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir coletas');
        return blankCollectEvent;
      }
      toast.success('Coletas incluídas com sucesso!');
      return response.data as ICollectEvent;
    } catch (error: any) {
      toast.error('Erro ao incluir coletas!');
      return blankCollectEvent;
    }
  }
  static async update({ ...collectEvent }: ICollectEvent): Promise<boolean> {
    try {
      this.standarlize(collectEvent);
      let response = await api.put(`/collect-events/${collectEvent.id}`, collectEvent);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar coletas!');
        return false;
      }
      // toast.success('Coleta atualizada com sucesso!');
      return true;
    } catch (error: any) {
      // toast.error('Erro ao Atualizar coletas!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/collect-events/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir coletas!');
        return false;
      }
      toast.success('Coleta excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir coletas!');
      return false;
    }
  }

  static standarlize(m: ICollectEvent | any): ICollectEvent {
    m.company_id = Number(m.company_id);
    m.company_id = m.company_id || undefined;
    m.score = Number(m.score) || undefined;
    m.score_by_collector = Number(m.score_by_collector) || undefined;
    m.sequence = Number(m.sequence) || 0;
    m.status = Number(m.status) || 0;
    m.collect_id = Number(m.collect_id) || undefined;
    m.route_event_id = Number(m.route_event_id) || undefined;
    m.weighing_id = Number(m.weighing_id) || undefined;
    console.log(m);
    return m;
  }
}
