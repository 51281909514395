import React from 'react';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IMaterial } from '../models/Material';

const SubCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin: 0.5px;
  width: 100%;
  border-radius: var(--borderRadius-m);
  background: var(--bgColor-1);
  box-shadow: var(--shadow-1);
`;

const SecondSubCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5px;
  width: 95%;
`;

const SecondSubCardMaterial = styled.div`
  width: 75%;
  padding: 0.5rem;
  border-radius: var(--borderRadius-m);
  background: rgba(195, 197, 199, 0.2);
  box-shadow: var(--shadow-1);
`;

const SecondSubCardWeight = styled.div`
  width: 20%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #00abee;
  border-radius: var(--borderRadius-m);
  background: #ffffff;
  box-shadow: var(--shadow-1);
`;

export const SubCardText = styled.p`
  font-size: 12px;
  font-weight: 700;
`;

type Props = {
  id: number;
  content?: string;
  type?: string[];
  value?: IMaterial;
  format?: number;
  onClick?: (id: number) => void;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
};

export default function SubCard({ id, content, type, value, format, onClick, onDelete, onEdit }: Props) {
  function handleClick(id: number) {
    if (onClick) {
      onClick(id);
    }
  }

  function handleEdit(id: number) {
    if (onEdit) {
      onEdit(id);
    }
  }

  function handleDelete(id: number) {
    if (onDelete) {
      onDelete(id);
    }
  }

  if (format === 2) {
    return (
      <SecondSubCardContainer>
        <SecondSubCardMaterial onClick={() => handleClick(id)}>
          <SubCardText>{content}</SubCardText>
        </SecondSubCardMaterial>
        <SecondSubCardWeight>
          <SubCardText>
            {Number(value?.weight).toFixed(1)} {value?.unit_abreviation}
          </SubCardText>
        </SecondSubCardWeight>
        {type?.includes('delete') && <DeleteIcon sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDelete(id)} />}
        {type?.includes('edit') && <EditIcon sx={{ cursor: 'pointer', color: 'green' }} onClick={() => handleEdit(id)} />}
      </SecondSubCardContainer>
    );
  } else {
    return (
      <SubCardContainer>
        <SubCardText onClick={() => handleClick(id)}>
          <div>{content}</div>
        </SubCardText>
        {type?.includes('delete') && <DeleteIcon sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDelete(id)} />}
        {type?.includes('edit') && <EditIcon sx={{ cursor: 'pointer', color: 'green' }} onClick={() => handleEdit(id)} />}
      </SubCardContainer>
    );
  }
}
