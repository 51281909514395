import api from './api';
import { IContainer, blankContainer, containerProps } from '../models/Container';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class ContainerService {
  static async getAll(): Promise<IContainer[]> {
    try {
      const response = await api.get('/containers');
      let allContainers = response.data as IContainer[];
      return allContainers;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar containers');
      return [];
    }
  }
  static async getById(id: number): Promise<IContainer> {
    try {
      const response = await api.get(`/containers/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, containerProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar container!');
      return blankContainer;
    }
  }
  static async getContainerName(id: number) {
    let c = await ContainerService.getById(id);
    return c.name;
  }

  static async create({ id, ...container }: IContainer): Promise<IContainer> {
    try {
      this.standarlizeContainer(container);
      const response = await api.post('/containers', container);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir container');
        return blankContainer;
      }
      toast.success('Acondicionamento incluído com sucesso!');
      return response.data as IContainer;
    } catch (error: any) {
      toast.error('Erro ao incluir container!');
      return blankContainer;
    }
  }
  static async update({ ...container }: IContainer): Promise<boolean> {
    try {
      this.standarlizeContainer(container);
      console.log(container);
      let response = await api.put(`/containers/${container.id}`, container);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar container!');
        return false;
      }
      toast.success('Acondicionamento atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar container!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/containers/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir container!');
        return false;
      }
      toast.success('Acondicionamento excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir container!');
      return false;
    }
  }

  static standarlizeContainer(c: IContainer | any): IContainer {
    c.codInea = Number(c.codInea);
    c.codInea = c.codInea || undefined;
    return c;
  }
}

export default ContainerService;
