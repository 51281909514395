import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Select,
  MenuItem,
  Stack,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  LinearProgress
} from '@mui/material';

import { IUser, blankUser } from '../../models/User';
import { toast } from 'react-toastify';
import { validateEmail, validateRequired } from '../../utils/validations';
import { getModelFromField, maskPhone } from '../../utils/MyLib';
import InputMask from 'react-input-mask';
import { ICompany, blankCompany } from '../../models/Company';
import { allStatesNames } from '../../models/States';
import { getAuthUser } from '../../services/auth';
import { ZipService } from '../../services/ZipService';
import { BigTextField, MediumTextField, SmallTextField } from '../../components/TextFields';
import { AvatarUpload } from '../../components/AvatarUpload';
import { ImgUploadService } from '../../services/ImgUploadService';
import styled from 'styled-components';
import { blankAddress } from '../../models/Address';
// import { document } from 'document-cnpj-validator';
import { CardTypeUser } from '../../components/CardTypeUser';
import { UserService } from '../../services/UserService';
import { baseImg } from '../../App';
import { ConfirmModal } from '../../components/ConfirmModal';
import { useNavigate, useParams } from 'react-router-dom';
import PageStructure from '../../components/PageStructure';
import { PageContainer } from '../../components/GlobalStyleds';
import CompanyService from '../../services/CompanyService';
import { CardTypeCompany } from '../../components/CardTypeCompany';

const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;

export default function CompanyForm() {
  const [company, setCompany] = useState<ICompany>(blankCompany);
  const [userAdmin, setUserAdmin] = useState<IUser>(blankUser);
  const [errors, setErrors] = useState<any>({});
  const [possibleCompanies, setPossibleCompanies] = useState<ICompany[]>([]);
  const [possibleAdmins, setPossibleAdmins] = useState<IUser[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir a empresa?');
  const [modal, setModal] = useState(true);

  const navigate = useNavigate();

  let { id } = useParams();
  let address = company.address;

  let logo = company.logo ? baseImg + company.logo : '';
  let fileImage: File = new File([], '');
  let docMask = userAdmin.type === 0 ? '999.999.999-99' : '99.999.999/9999-99';

  //Get all collectors
  async function fetchData() {
    try {
      let company = blankCompany;
      let userAdmin = blankUser;

      if (Number(id)) {
        company = await CompanyService.getById(Number(id));
        if (company.admin_id) {
          userAdmin = (await UserService.getById(company.admin_id)) || blankUser;
        }
      }

      setUserAdmin(userAdmin);
      setCompany(company);
      CompanyService.getPermissionsCompanies().then((result) => {
        setPossibleCompanies(result);
      });
      let users: IUser[] = [];
      users = await UserService.getAll();
      users = users.filter((u) => {
        return u.role.name === 'Admin';
      });
      setPossibleAdmins(users);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
      console.log(errors);
    }
  }, [errors]);

  function handleDeleteConfirmation(company: ICompany) {
    setConfirmMessage('Deseja excluir a empresa ' + company.fantasy_name + ' ?');
    setShowConfirmationModal(true);
  }

  async function handleDelete() {
    setShowConfirmationModal(false);
    setLoading(true);
    if (await CompanyService.delete(company.id)) {
      setLoading(false);
      toast.success('Empresa apagada com sucesso');
      company.id = -1;
      navigate('/companies', { state: { reload: true } });
    }
  }

  function validateErrors(company: ICompany) {
    let errors: any = {};
    if (!validateRequired(company.business_name)) errors.business_name = 'Obrigatorio';
    if (!validateRequired(company.fantasy_name)) errors.fantasy_name = 'Obrigatorio';

    if (!company.type.length) errors.type = 'Perfil Obrigatorio';
    if (!validateRequired(company.phone_number)) errors.phone_number = 'Telefone Obrigatorio';
    if (!validateRequired(company.contact)) errors.phone_number = 'Contato Obrigatorio ( Email, Telefone ou Site)';
    if (!validateRequired(company.address.zip_code)) errors.zip_code = 'Cep Obrigatorio';
    if (!validateRequired(company.address.street)) errors.street = 'Rua Obrigatorio';
    if (!validateRequired(company.address.neighborhood)) errors.neighborhood = 'Bairro Obrigatorio';
    if (!validateRequired(company.address.city)) errors.city = 'Cidade Obrigatoria';
    if (!validateRequired(company.address.state)) errors.state = 'Estado Obrigatorio';
    if (!validateRequired(company.cnpj)) errors.cnpj = 'Cnpj Obrigatorio';
    // if (!company.id && !validateRequired(userAdmin.document)) errors.document = 'document Obrigatorio';
    // if (!company.id && !validateRequired(userAdmin.name)) errors.name = 'Nome Obrigatorio';
    // if (!company.id && !validateRequired(userAdmin.email)) errors.email = 'Email Obrigatorio';
    // if (!company.id && !validateEmail(userAdmin.email)) errors.email = 'Email invalido';
    if (!company.type.includes('Coletadora') && !company.company_id) errors.company_id = 'Empresas não coletadoras deve ser vinculada a uma empresa';
    // if (!cnpj.isValid(company.cnpj)) errors.cnpj = 'CNPJ invalido';
    return errors;
  }

  function saveImage(company: ICompany, fileImage: File): void {
    if (fileImage.size > 0) {
      if (fileImage.size <= 1000000) {
        ImgUploadService.upload('companies', company.id, fileImage);
      } else {
        toast.error('Imagem muito grande, tamanho máximo de 1mb');
      }
    }
  }

  // async function createUser(userAdmin: IUser): Promise<IUser> {
  //   userAdmin.password = userAdmin.document.replace(/\D/g, '');
  //   userAdmin.phone_number = company.phone_number;
  //   // userAdmin.role.name = 'Admin';
  //   userAdmin.company_id = company.id;
  //   userAdmin.address = company.address;
  //   userAdmin.email = userAdmin.email;
  //   userAdmin.role.name = 'Operador';
  //   if (company.type.includes('Coletadora')) {
  //     userAdmin.role.name = 'Admin';
  //   } else if (company.type.includes('Ecoponto')) {
  //     userAdmin.role.name = 'Operador';
  //   }
  //   return await UserService.create(userAdmin);
  // }
  async function saveCompany(company: ICompany) {
    console.log(company);
    if (company.id) {
      if (await CompanyService.update(company)) {
        saveImage(company, fileImage);
        toast.success('Empresa atualizada com sucesso!');
        navigate('/companies', { state: { reload: true } });
      }
    } else {
      let newCompany = await CompanyService.create(company);
      // if (newCompany.id > 0) {
      //   company.id = newCompany.id;
      //   company.company_id = newCompany.id;
      //   await CompanyService.update(company);
      //   saveImage(company, fileImage);
      //   if (userAdmin.document) {
      //     let u = await createUser(userAdmin);
      //     if (u && u.id > 0) {
      //       company.admin_id = u.id;
      //       company.admin = u;
      //       await CompanyService.update(company);
      //     }
      //   }
      //   userAdmin.company_id = newCompany.id;
      // }
      if (newCompany.id > 0) {
        toast.success('Empresa criada com sucesso!');
        navigate('/companies', { state: { reload: true } });
      }
      
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(company);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveCompany(company);
    }
  };

  //make a function to take a substring up dot position and return the inital string
  function getModelFromField(field: string) {
    try {
      let index = field.indexOf('.');
      return { model: field.substring(0, index), field: field.substring(index + 1, field.length) };
    } catch {
      return { model: '', field: '' };
    }
  }

  //handle blur zip code
  async function handleBlurZipCode(e: any) {
    let zipCode = e.target.value;
    if (zipCode.length === 10 && company.address.street === '') {
      let address = blankAddress;
      address = await ZipService.getAddress(zipCode);
      setCompany({ ...company, address: address });
    }
  }

  //handle blur zip code
  async function handleBlurFantasyName(e: any) {
    let fantasy = e.target.value;
    if (fantasy.length >= 3 && company.business_name === '') {
      company.business_name = fantasy;
      setCompany(company);
    }
  }

  //handle avatar upload
  async function handleAvatarUpload(files: any) {
    fileImage = files[0];
  }

  async function handleCardTypeCompany(types: Array<string>) {
    setCompany({ ...company, type: types });
  }

  //Get values on form inputs and put into the model company
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;

    switch (model) {
      case 'address':
        address = { ...company.address, [field]: value };
        setCompany({ ...company, address: address });
        break;
      case 'company':
        if (field === 'phone_number') {
          value = maskPhone(value);
        }
        if (field === 'active') {
          setCompany({ ...company, [field]: e.target.checked });
          return;
        }
        if (field === 'admin_id') {
          let u = (await UserService.getById(value)) || blankUser;

          setUserAdmin(u);
          setCompany({ ...company, admin_id: value, admin: u });
          break;
        }
        setCompany({ ...company, [field]: value });
        break;
      // case 'userAdmin':
      //   if (field === 'document') {
      //     if (value.length > 14) {
      //       docMask = '99.999.999/9999-99';
      //       setUserAdmin({ ...userAdmin, [field]: value });
      //     } else {
      //       docMask = '999.999.999-99';
      //       setUserAdmin({ ...userAdmin, [field]: value });
      //     }
      //   } else {
      //     setUserAdmin({ ...userAdmin, [field]: value });
      //   }
      //   break;
      default:
        break;
    }
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={modal} maxWidth="lg">
          <DialogTitle textAlign="center">REGISTRO DE EMPRESAS</DialogTitle>
          <Separator />
          {loading && (
            <Box sx={{ width: '100%', alignContent: 'center' }}>
              <LinearProgress />
            </Box>
          )}
          <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Stack alignItems="center">
                    <AvatarUpload src={logo} width={100} height={100} radius={50} handleUpload={handleAvatarUpload} />
                    {/* <small>{company.email}</small> */}
                    <br />
                    <FormControlLabel control={<Checkbox name="company.active" checked={company.active} onChange={handleChange} value={company.active} />} label="Ativo" />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={9} sx={{ margin: 'auto', paddingTop: '0!important' }}>
                  <p className="paragraph">Ramo de Atuação</p>
                  <CardTypeCompany value={company.type} name="company.type" onChange={handleCardTypeCompany} />
                </Grid>
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Informações da Empresa
                </Typography>
                <Grid item xs={12} md={4}>
                  <Stack>
                    <BigTextField
                      key="company.fantasy_name"
                      label="Nome Fantasia"
                      name="company.fantasy_name"
                      value={company.fantasy_name}
                      onChange={handleChange}
                      onBlur={handleBlurFantasyName}
                      error={errors.fantasy_name}
                      helperText={errors.fantasy_name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack>
                    <InputMask mask="99.999.999/9999-99" name="company.cnpj" value={company.cnpj} onChange={handleChange} maskChar=" ">
                      <MediumTextField label="Cnpj" error={errors.cnpj} helperText={errors.cnpj} />
                    </InputMask>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack>
                    <BigTextField
                      key="company.business_name"
                      label="Razão Social"
                      name="company.business_name"
                      value={company.business_name}
                      onChange={handleChange}
                      error={errors.business_name}
                      helperText={errors.business_name}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Stack>
                    <MediumTextField
                      key="company.phone_number"
                      label="Telefone"
                      name="company.phone_number"
                      value={company.phone_number}
                      onChange={handleChange}
                      error={errors.phone_number}
                      helperText={errors.phone_number}
                    />
                  </Stack>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Stack>
                    <BigTextField
                      key="company.job_title"
                      label="Descricao"
                      name="company.description"
                      value={company.description}
                      onChange={handleChange}
                      error={errors.description}
                      helperText={errors.description}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack>
                    <BigTextField
                      key="company.contact"
                      label="Contato ( Site / Email )"
                      name="company.contact"
                      value={company.contact}
                      onChange={handleChange}
                      error={errors.contact}
                      helperText={errors.contact}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Stack>
                    <FormControl sx={{ m: 1 }}>
                      <InputLabel>Vinculada à empresa</InputLabel>
                      <Select key="company.company_id" label="Vinculada a empresa" name="company.company_id" value={company.company_id} onChange={handleChange} error={errors.company_id}>
                        {getAuthUser().role.name === 'Super Admin' && (
                          <MenuItem key="0" value="0">
                            Nenhum vinculo a empresas
                          </MenuItem>
                        )}
                        {possibleCompanies.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.fantasy_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>



                {company.id !== 0 && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                      Administrador
                    </Typography>
                    <Stack>
                      <FormControl sx={{ m: 1 }}>
                        <InputLabel>Usuário Administrador</InputLabel>
                        <Select key="company.admin_id" label="Usuário Administardor" name="company.admin_id" value={company.admin_id || 0} onChange={handleChange} error={errors.admin_id}>
                          <MenuItem key="0" value="0">
                            Nenhum vinculo a usuário administrador
                          </MenuItem>
                          {possibleAdmins.map((u) => (
                            <MenuItem key={u.id} value={u.id}>
                              {u.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                )}
                {/* {company.id === 0 && (
                  <>
                    <Grid item xs={12} md={2}>
                      <Stack>
                        <InputMask mask={docMask} name="userAdmin.document" value={userAdmin.document} onChange={handleChange} maskChar=" ">
                          <MediumTextField label="CPF/CNPJ" error={errors.document} helperText={errors.document} />
                        </InputMask>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack>
                        <BigTextField key="userAdmin.name" label="Nome" name="userAdmin.name" value={userAdmin.name} onChange={handleChange} error={errors.name} helperText={errors.name} />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Stack>
                        <BigTextField key="userAdmin.email" label="e-Mail" name="userAdmin.email" value={userAdmin.email} onChange={handleChange} error={errors.email} helperText={errors.email} />
                      </Stack>
                    </Grid>
                  </>
                )} */}

                {/* Endereço */}
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Endereço
                </Typography>
                <Grid item xs={12} md={1.5}>
                  <Stack>
                    <InputMask mask="99.999-999" name="address.zip_code" value={address.zip_code} onChange={handleChange} onBlur={handleBlurZipCode} maskChar=" ">
                      <SmallTextField label="CEP" error={errors.zip_code} helperText={errors.zip_code} sx={{ maxWidth: 200 }} />
                    </InputMask>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <Stack>
                    <BigTextField key="address.street" label="Rua" name="address.street" value={address.street} onChange={handleChange} error={errors.street} helperText={errors.street} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={1.2}>
                  <Stack>
                    <SmallTextField
                      key="address.number"
                      label="Número"
                      name="address.number"
                      value={address.number}
                      onChange={handleChange}
                      error={errors.number}
                      helperText={errors.number}
                      sx={{ maxWidth: 200 }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2.8}>
                  <Stack>
                    <BigTextField
                      key="address.complement"
                      label="Complemento"
                      name="address.complement"
                      value={address.complement}
                      onChange={handleChange}
                      error={errors.complement}
                      helperText={errors.complement}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack>
                    <MediumTextField
                      key="address.neighborhood"
                      label="Bairro"
                      name="address.neighborhood"
                      value={address.neighborhood}
                      onChange={handleChange}
                      error={errors.neighborhood}
                      helperText={errors.neighborhood}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Stack>
                    <MediumTextField key="address.city" label="Cidade" name="address.city" value={address.city} onChange={handleChange} error={errors.city} helperText={errors.city} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 150, m: 1 }} margin="dense">
                      <InputLabel>Estado</InputLabel>
                      <Select key="address.state" label="Estado" name="address.state" value={address.state} onChange={handleChange} error={errors.address} placeholder="Estado">
                        {allStatesNames.map((state) => (
                          <MenuItem key={state.abbreviation} value={state.abbreviation}>
                            {state.abbreviation}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button
              color="warning"
              disabled={company.id === 0}
              onClick={() => {
                handleDeleteConfirmation(company);
              }}>
              Apagar
            </Button>

            <Button
              onClick={() => {
                navigate('/companies');
              }}>
              Voltar
            </Button>
            <Button color="secondary" onClick={handleSubmit} variant="contained">
              Salvar
            </Button>
          </DialogActions>
          <ConfirmModal title="Deleção de Empresas" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
