import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import './MapLeaflet.css';
import 'leaflet/dist/leaflet.css';

import { Icon, latLng } from 'leaflet';
import { ICollectEvent, blankCollectEvent, collectEventStatus } from '../models/CollectEvent';

import flagStart from '../assets/map_flag-start.svg';
import flagEnd from '../assets/map_flag-end.svg';

import { Avatar, Chip } from '@mui/material';
import { OpenWith, NearMe } from '@mui/icons-material';
import { baseImg } from '../App';
import { ReactComponent as Balloon } from '../assets/BalaoConversa.svg';
import { ReactComponent as Phone } from '../assets/Telefone.svg';
import { Link, useNavigate } from 'react-router-dom';
import { IRouteEvent } from '../models/RouteEvent';
import { Grid, Typography } from '@mui/material';
import useCollectStore from '../contexts/CollectStore';

type Props = {
  collectEvents: ICollectEvent[];
  routeEvent: IRouteEvent;
};

export default function MapLeaflet({ collectEvents, routeEvent }: Props) {
  const navigate = useNavigate();
  const [collect_id, setSelectedCollect, unsetSelectedCollect] = useCollectStore((state) => [state.collect_id, state.setSelectedCollect, state.unsetSelectedCollect]);

  function latLngInitial() {
    if (routeEvent.address_start?.lat && routeEvent.address_start.lng) {
      return latLng(Number(routeEvent.address_start.lat), Number(routeEvent.address_start.lng));
    } else {
      return latLng(Number(routeEvent.schedule[0]?.neighborhood_lat), Number(routeEvent.schedule[0]?.neighborhood_lng));
    }
  }

  function latLngFinal() {
    if (routeEvent.address_end?.lat && routeEvent.address_end.lng) {
      return latLng(Number(routeEvent.address_end.lat), Number(routeEvent.address_end.lng));
    } else {
      return latLng(Number(routeEvent.schedule[routeEvent.schedule.length - 1]?.neighborhood_lat), Number(routeEvent.schedule[routeEvent.schedule.length - 1]?.neighborhood_lng));
    }
  }

  function centerMap() {
    return latLngInitial();
  }

  const flagStartIcon = new Icon({
    iconUrl: flagStart,
    iconSize: [30, 30]
  });
  const flagEndIcon = new Icon({
    iconUrl: flagEnd,
    iconSize: [30, 30]
  });

  function handleIcon(id: number, status: number) {
    let iconUrl = process.env.PUBLIC_URL + '/map/' + collectEventStatus[status].iconUrl || '';
    let size = 30;
    if (id === collect_id) {
      // iconUrl = process.env.PUBLIC_URL + '/map/MapMarkerDefault.svg';
      size = 50;
    }
    const markerIcon = new Icon({
      iconUrl: iconUrl,
      iconSize: [size, size]
    });
    return markerIcon;
  }

  function handleModal(collectEvent: ICollectEvent) {
    // setCollectEvent(collectEvent);
    // setIsOpen(true);
    // return;
    navigate('/collects_events/' + collectEvent.id);
  }
  function handleClick(id: number) {
    if (id === collect_id) {
      unsetSelectedCollect();
      return;
    }
    setSelectedCollect(id);
  }

  //!atualmente só suporta numero brasileiro
  return (
    <>
      <MapContainer center={centerMap()} zoom={13}>
        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={latLngInitial()} icon={flagStartIcon} interactive={false} />
        {collectEvents.map((c) => (
          <Marker
            key={c.id}
            position={[Number(c.collect?.address.lat), Number(c.collect?.address.lng)]}
            icon={handleIcon(c.id, c.status)}
            title={c.collect?.generator?.name || ''}
            eventHandlers={{
              click: (e) => {
                handleClick(c.id);
              }
            }}>
            <Popup closeButton={false} minWidth={420} closeOnEscapeKey={true}>
              <Grid container direction="row" justifyContent="center" alignItems="center" m={1}>
                <Avatar src={c.collect?.generator?.img && baseImg + c.collect?.generator?.img} sx={{ width: 50, height: 50 }} />

                <Typography variant="h6" sx={{ width: '55%', m: 1 }}>
                  {c.collect?.generator?.name}
                </Typography>
                <Chip label={collectEventStatus[c.status].name} style={{ background: collectEventStatus[c.status].color, color: '#FFFF' }} />
                <OpenWith onClick={() => handleModal(c)} style={{ cursor: 'pointer', marginLeft: 10 }} />
              </Grid>

              <Chip
                label={`${c.collect?.address.street}, ${c.collect?.address.number}, ${c.collect?.address.neighborhood}, ${c.collect?.address.city}, ${c.collect?.address.state}, ${c.collect?.address.zip_code}`}
              />
              <hr style={{ marginTop: 15, border: '1px solid #EEEEEE' }} />
              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10, alignItems: 'center' }}>
                <Link to={`https://wa.me/55${c.collect?.generator?.phone_number.replace('(', '').replace(')', '').replaceAll(' ', '').replace('-', '')}`}>
                  <Balloon style={{ width: '20px', height: '20px' }} />
                </Link>
                <hr style={{ width: '1px', height: '30px', display: 'inline-block', border: '1px solid #EEEEEE' }} />
                <Link to={`tel:+55${c.collect?.generator?.phone_number}`}>
                  <Phone style={{ width: '20px', height: '20px' }} />
                </Link>
                <hr style={{ width: '1px', height: '30px', display: 'inline-block', border: '1px solid #EEEEEE' }} />
                <Link
                  to={`https://maps.google.com/maps?q=${c.collect?.address?.street},${c.collect?.address?.number} ${c.collect?.address?.neighborhood} ${c.collect?.address?.city}/${c.collect?.address?.state}`}>
                  <NearMe style={{ width: '20px', height: '20px' }} />
                </Link>
              </div>
            </Popup>
          </Marker>
        ))}
        <Marker position={latLngFinal()} icon={flagEndIcon} interactive={false} />
      </MapContainer>
    </>
  );
}
