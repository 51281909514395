import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
};

const Line = styled.div`
  height: 1px;
  width: 100%;
  border: 1px var(--black-800) solid;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ExpandableSection({ isOpen }: Props) {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const handleToggleExpansion = () => setIsExpanded(!isExpanded);

  const expandStyle = {
    cursor: 'pointer',
    transform: `rotate(${isExpanded ? 180 : 0}deg)`
  };

  return (
    <Container>
      <Line onClick={handleToggleExpansion}></Line>
      <ExpandMoreIcon sx={expandStyle} onClick={handleToggleExpansion} />
    </Container>
  );
}
