import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dialog } from '@material-ui/core';
import { Button, Container, DialogActions, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { baseImg } from '../../App';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { AvatarUpload } from '../../components/AvatarUpload';
import { ConfirmModal } from '../../components/ConfirmModal';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { blankContainer, IContainer } from '../../models/Container';
import ContainerService from '../../services/ContainerService';
import { ImgUploadService } from '../../services/ImgUploadService';
import { getModelFromField } from '../../utils/MyLib';
import { validateRequired } from '../../utils/validations';

export default function ContainerForm() {
  const [container, setContainer] = useState<IContainer>(blankContainer);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  let fileImage: File = new File([], '');
  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    try {
      let container = blankContainer;
      if (Number(id)) {
        container = await ContainerService.getById(Number(id));
      }

      setContainer(container);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(container: IContainer) {
    let errors: any = {};
    if (!validateRequired(container.name)) errors.name = 'Obrigatorio';
    return errors;
  }

  async function save(container: IContainer) {
    if (container.id) {
      if (await ContainerService.update(container)) {
        console.log(container.id);
        saveImage(container, fileImage);
        toast.success('Acondicionamento atualizado com sucesso');
      }
    } else {
      let newContainer = await ContainerService.create(container);
      if (newContainer.id > 0) {
        container.id = newContainer.id;
        saveImage(container, fileImage);
        toast.success('Acondicionamento criada com sucesso');
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(container);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      console.log(container);
      save(container);
      navigate('/containers', { state: { reload: true } });
    }
  }

  function handleDeleteConfirmation(container: IContainer) {
    setConfirmMessage('Deseja excluir "' + container.name + '" ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await ContainerService.delete(container.id)) {
      toast.success('Acondicionamento apagada com sucesso');
      container.id = -1;
      navigate('/containers', { state: { reload: true } });
    }
  }

  function saveImage(container: IContainer, fileImage: File): void {
    console.log(container, fileImage.size);
    if (fileImage.size > 0) {
      if (fileImage.size <= 1000000) {
        ImgUploadService.upload('containers', container.id, fileImage);
      } else {
        toast.error('Imagem muito grande, tamanho máximo de 1mb');
      }
    }
  }

  //Get values on form inputs and put into the model Container
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      default:
        setContainer({ ...container, [field]: value });
        break;
    }
  }
  async function handleAvatarUpload(files: any) {
    fileImage = files[0];
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="sm" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>CADASTRO DE ACONDICIONAMENTOS</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/containers');
              }}
            />
          </Container>

          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Informações
                </Typography>
                <Grid item xs={12} md={2}>
                  <Stack>
                    <FormControl sx={{ maxWidth: '100%', m: 1, paddingBottom: '10px' }} margin="dense">
                      <AvatarUpload src={baseImg + container.img ? baseImg + container.img : ' '} imgDefault="x" width={100} height={100} radius={50} handleUpload={handleAvatarUpload} />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="container.id" label="Id" name="container.id" value={container.id} onChange={() => {}} error={errors.id} placeholder="Id" disabled />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="container.name" label="Nome" name="container.name" value={container.name} onChange={handleChange} error={errors.name} placeholder="Nome" />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="container.codInea" label="Cód.Inea" name="container.codInea" value={container.codInea} onChange={handleChange} error={errors.codInea} placeholder="Cód.Ineap" />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={container.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(container);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/containers');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>

          <ConfirmModal title="Deleção de Acondicionamento" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
