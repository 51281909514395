import React from 'react';
import PageStructure from '../components/PageStructure';
import { PageContainer, PageContent, PageHeader, PageFooter, PageTitle } from '../components/GlobalStyleds.jsx';

function ErrorPage(props: { message: string }) {
  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>Erro</PageTitle>
        </PageHeader>
        <PageContent>
          <p>{props.message}</p>
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}

export default ErrorPage;
