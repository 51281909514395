import './Autentication.css';
import logo from '../../assets/logo3.png';
import googleplay from '../../assets/googleplay.png';
import appstore from '../../assets/appstore.png';

import { useState } from 'react';
import api from '../../services/api';
import { login } from '../../services/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validateRequired } from '../../utils/validations';
import { LoginTextField } from '../../components/TextFields';

function Login() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormData>({ email: '', password: '' });
  const [errors, setErrors] = useState<any>({});

  interface IFormData {
    email: string;
    password: string;
  }

  async function handleChange(e: any) {
    let field = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [field]: value });
  }

  function validateErrors(formData: IFormData) {
    let errors: any = {};
    if (!validateEmail(formData.email)) errors.email = 'Email invalido';
    if (!validateRequired(formData.password)) errors.password = 'Preencha a senha!';
    if (formData.password.length < 6) errors.password = 'A senha deve conter no mínimo 6 caracteres!';

    return errors;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const errors = validateErrors(formData);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      try {
        const response = await toast.promise(api.post('login', formData), {
          pending: 'Aguarde...',
          success: 'Login realizado',
          error: 'Erro ao realizar login'
        });
        login(response.data);
        navigate('/home');
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit}>
        <div className="login-container">
          <img className="login-img" src={logo} alt="Siri Logo"></img>
          <h1>Login</h1>
          <LoginTextField key="email" label="Email" name="email" value={formData.email} onChange={handleChange} error={errors.email} helperText={errors.email} />
          <LoginTextField key="password" label="Senha" type="password" name="password" value={formData.password} onChange={handleChange} error={errors.password} helperText={errors.password} />
          <button type="submit" className="login-button">
            ENTRAR
          </button>

          <button type="button" className="signup-button" onClick={() => navigate('/signup')}>
            Cadastre-se
          </button>
          <a href="forget-password">Esqueci minha senha</a>
        </div>
        <div className="app">
          <p className="smallText">
            Obtenha o aplicativo <a href="./">Recicle Siri</a>
          </p>
          <div className="app-icons">
            <a href="https://play.google.com/store/apps/details?id=com.fluxo.siri">
              <img className="android" alt="Get it on Google Play" src={googleplay} />
            </a>
            <a href="https://apps.apple.com">
              <img className="apple" src={appstore} alt="Download on the App Store" />
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
