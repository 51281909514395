import { useEffect, useState } from 'react';
import styled, { styled as sx } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { Grid, Select, MenuItem, Stack, InputLabel, FormControl, FormControlLabel, Button, DialogActions, Container, Typography, TextField, FormLabel, FormGroup, Checkbox } from '@mui/material';

import { IPlan, blankPlan } from '../../models/Plan';
import { ICompany } from '../../models/Company';

import { toast } from 'react-toastify';

import PlanService from '../../services/PlanService';
import { validateRequired } from '../../utils/validations';

import { getModelFromField, stringIsNumber } from '../../utils/MyLib';
import { ConfirmModal } from '../../components/ConfirmModal';
import { Dialog } from '@material-ui/core';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import CompanyService from '../../services/CompanyService';

export default function PlanForm() {
  const [plan, setPlan] = useState<IPlan>(blankPlan);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir o plano?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allCompanies, setAllCompanies] = useState<ICompany[]>([]);
  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    try {
      let allCompanies = await CompanyService.getAllColetadoras();
      setAllCompanies(allCompanies);
      let plan = blankPlan;
      if (Number(id)) {
        plan = await PlanService.getById(Number(id));
      }
      setPlan(plan);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(plan: IPlan) {
    let errors: any = {};
    if (!validateRequired(plan.name)) errors.name = 'Obrigatorio';
    if (!plan.company_id) errors.company_id = 'Obrigatorio';
    if (!plan.price) errors.price = 'Obrigatorio';
    return errors;
  }

  async function save(plan: IPlan) {
    if (plan.id) {
      if (await PlanService.update(plan)) {
        toast.success('Plano salvo com sucesso');
      }
    } else {
      let newPlan = await PlanService.create(plan);
      if (newPlan.id > 0) {
        plan.id = newPlan.id;
        toast.success('Plano criado com sucesso');
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(plan);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      save(plan);
      navigate('/plans');
    }
  }

  function handleDeleteConfirmation(plan: IPlan) {
    setConfirmMessage('Deseja excluir "' + plan.name + '" ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await PlanService.delete(plan.id)) {
      toast.success('Estado Plano apagaoo com sucesso');
      plan.id = -1;
      navigate('/plans');
    }
  }

  //Get values on form inputs and put into the model Plan
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      case 'price':
        if (stringIsNumber(value, 2)) setPlan({ ...plan, [field]: value });
        break;
      default:
        setPlan({ ...plan, [field]: value });
        break;
    }
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="sm" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>CADASTRO DE PLANOS</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/plans');
              }}
            />
          </Container>

          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ width: '100%', m: 1 }} margin="dense">
                      <TextField key="plan.id" label="Id" name="plan.id" value={plan.id} onChange={() => {}} error={errors.id} placeholder="Id" disabled />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
                      <InputLabel>Empresa</InputLabel>
                      <Select key="plan.company_id" label="Empresa" name="plan.company_id" value={plan.company_id} onChange={handleChange} error={errors.company_id} placeholder="Empresa">
                        {allCompanies.map((r) => (
                          <MenuItem key={'r' + r.id} value={r.id}>
                            {r.fantasy_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ width: '100%', m: 1 }} margin="dense">
                      <TextField key="plan.name" label="Nome" name="plan.name" value={plan.name} onChange={handleChange} placeholder="Nome" error={errors.name} />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '100%', m: 1 }} margin="dense">
                      <TextField key="plan.description" label="Descrição" name="plan.description" value={plan.description} onChange={handleChange} placeholder="Descrição" error={errors.description} />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '100%', m: 1 }} margin="dense">
                      <TextField key="plan.price" label="Preço" name="plan.price" value={plan.price} onChange={handleChange} placeholder="Preço " error={errors.price} />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>

              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={plan.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(plan);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/plans');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>

          <ConfirmModal title="Deleção do Impacto do  Plano" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
