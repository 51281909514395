import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import SiriLogoSvg from '../../assets/LogoSiri.png';
import VilarejoLogo from '../../assets/logo-vilarejo-branca.png';
import TreeImage from '../../assets/icons/ArvoresSalvas.png';
import Co2Image from '../../assets/icons/Co2avoid.png';
import OceanPlastic from '../../assets/icons/PlasticoNoOceano.png';
import Reciclagem from '../../assets/icons/Reciclagem.png';
import Carro from '../../assets/icons/Car.png';
import Aviao from '../../assets/icons/Plane.png';
import Chuveiro from '../../assets/icons/shower.png';
import ArCondicionado from '../../assets/icons/airConditionator.png';
import QrCodeImage from '../../assets/qr-code-siri.png';
import ImpactPie from '../../components/ImpactPie';
import ImpactBar from '../../components/ImpactBar';
import { useParams } from 'react-router-dom';
import CollectTreatmentService from '../../services/CollectTreatmentService';
import CollectResiduesService from '../../services/CollectResiduesService';
import UserService from '../../services/UserService';
import { isAuthenticated } from '../../services/auth';
import { baseImg } from '../../App';


const DisplayContainer = styled.div`
  width: 100%;
  margin: auto;
  background: url('/assets/dashboard-background.jpg') no-repeat center center fixed;
  background-size: cover;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ccc;
`;

const HeaderText = styled.p`
  color: #ffffff;
  font-size: 2rem;
  text-align: left;
  font-weight: bold;
  margin-left: 10px;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1921px) {
    font-size: 3rem;
  }
`;

const HeaderImg = styled.img`
  width: 80px;
  height: 80px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1921px) {
    width: 120px;
    height: 120px;
  }
`;

const ImpactCardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 150px;
  margin: 5px;
`;

const ImpactCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const ImpactImage = styled.img`
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1921px) {
    width: 100px;
    height: 100px;
  }
`;

const ImpactCardTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.2rem;
  }
`;

const ImpactValue = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.6rem;
  }
`;

const CardsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px 0;
`;

const GraphsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: stretch;
  }

  @media (min-width: 1921px) {
    height: 60%;
  }
`;

const BarGraphContainer = styled.div`
  flex: 0.7;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 10px;
    margin: 5px;
  }

  @media (min-width: 1921px) {
    padding: 25px;
    margin: 15px;
  }
`;

const PieGraphContainer = styled.div`
  flex: 0.3;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 10px;
    margin: 5px;
  }

  @media (min-width: 1921px) {
    padding: 25px;
    margin: 15px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterImpactCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  flex: 0.6;

  @media (min-width: 768px) {
    justify-content: space-around;
  }
`;

const FooterInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0.4;
  flex-wrap: wrap;
  min-width: 250px;
  margin: 10px;
`;

const FooterSiriContainer = styled.div`
  display: flex;
  
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  margin: 10px;
`;
const FooterImpactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const FooterImpactImage = styled.img`
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 1921px) {
    width: 100px;
    height: 100px;
  }
`;

const FooterImpactTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #666;
  margin-top: 5px;

  @media (min-width: 1921px) {
    font-size: 1rem;
  }
`;

const FooterImpactValue = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-top: 5px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.4rem;
  }
`;

const FooterLogo = styled.img`
  width: 80px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    width: 120px;
  }

  @media (min-width: 1921px) {
    width: 150px;
  }
`;

const FooterText = styled.p`
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1921px) {
    font-size: 1.5rem;
  }
`;

const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const ImpactTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #666;
  text-align: center;
  margin-top: 8px;

  @media (min-width: 1921px) {
    font-size: 1.1rem;
  }
`;

const impactCards = [
  { id: 0, impact: '', subText: 'Total de Resíduos Destinados', imageSrc: Reciclagem },
  { id: 1, impact: '', subText: 'Emissões Evitadas', imageSrc: Co2Image },
  { id: 2, impact: '', subText: 'Árvores Salvas', imageSrc: TreeImage },
  { id: 3, impact: '', subText: 'Plásticos Evitados nos Oceanos', imageSrc: OceanPlastic },
];

interface PieDashBoardInfo {
  title: string;
  value: number;
}



const ImpactCard = ({ impact, text, imageSrc }: { impact: string; text: string; imageSrc: string }) => (
  <ImpactCardContainer>
    <ImpactImage src={imageSrc} alt={text} />
    <ImpactCardContent>
      <ImpactCardTitle>{text}</ImpactCardTitle>
      <ImpactValue>{impact}</ImpactValue>
    </ImpactCardContent>
  </ImpactCardContainer>
);

export default function ImpactDisplayPage() {
  const { id } = useParams<{ id: string }>();
  const user_id = Number(id);

  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [pieLabels, setPieLabels] = useState<string[]>([]);
  const [pieValues, setPieValues] = useState<number[]>([]);
  const [impactCardsInfo, setImpactCardsInfo] = useState(impactCards);
  const [totalCollectedByMonth, setTotalCollectedByMonth] = useState<number[]>([]);
  const [totalPlasticByMonth, setTotalPlasticByMonth] = useState<number[]>([]);
  const [totalGlassByMonth, setTotalGlassByMonth] = useState<number[]>([]);
  const [totalMetalByMonth, setTotalMetalByMonth] = useState<number[]>([]);
  const [totalPaperByMonth, setTotalPaperByMonth] = useState<number[]>([]);
  const [totalOrganicByMonth, setTotalOrganicByMonth] = useState<number[]>([]);
  const [planeKmEquivalent, setPlaneKmEquivalent] = useState<number>(0);
  const [showerHoursEquivalent, setShowerHoursEquivalent] = useState<number>(0);
  const [airConditionatorEquivalent, setAirConditionatorEquivalent] = useState<number>(0);
  const [carKmEquivalent, setCarKmEquivalent] = useState<number>(0);
  const [mensagemTela, setMensagemTela] = useState("");
  const [userName, setUserName] = useState<string | null>(null);
  const [userImg, setUserImg] = useState<string | undefined>(undefined);

  const formatNumber = (num: number) => {
    return Math.round(num).toLocaleString('pt-BR');
  };
   async function checkLoginStatus() {
    if (isAuthenticated()) {
      const user = await UserService.getById(user_id);
      setUserName(user.name);
      setUserImg(user.img ? baseImg + user.img : '')
      setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
      return true;
    } else {
      const user = await UserService.getBasicInfo(user_id);
      setUserName(user.name);
      setUserImg(user.img ? baseImg + user.img : '')
      setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
      return false;
    }
  }
  async function getPositiveImpact() {
    const collectTreatmentsInfo = await CollectTreatmentService.getById(user_id);
    const collectResidues = await CollectResiduesService.getById(user_id);

    const totalAvoidCO2 = collectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.avoid_co2, 0);
    const totalPlasticOceanSaved = collectResidues[0]?.value * 0.3;
    setPlaneKmEquivalent(totalAvoidCO2/(0.115))
    setCarKmEquivalent(totalAvoidCO2/(0.2))
    setAirConditionatorEquivalent(totalAvoidCO2/(0.56))
    setShowerHoursEquivalent(totalAvoidCO2)

    let mainCardsInfo = [...impactCards];
    mainCardsInfo[2].impact = formatNumber(Math.round(totalAvoidCO2 / 168));
    mainCardsInfo[1].impact = formatNumber(totalAvoidCO2) + " kg CO₂-e";
    mainCardsInfo[3].impact = formatNumber(Math.round(totalPlasticOceanSaved)) + " kg";

    const validTitles = ['Vidro', 'Papel', 'Plásticos', 'Metais'];
    const validCollectTreatments = collectResidues.reduce<PieDashBoardInfo[]>((acc, obj) => {
      const titleTrimmed = obj.title ? obj.title.trim().split(' ')[0] : 'Outros';
      if (validTitles.includes(titleTrimmed)) {
        const exist = acc.find((item) => item.title === titleTrimmed);
        if (exist) {
          exist.value += obj.value;
        } else {
          acc.push({ title: titleTrimmed, value: obj.value });
        }
      } else {
        const otherItem = acc.find((item) => item.title === 'Outros');
        if (otherItem) {
          otherItem.value += obj.value;
        } else {
          acc.push({ title: 'Outros', value: obj.value });
        }
      }
      return acc;
    }, []);

    const totalWeight = validCollectTreatments.reduce((acc, item) => acc + item.value, 0);

    mainCardsInfo[0].impact = formatNumber(Math.round(totalWeight)) + " kg";

    setTotalWeight(parseFloat(totalWeight.toFixed(2)));
    setPieLabels(validCollectTreatments.map((item) => item.title));
    setPieValues(validCollectTreatments.map((item) => item.value));
    setImpactCardsInfo(mainCardsInfo);
  }

  async function getPositiveImpactByMonth() {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const currentYear = new Date().getFullYear();

    const collectedByMonth = Array(12).fill(0);
    const plasticByMonth = Array(12).fill(0);
    const glassByMonth = Array(12).fill(0);
    const metalByMonth = Array(12).fill(0);
    const paperByMonth = Array(12).fill(0);
    const organicByMonth = Array(12).fill(0);

    const fetchDataForMonth = async (month: string) => {
      const data = await CollectResiduesService.getByMonth(user_id, `${currentYear}-${month}-01`, `${currentYear}-${month}-31`);
      return Array.isArray(data) ? data : [];
    };

    const collectResiduesInfoMonthly = await Promise.all(months.map(fetchDataForMonth));

    collectResiduesInfoMonthly.forEach((monthlyData, index) => {
      collectedByMonth[index] = monthlyData.reduce((acc, item) => acc + (item.value || 0), 0);

      monthlyData.forEach((residue) => {
        switch (residue.title.trim().split(' ')[0]) {
          case 'Plásticos':
            plasticByMonth[index] += residue.value || 0;
            break;
          case 'Vidro':
            glassByMonth[index] += residue.value || 0;
            break;
          case 'Metais':
            metalByMonth[index] += residue.value || 0;
            break;
          case 'Papel':
            paperByMonth[index] += residue.value || 0;
            break;
          case 'Orgânicos':
            organicByMonth[index] += residue.value || 0;
            break;
        }
      });
    });

    setTotalCollectedByMonth(collectedByMonth);
    setTotalPlasticByMonth(plasticByMonth);
    setTotalGlassByMonth(glassByMonth);
    setTotalMetalByMonth(metalByMonth);
    setTotalPaperByMonth(paperByMonth);
    setTotalOrganicByMonth(organicByMonth);
  }

  useEffect(() => {
    checkLoginStatus();
    getPositiveImpact();
    getPositiveImpactByMonth();
  }, []);

  return (
    <DisplayContainer>
      <HeaderContainer>
        <HeaderImg src={userImg} />
        <HeaderText>{mensagemTela}</HeaderText>
        <HeaderImg src={userImg} />
      </HeaderContainer>

      <CardsContainer container spacing={2}>
        {impactCardsInfo.map((card) => (
          <Grid item xs={6} sm={3} key={card.id}>
            <ImpactCard impact={card.impact} text={card.subText} imageSrc={card.imageSrc} />
          </Grid>
        ))}
      </CardsContainer>

      <GraphsContainer>
        <BarGraphContainer>
          <ImpactBar
            data_1={totalCollectedByMonth}
            data_2={totalPlasticByMonth}
            data_3={totalGlassByMonth}
            data_4={totalMetalByMonth}
            data_5={totalPaperByMonth}
            data_6={totalOrganicByMonth}
          />
        </BarGraphContainer>
        <PieGraphContainer>
          <h3>Total por resíduo</h3>
          <ImpactPie labels={pieLabels} values={pieValues} />
        </PieGraphContainer>
      </GraphsContainer>
      <HeaderText>As emissões evitadas equivalem a:</HeaderText>
      <FooterContainer>
        <FooterImpactCardsContainer>
          <FooterImpactCard>
            <FooterImpactImage src={Carro} alt="Emissões Evitadas" />
            <FooterImpactTitle>Quilometros Rodados em Carro de Médio Porte</FooterImpactTitle>
            <FooterImpactValue>{formatNumber(carKmEquivalent)} km</FooterImpactValue>
          </FooterImpactCard>
          <FooterImpactCard>
            <FooterImpactImage src={Aviao} alt="Árvores Salvas" />
            <FooterImpactTitle>Quilometros de Voo Comercial</FooterImpactTitle>
            <FooterImpactValue>{formatNumber(Math.round(planeKmEquivalent))} km</FooterImpactValue>
          </FooterImpactCard>
          <FooterImpactCard>
            <FooterImpactImage src={Chuveiro} alt="Plásticos no Oceano" />
            <FooterImpactTitle>Horas de Chuveiro Elétrico Ligado</FooterImpactTitle>
            <FooterImpactValue>{formatNumber(showerHoursEquivalent)} horas</FooterImpactValue>
          </FooterImpactCard>
          <FooterImpactCard>
            <FooterImpactImage src={ArCondicionado} alt="Total Reciclado" />
            <FooterImpactTitle>Horas de Ar Condicionado Ligado</FooterImpactTitle>
            <FooterImpactValue>{formatNumber(airConditionatorEquivalent)} Horas</FooterImpactValue>
          </FooterImpactCard>
        </FooterImpactCardsContainer>

        <FooterInfoContainer>
          <FooterSiriContainer>
          <FooterLogo src={SiriLogoSvg} alt="Siri Logo" />
          <FooterText>Vem reciclar também!!!</FooterText>
          </FooterSiriContainer>
          <FooterSiriContainer>
          <FooterText>Escaneie o código para saber mais</FooterText>
          <QrCodeContainer>
            <img src={QrCodeImage} width={100} height={100} alt="QR Code" />
          </QrCodeContainer>
          
          </FooterSiriContainer>
        </FooterInfoContainer>

      </FooterContainer>
    </DisplayContainer>
  );
}