import { useEffect, useState } from 'react';
import styled, { styled as sx } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { Grid, Select, MenuItem, Stack, InputLabel, FormControl, FormControlLabel, Button, DialogActions, Container, Typography, TextField, FormLabel, FormGroup, Checkbox } from '@mui/material';

import { ITreatment, blankTreatment } from '../../models/Treatment';
import { ICompany } from '../../models/Company';

import { toast } from 'react-toastify';

import TreatmentService from '../../services/TreatmentService';
import { validateRequired } from '../../utils/validations';

import { getModelFromField } from '../../utils/MyLib';
import { ConfirmModal } from '../../components/ConfirmModal';
import { Dialog } from '@material-ui/core';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';

export default function TreatmentForm() {
  const [treatment, setTreatment] = useState<ITreatment>(blankTreatment);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir a unidade?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    try {
      let treatment = blankTreatment;
      if (Number(id)) {
        treatment = await TreatmentService.getById(Number(id));
      }
      setTreatment(treatment);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(treatment: ITreatment) {
    let errors: any = {};
    if (!validateRequired(treatment.name)) errors.name = 'Obrigatorio';
    return errors;
  }

  async function save(treatment: ITreatment) {
    if (treatment.id) {
      if (await TreatmentService.update(treatment)) {
        toast.success('Resíduo salva com sucesso');
      }
    } else {
      let newContainer = await TreatmentService.create(treatment);
      if (newContainer.id > 0) {
        treatment.id = newContainer.id;
        toast.success('Resíduo criada com sucesso');
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(treatment);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      save(treatment);
      navigate('/treatments', { state: { reload: true } });
    }
  }

  function handleDeleteConfirmation(treatment: ITreatment) {
    setConfirmMessage('Deseja excluir "' + treatment.name + '" ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await TreatmentService.delete(treatment.id)) {
      toast.success('Resíduo apagada com sucesso');
      treatment.id = -1;
      navigate('/treatments', { state: { reload: true } });
    }
  }

  //Get values on form inputs and put into the model Container
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      default:
        setTreatment({ ...treatment, [field]: value });
        break;
    }
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="sm" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>CADASTRO DE TEC/TRATAMENTO</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/treatments');
              }}
            />
          </Container>

          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Informações
                </Typography>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="treatment.id" label="Id" name="treatment.id" value={treatment.id} onChange={() => {}} error={errors.id} placeholder="Id" disabled />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="treatment.name" label="Nome" name="treatment.name" value={treatment.name} onChange={handleChange} error={errors.name} placeholder="Nome" />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="treatment.codInea" label="Cód.Inea" name="treatment.codInea" value={treatment.codInea} onChange={handleChange} error={errors.codInea} placeholder="Cód.Ineap" />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={treatment.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(treatment);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/treatments');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>

          <ConfirmModal title="Deleção de Resíduo" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
