import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  transition: var(--transition);
  &:hover {
    transform: scale(1.19);
  }
`;

const DateBox = styled.div<{ selectedDate: Dayjs; date: Dayjs }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  font-weight: 700;
  background: ${(props) => (props.selectedDate.format('DD/MM/YYYY') === props.date.format('DD/MM/YYYY') ? 'var(--azul-bg, #004757)' : 'var(--azul-bg, #f0f9fb)')};
  color: ${(props) => (props.selectedDate.format('DD/MM/YYYY') === props.date.format('DD/MM/YYYY') ? 'white' : 'black')};

  cursor: pointer;
`;

type Props = {
  dateChoiced: Dayjs;
  onChange: (date: Dayjs) => void;
  datesWithRoutes: string[];
};

export default function DatePickerCarousel({ dateChoiced, onChange, datesWithRoutes }: Props) {
  const weekday = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const [date, setDate] = useState(dayjs(new Date()));
  const [centerDate, setCenterDate] = useState(dayjs(new Date()));
  const navigate = useNavigate();
  const days = 5;

  useEffect(() => {
    setDate(dateChoiced);
  }, []);

  function displayDays() {
    let d = Math.abs(date.diff(centerDate, 'days'));
    if (d > 4) setCenterDate(date);
    let dates: Dayjs[] = [];
    for (let i = -days; i <= days; i++) {
      dates.push(centerDate.add(i, 'days'));
    }
    return dates;
  }

  function nextDays() {
    handleChoice(date.add(1, 'days'));
    setDate(date.add(1, 'days'));
  }

  function previusDays() {
    handleChoice(date.subtract(1, 'days'));
    setDate(date.subtract(1, 'days'));
  }
  const handleChoice = (date: Dayjs) => {
    dateChoiced = date;
    onChange(date);
    setDate(date);
  };
  function hasRoute(d: Dayjs) {
    let data = d.format('YYYY-MM-DD');
    let style = 'none';
    if (datesWithRoutes.includes(data)) {
      style = 'underline  #004757 2px';
      if (date.format('YYYY-MM-DD') === data) {
        style = 'underline  #FFFFFF 2px';
      }
    }
    return style;
  }

  return (
    <>
      <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', width: '70%', alignItems: 'center', margin: 'auto' }}>
        <p style={{ fontWeight: 'bold' }}>Data: {date.format('DD/MM/YYYY')}</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '70%', alignItems: 'center', margin: 'auto' }}>
        <ArrowBackIosIcon
          onClick={() => {
            previusDays();
          }}
          sx={{ cursor: 'pointer' }}
        />
        {displayDays().map((d) => (
          <DateContainer key={d.toString()}>
            <DateBox selectedDate={date} date={d} onClick={() => handleChoice(d)} onDoubleClick={() => navigate('/routes_events?date=' + date.format('YYYY-MM-DD'))}>
              <small style={{ textDecoration: hasRoute(d) }}>{d.get('date')}</small>
            </DateBox>
            <p style={{}}>{weekday[d.day()]}</p>
          </DateContainer>
        ))}
        <ArrowForwardIosIcon
          onClick={() => {
            nextDays();
          }}
          sx={{ cursor: 'pointer' }}
        />
      </div>
    </>
  );
}
