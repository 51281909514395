import { useState } from 'react';
import { ToggleMenuContext, SubMenuContext } from './contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './global.css';
import {} from 'react-router-dom';
import RoutesComponent from './Routes';
import { lightTheme } from './themes/AppTheme';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
export const baseImg = process.env.REACT_APP_BASEIMG;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function App() {
  const [navDisplay, setNavDisplay] = useState(true);
  const [subMenuDisplay, setSubMenuDisplay] = useState('none');

  const navValue = [navDisplay, setNavDisplay];
  const subMenuValue = [subMenuDisplay, setSubMenuDisplay];

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline enableColorScheme />
      <ToggleMenuContext.Provider value={navValue}>
        <SubMenuContext.Provider value={subMenuValue}>
          <RoutesComponent />
          <ToastContainer />
        </SubMenuContext.Provider>
      </ToggleMenuContext.Provider>
    </ThemeProvider>
  );
}
//t

export default App;
