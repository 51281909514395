import api from './api';
import { IResidue, blankResidue, residueProps } from '../models/Residue';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';
import Papa from 'papaparse';

export class ResidueService {
  static async getAll(): Promise<IResidue[]> {
    try {
      const response = await api.get('/residues');
      let allResidues = response.data as IResidue[];
      return allResidues;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar tipos de resíduos');
      return [];
    }
  }
  static async getById(id: number): Promise<IResidue> {
    try {
      const response = await api.get(`/residues/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, residueProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar tipo de resíduo!');
      return blankResidue;
    }
  }

  static async getResidueName(id: number) {
    let c = await ResidueService.getById(id);
    return c.description;
  }

  static async create({ id, ...residue }: IResidue): Promise<IResidue> {
    try {
      this.standarlizeResidues(residue);
      const response = await api.post('/residues', residue);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir tipo de resíduo');
        return blankResidue;
      }
      toast.success('Tipo de resíduo incluído com sucesso!');
      return response.data as IResidue;
    } catch (error: any) {
      toast.error('Erro ao incluir tipo de resíduo!');
      return blankResidue;
    }
  }
  static async update({ ...residue }: IResidue): Promise<boolean> {
    try {
      this.standarlizeResidues(residue);
      let response = await api.put(`/residues/${residue.id}`, residue);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar tipo de resíduo!');
        return false;
      }
      toast.success('Tipo de resíduo atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar tipo de resíduo!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/residues/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir tipo de resíduo!');
        return false;
      }
      toast.success('Tipo de resíduo excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir tipo de resíduo!');
      return false;
    }
  }

  static standarlizeResidues(v: IResidue | any): IResidue {
    if (v.company_id === '0') {
      v.company_id = undefined;
    }
    if (!v.company_id) {
      v.company_id = undefined;
    }
    return v;
  }
  static standarlizeCodes(code: string): string {
    let codeArray = code.split(' ');
    let codeString = '';
    codeArray.map((v, i) => {
      if (i === 0) {
        codeString = v;
      } else {
        codeString = codeString + '.' + v;
      }
      return v;
    });
    return codeString;
  }
  static async importFromFile() {
    const fileName = '/docs/ResiduesTypes.csv'; // Replace 'path/to/csv/file.csv' with the
    let text = '';
    let i = 1;
    await fetch(fileName)
      .then((response) => response.text())
      .then((responseText) => {
        text = responseText;
      });
    let data = {};
    Papa.parse(text, {
      header: true,
      complete: (results) => {
        data = results.data;
      }
    });
    let allResidues = data as IResidue[];
    allResidues.map((v) => {
      v.id = i++;
      v.description = v.description.replace('–', '');
      v.code = this.standarlizeCodes(v.code);
      v.level = v.code.split('.').length;
      // v.class = v.class ? v.class : 'Não Perigoso';
      // v.unit = v.unit ? v.unit : ' ';
      return v;
    });
    allResidues.forEach(async (v) => {
      await this.create(v);
    });
  }
}

export default ResidueService;
