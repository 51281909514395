export function capitalizeEveryWord(str: string): string {
  let arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    // if (arr[i].length <= 2) {
    //   arr[i] = arr[i].toLowerCase();
    // }
    if (
      arr[i] === 'Do' ||
      arr[i] === 'Da' ||
      arr[i] === 'De' ||
      arr[i] === 'Dos' ||
      arr[i] === 'Das' ||
      arr[i] === 'E' ||
      arr[i] === 'Em' ||
      arr[i] === 'No' ||
      arr[i] === 'Na' ||
      arr[i] === 'Nos' ||
      arr[i] === 'Nas'
    ) {
      arr[i] = arr[i].toLowerCase();
    }
  }
  return arr.join(' ');
}

export function stringIsNumber(value: string, decimal: number): boolean {
  const re = /^[0-9\b]+$/;
  if (value === '' || re.test(value) || Number(value)) {
    let afterPoint = value.split('.')[1];
    if (afterPoint && afterPoint.length > decimal) {
      return false;
    } else {
      return true;
    }
  }
  return !isNaN(Number(value));
}

export function standarlizeField(field: any): any {
  field = Number(field);
  field = field || undefined;
}

export function formatDate(dateValue: any): string {
  // Create a new Date object from the value
  if (!dateValue) return '';
  if (dateValue.length > 10) dateValue = dateValue.slice(0, 10);
  let date = new Date(Date.parse(dateValue));
  date.setDate(date.getDate() + 1);

  // Convert the date to the desired format
  let formattedDate = date.toLocaleDateString();

  // Return the formatted date
  return formattedDate;
}
export function maskPhone(value: string): string {
  if (!value) return '';
  if (value.length > 15) return value.slice(0, 15);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
}

export function maskCPF(value: string): string {
  if (value.length > 14) return value.slice(0, 14);
  value = value.replace(/[^\d]/g, '');
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}
export function maskCNPJ(value: string): string {
  if (value.length > 18) return value.slice(0, 18);
  value = value.replace(/[^\d]/g, '');
  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
}

export function maskCEP(value: string): string {
  if (value.length > 9) return value.slice(0, 9);
  value = value.replace(/[^\d]/g, '');
  return value.replace(/(\d{5})(\d{3})/, '$1-$2');
}
export function maskResidueCode(value: string): string {
  if (value.length > 10) return value.slice(0, 10);
  value = value.replace(/[^\d]/g, '');
  return value.replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3');
}
//function to map object and put '' in empty values
export function mapNullToString(obj: any) {
  const mapped = Object.keys(obj).map((key) => {
    return obj[key] === null ? { [key]: '' } : { [key]: obj[key] };
  });
  return Object.assign({}, ...mapped);
}

export function clearObj(obj: any, fields: string[]) {
  for (let prop in obj) {
    if (fields.includes(prop) === false) {
      delete (obj as { [key: string]: any })[prop];
    }
  }
}
export function capitalizeObj(obj: any) {
  for (let prop in obj) {
    if (typeof obj[prop] === 'string') {
      if (prop === 'email') {
        obj[prop] = obj[prop].toLowerCase();
      } else if (prop === 'state') {
        obj[prop] = obj[prop].toUpperCase() as string;
      } else if (prop === 'password') {
      } else {
        obj[prop] = capitalizeEveryWord(obj[prop]);
      }
    } else if (typeof obj[prop] === 'object') {
      obj[prop] = capitalizeObj(obj[prop]);
    }
  }
  return obj;
}

export const weekday = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

export function getModelFromField(field: string) {
  try {
    let index = field.indexOf('.');
    return { model: field.substring(0, index), field: field.substring(index + 1, field.length) };
  } catch {
    return { model: '', field: '' };
  }
}
export const weeksdays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
