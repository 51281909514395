import api from './api';
import { IPack, blankPack, packProps } from '../models/Pack';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { getAuthUser } from './auth';
import { toast } from 'react-toastify';

export class PackService {
  static async getAll(): Promise<IPack[]> {
    try {
      const response = await api.get('/packs');
      let allPacks = response.data as IPack[];
      // let role = getAuthUser().role;
      return allPacks;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar resíduos');
      return [];
    }
  }
  static async getById(id: number): Promise<IPack> {
    try {
      const response = await api.get(`/packs/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, packProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar resíduo!');
      return blankPack;
    }
  }

  static async create({ id, ...pack }: IPack): Promise<IPack> {
    try {
      this.standarlizePacks(pack);
      const response = await api.post('/packs', pack);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir resíduo');
        return blankPack;
      }
      toast.success('Resíduo incluído com sucesso!');
      return response.data as IPack;
    } catch (error: any) {
      toast.error('Erro ao incluir resíduo!');
      return blankPack;
    }
  }
  static async update({ ...pack }: IPack): Promise<boolean> {
    try {
      this.standarlizePacks(pack);
      let response = await api.put(`/packs/${pack.id}`, pack);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar resíduo!');
        return false;
      }
      toast.success('Resíduo atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar resíduo!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/packs/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir resíduo!');
        return false;
      }
      toast.success('Resíduo excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir resíduo!');
      return false;
    }
  }

  static standarlizePacks(v: IPack | any): IPack {
    if (v.company_id === '0') {
      v.company_id = undefined;
    }
    if (!v.company_id) {
      v.company_id = undefined;
    }
    return v;
  }
}

export default PackService;
