export interface IPlan {
  id: number;
  name: string;
  description: string;
  img: string;
  price: number;
  company_id: number;
}

export const blankPlan: IPlan = {
  id: 0,
  name: '',
  description: '',
  img: '',
  price: 0,
  company_id: 0
};

export const planProps = Object.keys(blankPlan);
