import api from './api';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { getAuthUser } from './auth';
import { toast } from 'react-toastify';
import { IRouteEvent, blankRouteEvent, routeEventProps } from '../models/RouteEvent';
import { ICollectEvent } from '../models/CollectEvent';

export default class RouteEventsService {
  static async getAll(filter?: string): Promise<IRouteEvent[]> {
    try {
      const response = await api.get('/routes-events' + (filter ? `?${filter}` : ''));
      let allRouteEvents = response.data as IRouteEvent[];
      return allRouteEvents;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar evento de rotas');
      return [];
    }
  }
  static async getById(id: number): Promise<IRouteEvent> {
    try {
      const response = await api.get(`/routes-events/${id}`);
      let r = response.data;
      // r = mapNullToString(r);
      clearObj(r, routeEventProps);
      return r;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar evento de rota!');
      return blankRouteEvent;
    }
  }
  static async getByRoute(route_id: number): Promise<IRouteEvent[]> {
    try {
      const response = await api.get(`/routes-events?route_id=${route_id}`);
      let allRoutesEvents = response.data as IRouteEvent[];
      return allRoutesEvents;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar coletas');
      return [];
    }
  }

  static async create({ id, ...routeEvent }: IRouteEvent): Promise<IRouteEvent> {
    try {
      this.standarlizeRoutes(routeEvent);
      const response = await api.post('/routes-events', routeEvent);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir evento de rota');
        return blankRouteEvent;
      }
      toast.success('Evento de rota incluído com sucesso!');
      return response.data as IRouteEvent;
    } catch (error: any) {
      toast.error('Erro ao incluir evento de rota!');
      return blankRouteEvent;
    }
  }
  static async update({ ...routeEvent }: IRouteEvent): Promise<boolean> {
    try {
      this.standarlizeRoutes(routeEvent);
      let response = await api.put(`/routes-events/${routeEvent.id}`, routeEvent);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar evento de rota!');
        return false;
      }
      toast.success('Evento de rota atualizada com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar evento de rota!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/routes-events/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir evento de rota!');
        return false;
      }
      toast.success('Evento de rota excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir evento de rota!');
      return false;
    }
  }

  static standarlizeRoutes(r: IRouteEvent | any): IRouteEvent {
    r.company_id = Number(r.company_id);
    r.company_id = r.company_id || undefined;
    r.vehicle_id = Number(r.vehicle_id);
    r.vehicle_id = r.vehicle_id || undefined;
    r.collector_aux_id = Number(r.collector_aux_id);
    r.collector_aux_id = r.collector_aux_id || undefined;
    r.privacy = Number(r.privacy);
    r.privacy = r.privacy || 0;
    return r;
  }
}
