import React from 'react';
import styled from 'styled-components';
import { baseImg } from '../App';
import { Avatar } from '@mui/material';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 15rem;
  width: 100%;
  background: var(--bgColor-1);
  border-radius: var(--borderRadius-m);
  box-shadow: var(--shadow-3);

  @media (max-height: 800px) {
    height: 14.5rem;
  }

  &:hover {
    transform: translateY(-0.5rem);
    transition: transform 0.3s;
    background: var(--primary-25);
  }

  .MainTitleWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: calc(20% - 2px);
  }

  .MainTitleWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: calc(20% - 2px);
  }

  .materialTitle {
    text-align: start;
    padding: 0.5rem;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mainContentInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: calc(80% - 2px);
  }

  .iconWrapper {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mainTextContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }
`;

const Separator = styled.div`
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, rgba(22, 60, 67, 0.2) 0%, rgba(240, 249, 251, 0.2) 100%);
`;

type CardProps = {
  title: string;
  img: string;
  mainInfo: string;
  percent: string;
};

export default function CardsHomeMaterialsInfo({ title, mainInfo, percent, img }: CardProps) {
  return (
    <CardContainer>
      <div className="MainTitleWrapper">
        <h3 className="materialTitle">{title}</h3>
      </div>
      <Separator />
      <div className="mainContentInfo">
        <div className="iconWrapper">
          <Avatar
            sx={{
              height: '100px',
              width: '100px'
            }}
            src={img ? baseImg + img : process.env.PUBLIC_URL + '/assets/lixo.jpg'}>
            -
          </Avatar>
        </div>
        <div className="mainTextContent">
          <h3>{mainInfo}</h3>
          <h3>{percent}</h3>
        </div>
      </div>
    </CardContainer>
  );
}
