import api from './api';
import { IVehicle, blankVehicle, vehicleProps } from '../models/Vehicle';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class VehicleService {
  static async getAll(): Promise<IVehicle[]> {
    try {
      const response = await api.get('/vehicles');
      let allVehicles = response.data as IVehicle[];
      return allVehicles;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar veículos');
      return [];
    }
  }
  static async getById(id: number): Promise<IVehicle> {
    try {
      const response = await api.get(`/vehicles/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, vehicleProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar veículo!');
      return blankVehicle;
    }
  }

  static async create({ id, ...vehicle }: IVehicle): Promise<IVehicle> {
    try {
      this.standarlizeVehicle(vehicle);
      const response = await api.post('/vehicles', vehicle);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir veículo');
        return blankVehicle;
      }
      toast.success('Veículo incluído com sucesso!');
      return response.data as IVehicle;
    } catch (error: any) {
      toast.error('Erro ao incluir veículo!');
      return blankVehicle;
    }
  }
  static async update({ ...vehicle }: IVehicle): Promise<boolean> {
    try {
      this.standarlizeVehicle(vehicle);
      let response = await api.put(`/vehicles/${vehicle.id}`, vehicle);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar veículo!');
        return false;
      }
      toast.success('Veículo atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar veículo!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/vehicles/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir veículo!');
        return false;
      }
      toast.success('Veículo excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir veículo!');
      return false;
    }
  }

  static standarlizeVehicle(v: IVehicle | any): IVehicle {
    v.company_id = Number(v.company_id);
    v.company_id = v.company_id || undefined;
    v.collector_id = Number(v.collector_id);
    v.collector_id = v.collector_id || undefined;
    v.capacity = Number(v.capacity) || 0;
    v.consumption = Number(v.consumption) || 0;
    return v;
  }
}

export default VehicleService;
