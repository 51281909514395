import { Avatar, Chip, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { baseImg } from '../App';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { IRouteEvent, routeEventStatus } from '../models/RouteEvent';
import { useNavigate } from 'react-router-dom';

//0-Planejada, 1-Cancelada, 2-Atrasada, 3-Em andamento, 4-Aguardando conclusão, 5-Finalizada
const colors = routeEventStatus.map((status) => status.color);

const Card = styled.div<{ status: number }>`
  min-width: 400px;
  min-height: 200px;
  max-height: 200px;
  border: 2px solid ${(props) => colors[props.status]};
  border-radius: var(--borderRadius-m);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;

  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: visible;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 0;
`;

const CardHeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubContent = styled.div`
  margin-top: 2px;
  display: flex;
`;

const CardsMainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardStatus = styled.div<{ status: number }>`
  border: 2px solid ${(props) => colors[props.status]};
  color: ${(props) => colors[props.status]};
  border-radius: 6px;
  padding: 0px 10px;
  margin-top: -24px;
  margin-left: 240px;
  width: 100px;
  background-color: white;
  font-size: 12px;
`;

const RowDisplay = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  gap: 10px;
`;

const IdText = styled.p`
  color: var(--black-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
  margin-left: 2px;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 12,
    border: '1px solid #dadde9'
  }
}));

type Props = {
  routeEvent: IRouteEvent;
  onClick: (id: number) => void;
  onView: () => void;
};

export default function RouteEventCard({ routeEvent, onClick, onView }: Props) {
  const concatenatedNeighborhoods = routeEvent.neighborhoods_name ? routeEvent.neighborhoods_name.map((neighborhood) => `${neighborhood}, `) : [];
  const navigate = useNavigate();
  const neighborhoods = concatenatedNeighborhoods.join('').slice(0, -2);

  const [toolBarOpen, setToolBarOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  function handleViewMap(event: Event | React.SyntheticEvent) {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    navigate('/routes_map/' + routeEvent.id);
  }

  const handleToggle = () => {
    setToolBarOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setToolBarOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setToolBarOpen(false);
    } else if (event.key === 'Escape') {
      setToolBarOpen(false);
    }
  }

  // return focus to the button when we transitioned from !toolBarOpen -> toolBarOpen
  const prevOpen = useRef(toolBarOpen);
  useEffect(() => {
    if (prevOpen.current === true && toolBarOpen === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = toolBarOpen;
  }, [toolBarOpen]);

  return (
    <Card status={routeEvent.status}>
      <div className="route-event-chip">
        <Chip
          sx={{
            borderRadius: '6px',
            background: 'var(--preto-0, #fff)',
            border: `2px solid ${colors[routeEvent.status]}`,
            color: `${colors[routeEvent.status]}`
          }}
          label={routeEventStatus[routeEvent.status].name}
        />
      </div>
      <CardHeader>
        <CardHeaderMain>
          <h2>{routeEvent.name}</h2>
          <MoreVertIcon
            sx={{ '&:hover': { cursor: 'pointer' }, color: '#A4A4A4' }}
            aria-controls={toolBarOpen ? 'composition-menu' : undefined}
            aria-expanded={toolBarOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            ref={anchorRef}
          />
        </CardHeaderMain>
        <div className="tool-bar">
          <Popper open={toolBarOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={toolBarOpen} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={onView}>Editar Rota</MenuItem>
                      <MenuItem onClick={handleViewMap}>Ver Map</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <OpenWithIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={onView} />
      </CardHeader>
      <SubContent>
        <IdText>ID: {routeEvent.id}</IdText>
      </SubContent>
      <SubContent>
        <h4>{neighborhoods.length > 40 ? neighborhoods.substring(0, 40) + '...' : neighborhoods}</h4>
      </SubContent>
      <CardsMainContent>
        <RowDisplay>
          <Chip label={routeEvent.route.recurrent ? 'Recorrente' : 'Única'} />
          {routeEvent.schedule.length > 1 ? (
            <HtmlTooltip
              title={
                <>
                  {routeEvent.schedule.map((schedule) => (
                    <p key={schedule.initial_time}>{`${schedule.initial_time} - ${schedule.end_time}`}</p>
                  ))}
                </>
              }>
              <Chip label={'Horarios'} />
            </HtmlTooltip>
          ) : (
            <Chip label={`${routeEvent.schedule[0].initial_time} - ${routeEvent.schedule[0].end_time}`} />
          )}
          <Chip label={routeEvent.vehicle?.name ? routeEvent.vehicle?.name : 'Sem Veículo'} />
          <Chip label={'Coletas:' + routeEvent.qty} />
        </RowDisplay>
        <RowDisplay>
          <Chip label={routeEvent.company?.fantasy_name} />
          <div className="cards-avatar">
            <Chip avatar={<Avatar alt="" src={routeEvent.collector?.img ? baseImg + routeEvent.collector?.img : ''} />} label={routeEvent.collector?.name} />
          </div>
        </RowDisplay>
      </CardsMainContent>
    </Card>
  );
}
