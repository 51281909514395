import { ICollectEvent } from './CollectEvent';
import { IMaterial } from './Material';
import { IRouteEvent, blankRouteEvent } from './RouteEvent';
import { IUser } from './User';

export interface IWeighing {
  id: number;
  date_time?: string;
  route_event_id: number;
  collectEvents: ICollectEvent[];
  collect_events_ids?: Array<number>;
  materials: IMaterial[];
  totalWeight: number;
  routeEvent: IRouteEvent;
  company_name?: string;
  ces?: string;
  weigher?: IUser;
}

export const blankWeighing: IWeighing = {
  id: 0,
  route_event_id: 0,
  collectEvents: [],
  collect_events_ids: [],
  materials: [],
  totalWeight: 0,
  routeEvent: blankRouteEvent,
  company_name: '',
  weigher: undefined
};

export const weighingProps = Object.keys(blankWeighing);
