import dayjs from 'dayjs';
import { IAddress, blankAddress } from './Address';
import { IRoute, blankRoute } from './Route';
import { IUser, blankUser } from './User';
import { ICompany, blankCompany } from './Company';
import { IMaterial } from './Material';
export interface ICollect {
  id: number;

  request_date: string;
  recurrent: boolean;
  generator_type: string; // 'Residencial' | 'Comercial'
  default_address: boolean;
  address: IAddress;
  materials: Array<IMaterial>;
  status: number;
  obs: string;
  weekdays: Array<number>;
  dates: Array<string>;
  weekly_frequence?: number;

  company_id: number;
  company?: ICompany;
  route_id: number;
  route?: IRoute;
  generator_id: number;
  generator?: IUser;
}

export const blankCollect: ICollect = {
  id: 0,
  request_date: dayjs().format('YYYY-MM-DD'),
  recurrent: true,
  generator_type: 'Residencial',
  default_address: true,
  address: blankAddress,
  materials: [],
  status: 3,
  obs: '',
  weekdays: [],
  dates: [],
  weekly_frequence: 1,

  company_id: 1,
  company: { ...blankCompany, id: 1 },
  route_id: 0,
  route: blankRoute,
  generator_id: 0,
  generator: blankUser
};
export const collectStatus = [
  { id: 0, name: 'Solicitada', color: '#FFD140' }, //35A1B7
  { id: 1, name: 'Ativa', color: '#35A1B7' }, //730E18
  { id: 2, name: 'Cancelada', color: '#730E18' }, //FFD140
  { id: 3, name: 'Sem área de atendimento', color: '#735700' }
  // { id: 4, name: 'Aguardando conclusão', color: '#735700' },
  // { id: 5, name: 'Finalizada', color: '#133940' }
];
export const collectContainers = [
  { name: 'Sacola ', img: '/materials_size/sacola_05kg.svg' },
  { name: 'Latão/Balde ', img: '/materials_size/latao_lixo.svg' },
  { name: 'Caixa', img: '/materials_size/caixa.png' },
  { name: 'Garrafa', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Bombona', img: '/materials_size/garrafa_10l.svg' },
  { name: 'Big Bag', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Sacola Plástica', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Bombona/Tambor', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Caçamba Aberta', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Caçamba Fechada', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Peça', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Caixa de Papelão', img: '/materials_size/garrafa_01l.svg' },
  { name: 'Outros', img: '/materials_size/garrafa_01l.svg' }
];
export const collectSizes = ['Pequena', 'Média', 'Grande', '1L', '2L', '5L', '10L', '20L', '50L', '100L', '500L', '1000L'];

export const collectProps = Object.keys(blankCollect);
