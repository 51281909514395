import React from 'react';
import { useEffect, useState } from 'react';

import styled from 'styled-components';

const CardWrapper = styled.div`
  width: 100%;
  background-color: var(--bgColor-1);
  /* border: 1px solid var(--black-300); */
  border-radius: var(--borderRadius-m);
  box-shadow: var(--shadow-3);

  transition: var(--transition);

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

const Title = styled.h5`
  margin: 0;
  padding-top: 10px;
  padding-left: 8px;
  text-align: start;
`;

const Content = styled.h1`
  padding-left: 24px;
  padding-right: 14px;
  padding-top: 5px;
  text-align: start;
`;

const Separator = styled.hr`
  border: 1px solid white; /* Cor da linha branca */
`;

const CardContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px auto;
  max-width: 90%;
`;
type ICard = {
  id: number;
  title: string;
  content: number;
};

type Props = {
  cards: ICard[];
  choiced: number;
  onChange: (choice: number) => void;
};
const CardsContainer = ({ cards, choiced, onChange }: Props) => {
  const [choice, setChoice] = useState(0);
  const selected = {
    backgroundColor: 'var(--primary-700)'
  };
  const textSelected = {
    color: 'var(--black-0)'
  };
  const normal = { backgroundColor: 'var(--bgColor-1)' };
  useEffect(() => {
    setChoice(choiced);
  }, []);
  const handleChoice = (id: number) => {
    onChange(id);
    setChoice(id);
  };

  return (
    <CardContainerWrapper>
      {cards.map((card) => (
        <CardWrapper key={card.id} onClick={() => handleChoice(card.id)} style={choice === card.id ? selected : normal}>
          <Title style={choice === card.id ? textSelected : normal}>{card.title}</Title>
          <Separator />
          <Content style={choice === card.id ? textSelected : normal}>{card.content}</Content>
        </CardWrapper>
      ))}
    </CardContainerWrapper>
  );
};

export default CardsContainer;
