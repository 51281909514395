import { ICompany } from '../models/Company';
import { IUser } from '../models/User';
import CompanyService from './CompanyService';

// import { user } from './fakerAuth';
export const TOKEN_KEY = '@token';
export const USER_KEY = '@user';

type IData = {
  user: IUser;
  access_token: string;
};

export function isAuthenticated() {
  return localStorage.getItem(TOKEN_KEY) !== null;
}
export const getToken = () => localStorage.getItem(TOKEN_KEY);

export function getAuthUser() {
  return JSON.parse(localStorage.getItem(USER_KEY) || '{}');
}

export function login(data: IData): void {
  let user = { ...data.user, role: data.user.role, role_name: data.user.role.name };
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  localStorage.setItem(TOKEN_KEY, data.access_token);
}
export function logout(): void {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
}

export async function protectForm(defaultUrl: string): Promise<ICompany> {
  let authUser = getAuthUser();
  let company_id = authUser.company?.id ? authUser.company.id : 0;
  if (authUser.role.name === 'Super Admin') {
    if (!authUser.company) {
      alert('Para acessar esta página como Super Admin, você deve escolher uma empresa coletadora!');
      window.open(defaultUrl, '_self');
    }
  }
  let company = await CompanyService.getById(company_id);
  console.log(company);
  if (!company.type.includes('Coletadora')) {
    alert('Apenas empresas coletadoras acessam esta página!');
    window.open(defaultUrl, '_self');
  }
  return company;
}

// localStorage.setItem(TOKEN_KEY, 'Bearer 123456789');
// localStorage.setItem(USER_KEY, JSON.stringify(user));
