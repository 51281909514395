import api from './api';
import { IResidueClass, blankResidueClass, residueClassProps } from '../models/ResidueClass';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class ResidueClassService {
  static async getAll(): Promise<IResidueClass[]> {
    try {
      const response = await api.get('/residue-class');
      let allClasss = response.data as IResidueClass[];
      return allClasss;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar classe');
      return [];
    }
  }
  static async getById(id: number): Promise<IResidueClass> {
    try {
      const response = await api.get(`/residue-class/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, residueClassProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar classe!');
      return blankResidueClass;
    }
  }

  static async create({ id, ...c }: IResidueClass): Promise<IResidueClass> {
    try {
      this.standarlizeClass(c);
      const response = await api.post('/residue-class', c);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir classe');
        return blankResidueClass;
      }
      toast.success('Veículo incluído com sucesso!');
      return response.data as IResidueClass;
    } catch (error: any) {
      toast.error('Erro ao incluir classe!');
      return blankResidueClass;
    }
  }
  static async update({ ...c }: IResidueClass): Promise<boolean> {
    try {
      this.standarlizeClass(c);
      console.log(c);
      let response = await api.put(`/residue-class/${c.id}`, c);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar classe!');
        return false;
      }
      toast.success('Veículo atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar classe!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/residue-class/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir classe!');
        return false;
      }
      toast.success('Veículo excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir classe!');
      return false;
    }
  }

  static standarlizeClass(c: IResidueClass | any): IResidueClass {
    c.codInea = Number(c.codInea);
    return c;
  }
}

export default ResidueClassService;
