import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { baseImg } from '../../App';
import { collectSizes } from '../../models/Collect';
import { IContainer } from '../../models/Container';
import { blankMaterial, IMaterial } from '../../models/Material';
import { IUnit } from '../../models/Unit';
import ContainerService from '../../services/ContainerService';
import UnitService from '../../services/UnitService';
import { getModelFromField, stringIsNumber } from '../../utils/MyLib';
import { ITreatment } from '../../models/Treatment';
import TreatmentService from '../../services/TreatmentService';
import { IResidueState } from '../../models/ResidueState';
import { IResidueClass } from '../../models/ResidueClass';
import { IComposition } from '../../models/Pack';
import ResidueStateService from '../../services/ResidueStateService';

type Props = {
  initialValue: IMaterial;
  open: boolean;
  onSubmit: (material: IMaterial) => void;
  onClose: () => void;
  allCompositions: IComposition[];
  origin?: string;
};
export default function CollectMaterialModal({ open, initialValue, allCompositions, onClose, onSubmit }: Props) {
  const [allContainers, setAllContainers] = useState<IContainer[]>([]);
  const [allUnits, setAllUnits] = useState<IUnit[]>([]);
  const [allStates, setAllStates] = useState<IResidueState[]>([]);
  const [allTreatment, setAllTreatment] = useState<ITreatment[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [material, setMaterial] = useState(blankMaterial);

  const imgNormal = {
    objectPosition: 'right',
    maxWidth: 25,
    maxHeight: 25
  };

  async function fetchData() {
    let allContainers = await ContainerService.getAll();
    let allUnits = await UnitService.getAll();
    let allTreatment = await TreatmentService.getAll();
    let allStates = await ResidueStateService.getAll();
    setAllContainers(allContainers);
    setAllUnits(allUnits);
    setAllTreatment(allTreatment);
    setAllStates(allStates);
    let material = initialValue;
    let residue = allCompositions.find((composition) => composition.residue_id === material.residue_id);
    let unit = allUnits.find((unit) => unit.id === material.unit_id);
    let container = allContainers.find((container) => container.id === material.container_id);
    let treatment = allTreatment.find((treatment) => treatment.id === material.treatment_id);
    let state = allStates.find((state) => state.id === material.state_id);
    if (residue) material.residue_name = residue.description;
    if (unit) {
      material.unit_name = unit.name;
      material.unit_abreviation = unit.abreviation;
    }
    if (container) material.container_name = container.name;
    if (treatment) material.treatment_name = treatment.name;
    if (state) material.state_name = state.name;

    setMaterial(material);
  }

  useEffect(() => {
    console.log(initialValue);
    fetchData();
    setErrors({});
  }, [open]);

  function close() {
    onClose();
    setMaterial(blankMaterial);
  }

  async function handleCardSizeMaterial(e: any) {
    //  material.residue_id = choice;
    //  setUser(user);
  }

  async function handleChange(e: any) {
    let { field } = getModelFromField(e.target.name);
    let value = e.target.value;
    console.log('field', field, 'value', value);
    switch (field) {
      case 'residue_id':
        let composition = allCompositions.find((composition) => composition.residue_id === value);
        console.log('composition', composition);
        if (composition)
          setMaterial({
            ...material,
            residue_id: value,
            residue_name: composition.description
          });
        break;
      case 'container_id':
        let container = allContainers.find((container) => container.id === value);
        if (container) setMaterial({ ...material, container_id: value, container_name: container.name });
        break;
      case 'unit_id':
        let unit = allUnits.find((unit) => unit.id === value);
        if (unit) setMaterial({ ...material, unit_id: value, unit_name: unit.name, unit_abreviation: unit.abreviation });
        break;
      case 'treatment_id':
        let treatment = allTreatment.find((treatment) => treatment.id === value);
        if (treatment) setMaterial({ ...material, treatment_id: value, treatment_name: treatment.name });
        break;
      case 'state_id':
        let state = allStates.find((state) => state.id === value);
        if (state) setMaterial({ ...material, state_id: value, state_name: state.name });
        break;
      case 'weight':
        if (stringIsNumber(value, 2)) setMaterial({ ...material, [field]: value });
        break;
      default:
        setMaterial({ ...material, [field]: value });
        break;
    }
    console.log('material', material);
  }

  function validateErrors(material: IMaterial) {
    let errors: any = {};
    if (!material.residue_id) errors.residue_id = 'Obrigatorio';
    if (!material.size) errors.size = 'Obrigatorio';
    if (!material.quantity) errors.quantity = 'Obrigatorio';
    if (!material.unit_id) errors.unit_id = 'Obrigatorio';
    if (!material.container_id) errors.container_id = 'Obrigatorio';
    if (!material.state_id) errors.state_id = 'Obrigatorio';
    if (origin === 'WeighingForm') {
      if (!material.treatment_id) errors.treatment_id = 'Obrigatorio';
      if (!material.weight) errors.weight = 'Obrigatorio';
    }
    return errors;
  }

  function handleSave(e: any) {
    e.preventDefault();
    const errors = validateErrors(material);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onSubmit(material);
      setMaterial(blankMaterial);
      onClose();
    }
  }

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Stack>
                <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                  <InputLabel>Residuo</InputLabel>
                  <Select key="residue_id" label="Residuo" name="material.residue_id" value={material.residue_id} onChange={handleChange} placeholder="Material" error={errors.residue_id}>
                    {allCompositions.map((n) => (
                      <MenuItem key={n.residue_id} value={n.residue_id}>
                        {n.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack>
                <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                  <InputLabel>Estado</InputLabel>
                  <Select key="state_id" label="Estado" name="material.state_id" value={material.state_id} onChange={handleChange} placeholder="Material" error={errors.state_id}>
                    {allStates.map((n) => (
                      <MenuItem key={n.id} value={n.id}>
                        {n.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} m={1}>
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ minWidth: 100, maxWidth: 100, m: 1 }} margin="dense">
                  <TextField key="quantity" label="Quantidade" name="material.quantity" value={material.quantity} onChange={handleChange} placeholder="Quantidade" error={errors.quantity} />
                </FormControl>

                <FormControl sx={{ minWidth: 200, maxWidth: 200, m: 1 }} margin="dense">
                  <InputLabel>Recipiente</InputLabel>
                  <Select
                    key="material.container_id"
                    label="Container"
                    name="material.container_id"
                    value={material.container_id}
                    onChange={handleChange}
                    placeholder="Container"
                    error={errors.container_id}>
                    {allContainers.map((container) => (
                      <MenuItem key={container.id} value={container.id}>
                        <Stack direction="row" spacing={2} sx={{ gap: '5px' }} alignItems="center">
                          <img style={imgNormal} src={baseImg + container.img} alt="  " />
                          {container.name}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 190, maxWidth: 190, m: 1 }} margin="dense">
                  <InputLabel>Tamanho</InputLabel>
                  <Select key="material.size" label="Tamanho" name="material.size" value={material.size} onChange={handleChange} placeholder="Tamanho" error={errors.size}>
                    {collectSizes.map((size) => (
                      <MenuItem key={size} value={size}>
                        <Stack direction="row" spacing={2} sx={{ gap: '5px' }} alignItems="center">
                          {size}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} spacing={2} m={1}>
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ maxWidth: 200, m: 1 }} margin="dense">
                  <TextField key="weight" label="Peso" name="material.weight" value={material.weight} onChange={handleChange} placeholder="Peso" error={errors.weight} />
                </FormControl>

                <FormControl sx={{ minWidth: 200, maxWidth: 200, m: 1 }} margin="dense">
                  <InputLabel>Unidade</InputLabel>
                  <Select key="material.unit_id" label="Unidade" name="material.unit_id" value={material.unit_id} onChange={handleChange} placeholder="Unidade" error={errors.unit_id}>
                    {allUnits.map((unit) => (
                      <MenuItem key={unit.id} value={unit.id}>
                        <Stack direction="row" spacing={2} sx={{ gap: '5px' }} alignItems="center">
                          {unit.abreviation}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack>
                <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                  <InputLabel>Tecnologia de Tratamento</InputLabel>
                  <Select
                    key="material.treatment_id"
                    label="Tecnologia de tratamento"
                    name="material.treatment_id"
                    value={material.treatment_id}
                    onChange={handleChange}
                    placeholder="Tecnologia de Tratamento"
                    error={errors.treatment_id}>
                    {allTreatment.map((treatment) => (
                      <MenuItem key={treatment.id} value={treatment.id}>
                        <Stack direction="row" spacing={2} sx={{ gap: '5px' }} alignItems="center">
                          {treatment.name}
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={close}>Cancelar</Button>
        <Button color="secondary" onClick={handleSave} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
