export interface IUnit {
  id: number;
  name: string;
  abreviation: string;
  codInea: number;
}

export const blankUnit: IUnit = {
  id: 0,
  name: '',
  abreviation: '',
  codInea: 0
};

export const unitProps = Object.keys(blankUnit);
