import api from './api';
import { IResidueState, blankResidueState, residueStateProps } from '../models/ResidueState';
import { mapNullToString, clearObj } from '../utils/MyLib';
import { toast } from 'react-toastify';

export class ResidueStateService {
  static async getAll(): Promise<IResidueState[]> {
    try {
      const response = await api.get('/residue-state');
      let allResidueStates = response.data as IResidueState[];
      return allResidueStates;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Estadoss');
      return [];
    }
  }
  static async getById(id: number): Promise<IResidueState> {
    try {
      const response = await api.get(`/residue-state/${id}`);
      let v = mapNullToString(response.data);
      clearObj(v, residueStateProps);
      return v;
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar Estados!');
      return blankResidueState;
    }
  }

  static async create({ id, ...residueState }: IResidueState): Promise<IResidueState> {
    try {
      this.standarlizeResidueState(residueState);
      const response = await api.post('/residue-state', residueState);
      if (response.status !== 201) {
        toast.error('Erro ao Incluir Estados');
        return blankResidueState;
      }
      toast.success('Estado incluído com sucesso!');
      return response.data as IResidueState;
    } catch (error: any) {
      toast.error('Erro ao incluir Estados!');
      return blankResidueState;
    }
  }
  static async update({ ...residueState }: IResidueState): Promise<boolean> {
    try {
      this.standarlizeResidueState(residueState);
      let response = await api.put(`/residue-state/${residueState.id}`, residueState);
      if (response.status !== 200) {
        toast.error('Erro ao Atualizar Estados!');
        return false;
      }
      toast.success('Estado atualizado com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Atualizar Estados!');
      return false;
    }
  }

  static async delete(id: number): Promise<boolean> {
    try {
      let response = await api.delete('/residue-state/' + id);
      if (response.status !== 200) {
        toast.error('Erro ao Excluir Estados!');
        return false;
      }
      toast.success('Estado excluído com sucesso!');
      return true;
    } catch (error: any) {
      toast.error('Erro ao Excluir Estados!');
      return false;
    }
  }

  static standarlizeResidueState(c: IResidueState | any): IResidueState {
    c.codInea = Number(c.codInea);
    c.codInea = c.codInea || undefined;
    return c;
  }
}

export default ResidueStateService;
