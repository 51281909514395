import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, FormControlLabel, Typography, Grid, Select, MenuItem, Stack, InputLabel, FormControl } from '@mui/material';
import { IVehicle, blankVehicle, vehicleStatus } from '../../models/Vehicle';
import { ICompany, blankCompany } from '../../models/Company';
import { IUser } from '../../models/User';
import { toast } from 'react-toastify';
import { validateRequired } from '../../utils/validations';
import { getAuthUser } from '../../services/auth';
import { VehicleService } from '../../services/VehicleService';
import InputMask from 'react-input-mask';
import { BigTextField, MediumTextField, SmallTextField } from '../../components/TextFields';
import { CompanyService } from '../../services/CompanyService';
import { UserService } from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import { AvatarUpload } from '../../components/AvatarUpload';
import { ImgUploadService } from '../../services/ImgUploadService';
import styled from 'styled-components';
import { ConfirmModal } from '../../components/ConfirmModal';
import { baseImg } from '../../App';
import { all } from 'axios';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import * as React from 'react';

const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;

interface ModalProps {
  value: IVehicle;
  onClose: () => void;
  onSubmit: (vehicle: IVehicle, operation: string) => void;
  open: boolean;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustomKmL = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      suffix="Km/L"
    />
  );
});

const NumericFormatCustomKg = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      suffix="Kg"
    />
  );
});

export const VehicleModal = ({ open, value, onClose, onSubmit }: ModalProps) => {
  const [vehicle, setVehicle] = useState<IVehicle>(blankVehicle);
  // const [vehicleTypes, setVehicleTypes] = useState<string[]>([]);
  // const [vehicleFuel, setVehicleFuel] = useState<string[]>([]);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState<ICompany[]>([]);
  const [allCollectors, setAllCollectors] = useState<IUser[]>([]);
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir a veículo?');

  let img = vehicle.img ? baseImg + vehicle.img : '';
  let fileImage: File = new File([], '');

  const fuelTypes = ['Nenhum', 'Gasolina', 'Flex', 'Diesel', 'Elétrico'];
  const vehicleTypes = ['Carroça', 'Carro', 'Caminhonete', 'Caminhão'];

  //Get all companies
  async function fetchCompanies() {
    let allCompanies = await CompanyService.getAll();
    setAllCompanies(allCompanies);
  }
  async function fetchColectors() {
    let allCollectors = await UserService.getAll();
    allCollectors = allCollectors.filter((user) => user.role.name === 'Catador');
    setAllCollectors(allCollectors);
  }

  useEffect(() => {
    fetchCompanies();
    fetchColectors();
  }, []);

  useEffect(() => {
    setErrors({});
    // value.id === 0 ? setVehicle({ ...value, company_id: getAuthUser().company_id }) : setVehicle(value);
    setVehicle(value);
  }, [value]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function handleDeleteConfirmation(vehicle: IVehicle) {
    setConfirmMessage('Deseja excluir a veículo ' + vehicle.name + ' ?');
    setShowConfirmationModal(true);
  }

  async function handleDelete() {
    setShowConfirmationModal(false);
    setLoading(true);
    if (await VehicleService.delete(vehicle.id)) {
      setLoading(false);
      toast.success('Veículo apagado com sucesso');
      vehicle.id = -1;
      onSubmit(vehicle, 'delete');
      onClose();
    }
  }

  function validateErrors(vehicle: IVehicle) {
    let errors: any = {};
    if (!validateRequired(vehicle.name)) errors.name = 'Obrigatorio';
    if (!validateRequired(vehicle.description)) errors.description = 'Obrigatorio';

    return errors;
  }

  async function saveVehicle(vehicle: IVehicle) {
    if (vehicle.id) {
      if (await VehicleService.update(vehicle)) {
        saveImage(vehicle, fileImage);
        toast.success('Veículo salvo com sucesso');
        onSubmit(vehicle, 'update');
        onClose();
      }
    } else {
      let newVehicle = await VehicleService.create(vehicle);
      if (newVehicle.id > 0) {
        vehicle.id = newVehicle.id;
        saveImage(vehicle, fileImage);
        toast.success('Veículo criado com sucesso');
        onSubmit(vehicle, 'create');
        onClose();
      }
    }
  }
  function saveImage(vehicle: IVehicle, fileImage: File): void {
    if (fileImage.size > 0) {
      if (fileImage.size <= 1000000) {
        ImgUploadService.upload('vehicles', vehicle.id, fileImage);
      } else {
        toast.error('Imagem muito grande, tamanho máximo de 1mb');
      }
    }
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateErrors(vehicle);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveVehicle(vehicle);
    }
  };

  //make a function to take a substring up dot position and return the inital string
  function getModelFromField(field: string) {
    try {
      let index = field.indexOf('.');
      return { model: field.substring(0, index), field: field.substring(index + 1, field.length) };
    } catch {
      return { model: '', field: '' };
    }
  }

  //handle avatar upload
  async function handleCarUpload(files: any) {
    fileImage = files[0];
  }

  //Get values on form inputs and put into the model vehicle
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      case 'company_id':
        let company_id = parseInt(value);
        let company = allCompanies.find((company) => company.id === company_id) || blankCompany;
        setVehicle({ ...vehicle, company_id: company_id, company: company });
        break;
      case 'status':
        setVehicle({ ...vehicle, [field]: parseInt(value) });
        break;
      case 'license_plate':
        setVehicle({ ...vehicle, [field]: value.toUpperCase() });
        break;
      default:
        setVehicle({ ...vehicle, [field]: value });
        break;
    }
  }

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle textAlign="center">REGISTRO DE VEÍCULOS</DialogTitle>
      <Separator />
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Stack alignItems="center">
                <AvatarUpload src={img} width={100} radius={18} height={100} handleUpload={handleCarUpload} />
                <br />
              </Stack>
            </Grid>
            <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
              Informações do Veículo
            </Typography>

            {/* Grid 1 */}
            <Grid item xs={12} md={4}>
              <Stack>
                <BigTextField key="vehicle.name" label="Nome do Veículo" name="vehicle.name" value={vehicle.name} onChange={handleChange} error={errors.name} helperText={errors.name} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack>
                <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                  <InputLabel>Empresa</InputLabel>
                  <Select key="vehicle.company_id" label="Empresa" name="vehicle.company_id" value={vehicle.company_id} onChange={handleChange} error={errors.comany_id} placeholder="Empresa">
                    {allCompanies.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.fantasy_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack>
                <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                  <InputLabel>Status</InputLabel>
                  <Select key="vehicle.status" label="Status" name="vehicle.status" value={vehicle.status} onChange={handleChange} error={errors.status} placeholder="Status">
                    {vehicleStatus.map((s) => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack>
                <BigTextField
                  key="vehicle.description"
                  label="Descricao"
                  name="vehicle.description"
                  value={vehicle.description}
                  onChange={handleChange}
                  error={errors.description}
                  helperText={errors.description}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack>
                <InputMask mask="aaa-9*99" name="vehicle.license_plate" value={vehicle.license_plate} onChange={handleChange} maskChar=" ">
                  <SmallTextField label="Placa " error={errors.license_plate} helperText={errors.license_plate} sx={{ maxWidth: 200 }} />
                </InputMask>
              </Stack>
            </Grid>
            {/* Grid 2 */}
            <Grid item xs={12} md={4}>
              <Stack>
                <FormControl margin="dense" sx={{ m: 1 }}>
                  <InputLabel>Tipo de Veículo</InputLabel>
                  <Select key="vehicle.type" label="Tipo de Veículo" name="vehicle.type" value={vehicle.type} onChange={handleChange} error={errors.type} placeholder="Tipo de Veículo">
                    {vehicleTypes.map((types) => (
                      <MenuItem key={types} value={types}>
                        {types}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={2.8}>
              <Stack>
                <FormControl margin="dense" sx={{ m: 1 }}>
                  <InputLabel>Combustível</InputLabel>
                  <Select key="vehicle.fuel" label="Combustível" name="vehicle.fuel" value={vehicle.fuel} onChange={handleChange} error={errors.fuel} placeholder="Tipo de Veículo">
                    {/* {vehicleFuel.map((fuels) => (
                      <MenuItem key={fuels} value={fuels}>
                        {fuels}
                      </MenuItem>
                    ))} */}
                    {fuelTypes.map((fuels) => (
                      <MenuItem key={fuels} value={fuels}>
                        {fuels}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={2.6}>
              <Stack>
                <SmallTextField
                  key="vehicle.capacity"
                  label="Capcidade (Kg)"
                  name="vehicle.capacity"
                  value={vehicle.capacity === 0 ? '' : vehicle.capacity}
                  onChange={handleChange}
                  error={errors.capacity}
                  helperText={errors.capacity}
                  InputProps={{
                    inputComponent: NumericFormatCustomKg as any
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2.6}>
              <Stack>
                <SmallTextField
                  key="vehicle.consumption"
                  label="Consumo (Km/L)"
                  name="vehicle.consumption"
                  value={vehicle.consumption === 0 ? '' : vehicle.consumption}
                  onChange={handleChange}
                  error={errors.consumption}
                  helperText={errors.consumption}
                  InputProps={{
                    inputComponent: NumericFormatCustomKmL as any
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button
          color="warning"
          disabled={vehicle.id === 0}
          onClick={() => {
            handleDeleteConfirmation(vehicle);
          }}>
          Apagar
        </Button>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Salvar
        </Button>
      </DialogActions>
      <ConfirmModal title="Deleção de Veículo" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
    </Dialog>
  );
};
