export interface IPositiveImpact {
  id: number;
  residue_id: number;
  treatment_id: number;
  avoid_co2: number;
  ref_avoid_co2: string;
  water_saved: number;
  ref_water_saved: string;
  energy_saved: number;
  ref_energy_saved: string;
  oil_saved: number;
  ref_oil_saved: string;
  minerals_saved: number;
  ref_minerals_saved: string;
  mineral_name: string;
  price: number;
  ref_price: string;

  residue_name?: string;
  treatment_name?: string;
}

export const blankPositiveImpact: IPositiveImpact = {
  id: 0,
  residue_id: 0,
  treatment_id: 0,
  avoid_co2: 0,
  ref_avoid_co2: '.',
  water_saved: 0,
  ref_water_saved: '.',
  energy_saved: 0,
  ref_energy_saved: '.',
  oil_saved: 0,
  ref_oil_saved: '.',
  minerals_saved: 0,
  ref_minerals_saved: '.',
  mineral_name: '.',
  price: 0,
  ref_price: '.',

  residue_name: '',
  treatment_name: ''
};

export const positiveImpactProps = Object.keys(blankPositiveImpact);
