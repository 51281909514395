/* eslint-disable react-hooks/exhaustive-deps */
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import { Checkbox, Chip, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import 'dayjs/locale/pt-br';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_TableInstance, type MRT_Row } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import { LoadWrapper, PageContainer, PageContent, PageFooter, PageHeader, PageTitle } from '../../components/GlobalStyleds.jsx';
import PageStructure from '../../components/PageStructure';
import { IWeighing } from '../../models/Weighing';
import WeighingService from '../../services/WeighingService';
import { IRouteEvent, routeEventStatus } from '../../models/RouteEvent';
import RouteEventService from '../../services/RouteEventService';
import { IUser, blankUser } from '../../models/User';
import { formatDate } from '../../utils/MyLib';
import RouteEventsService from '../../services/RouteEventService';
import CardsContainer from '../../components/CardsContainer';
import CollectEventService from '../../services/CollectEventService';
import { ICollectEvent } from '../../models/CollectEvent';

export default function WeighingPage() {
  interface IWeighingData {
    id: number; //id da rota
    name: string;
    company_name: string;
    collector: IUser;
    date: string;
    status: number;
    totalWeight: number;
    qtdWeighings?: number;
    weighings: IWeighing[];
    collectEventsWithoutWeighing: ICollectEvent[];
  }
  const [allWeighingData, setAllWeighingData] = useState<IWeighingData[]>([]);
  const [loading, setLoading] = useState(true);
  const [cardChoiced, setCardChoiced] = useState(0);
  const tableInstanceRef = useRef<MRT_TableInstance<IWeighingData>>(null);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedCollectEvents, setSelectedCollectEvents] = useState<ICollectEvent[]>([]);
  const navigate = useNavigate();
  const [cards, setCards] = useState([
    { id: 0, title: 'Rotas Coletadas', content: 0 },
    { id: 1, title: 'Rotas Finalizadas', content: 0 },
    { id: 2, title: 'Rotas Pesadas', content: 0 }
  ]);

  async function fetchData() {
    setLoading(true);

    // setAllWeighings(allWeighings);
    let allRouteEvents = await RouteEventsService.getAll();
    allRouteEvents = allRouteEvents.filter((c) => [5, 6].includes(c.status));
    getWeighingData(allRouteEvents);
    setLoading(false);
  }

  async function getWeighingData(allRouteEvents: IRouteEvent[]) {
    console.log(allRouteEvents);
    let allWeighings = await WeighingService.getAll();
    let allCollectEvents = await CollectEventService.getAll();
    let allWeighingData: IWeighingData[] = [];
    allRouteEvents = allRouteEvents.filter((c) => [5, 6].includes(c.status));
    allRouteEvents.forEach((r) => {
      let collectEventsWithoutWeighing = allCollectEvents.filter((c) => c.route_event_id === r.id && c.weighing_id === null && c.status !== 1);
      let weighings = allWeighings.filter((w) => w.route_event_id === r.id);
      let status = r.status;
      if (collectEventsWithoutWeighing.length > 0) status = 5;
      else {
        if (weighings.length > 0) status = 6;
      }

      if (collectEventsWithoutWeighing.length > 0 || weighings.length > 0) {
        allWeighingData.push({
          id: r.id,
          name: r.name,
          company_name: r.company?.fantasy_name || '',
          collector: r.collector || blankUser,
          date: r.date,
          status: status,
          totalWeight: weighings.reduce((acc, w) => acc + w.totalWeight, 0),
          weighings: weighings,
          collectEventsWithoutWeighing: collectEventsWithoutWeighing,
          qtdWeighings: weighings.length
        });
        if (r.status !== status) {
          RouteEventService.update({ ...r, status: status });
        }
      }
    });

    console.log(allWeighingData);

    allWeighingData.sort((a, b) => b.date.localeCompare(a.date));
    cards[0].content = allWeighingData.filter((c) => [5, 6].includes(c.status)).length;
    cards[1].content = allWeighingData.filter((c) => c.status === 5).length;
    cards[2].content = allWeighingData.filter((c) => c.status === 6).length;
    setCards([...cards]);
    setCardChoiced(0);
    setAllWeighingData(allWeighingData);
  }

  async function handleClickFilterCard(id: number) {
    let allRouteEvents = await RouteEventService.getAll();
    allRouteEvents = allRouteEvents.filter((c) => [5, 6].includes(c.status));
    allRouteEvents = id === 1 ? allRouteEvents.filter((c) => c.status === 5) : allRouteEvents;
    allRouteEvents = id === 2 ? allRouteEvents.filter((c) => c.status === 6) : allRouteEvents;
    getWeighingData(allRouteEvents);
    setCardChoiced(id);
  }

  const handleSelectedCollects = useCallback(
    (ce: ICollectEvent) => {
      if (selectedCollectEvents.includes(ce)) {
        setSelectedCollectEvents(selectedCollectEvents.filter((c) => c.id !== ce.id));
      } else {
        setSelectedCollectEvents([...selectedCollectEvents, ce]);
      }
    },
    [selectedCollectEvents]
  );

  const formDetails = useCallback(
    ({ row, table }: { row: MRT_Row<IWeighingData>; table: MRT_TableInstance<IWeighingData> }) => {
      return (
        <div key={row.id}>
          {row.original.weighings.length > 0 && (
            <Grid container rowSpacing={0} alignItems="center">
              <Typography variant="body2" padding={2}>
                <h4>Pesagens Realizadas</h4>
              </Typography>
            </Grid>
          )}
          {row.original.weighings.map((w, index) => (
            <Grid
              container
              rowSpacing={0}
              alignItems="center"
              bgcolor={index % 2 === 0 ? 'var(--bgColor-1)' : '#FFFFFF'}
              onClick={() => navigate('/weighings/' + w.id, { state: { selectedCollectEvents: w.collectEvents, route_event_id: row.original.id } })}>
              <Grid container rowSpacing={0} alignItems="flex-start" justifyContent="space-between" padding={1}>
                <Grid item xs={12} md={12}>
                  <Typography variant="body2" padding={2}>
                    <h5>{'Id : ' + w.id}</h5>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} sx={{ paddingLeft: 1 }}>
                  {w.collectEvents.map((c) => (
                    <Grid item xs={12} md={12}>
                      {'Gerador: ' + c.collect?.generator?.name}
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={6} md={6}>
                  <h5>{'Materiais'}</h5>
                  {w.materials.map((m) => (
                    <Grid item xs={12} md={12} sx={{ paddingLeft: 1 }}>
                      {m.residue_name + ' - ' + m.weight + ' Kg'}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ))}
          {/* Coletas sem pesagem */}
          {row.original.collectEventsWithoutWeighing.length > 0 && (
            <Grid container rowSpacing={0} alignItems="center">
              <Typography variant="body2" padding={2}>
                <h4>Coletas Sem pesagem</h4>
              </Typography>

              <Chip
                label={'Pesar Coletas Selecionadas'}
                style={{ background: 'var(--primary-700)', color: 'white' }}
                onClick={() => {
                  if (selectedCollectEvents.length === 0) {
                    alert('Selecione pelo menos uma coleta para pesar');
                    return;
                  }
                  navigate('/weighings/0', { state: { selectedCollectEvents: selectedCollectEvents, route_event_id: row.original.id } });
                }}
              />
            </Grid>
          )}

          {row.original.collectEventsWithoutWeighing.map((c, index) => (
            <FormGroup key={index}>
              <Grid container rowSpacing={0} alignItems="center" bgcolor={index % 2 === 0 ? 'var(--bgColor-1)' : '#FFFFFF'}>
                <FormControlLabel control={<Checkbox checked={selectedCollectEvents.includes(c)} onChange={() => handleSelectedCollects(c)} />} label={c.id + ' - ' + c.collect?.generator?.name} />
              </Grid>
            </FormGroup>
          ))}
        </div>
      );
    },
    [selectedCollectEvents]
  );

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<IWeighingData>[]>(
    () => [
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => <Chip variant="outlined" label={routeEventStatus[row.original.status].name} style={{ background: routeEventStatus[row.original.status].color, color: 'white' }} />,
        size: 10
      },
      { accessorKey: 'id', header: 'Id' },
      { accessorKey: 'company_name', header: 'Empresa' },
      {
        accessorKey: 'date',
        header: 'Data',
        Cell: ({ cell }) => <div style={{ textAlign: 'left' }}>{formatDate(cell.getValue())}</div>
      },
      { accessorKey: 'name', header: 'Rota' },
      { accessorKey: 'collector.name', header: 'Coletor' },
      // { accessorKey: 'ces', header: 'Coletas' },
      {
        accessorKey: 'qtdWeighings',
        header: 'Qtd.Pesagens',
        Cell: ({ cell }) => (
          <div style={{ textAlign: 'center' }}>
            {cell.getValue<number>()?.toLocaleString?.('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </div>
        )
      },
      {
        accessorKey: 'totalWeight',
        header: 'Peso Total',
        Cell: ({ cell }) => (
          <div style={{ textAlign: 'center' }}>
            {cell.getValue<number>()?.toLocaleString?.('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}{' '}
            Kg
          </div>
        )
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>PESAGENS</PageTitle>
          <CardsContainer
            cards={cards}
            choiced={cardChoiced}
            onChange={(id) => {
              handleClickFilterCard(id);
            }}></CardsContainer>
        </PageHeader>
        <PageContent>
          {allWeighingData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhuma rota finalizada ou pesada</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />
            </Stack>
          )}
          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {allWeighingData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={allWeighingData}
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false, company_name: false, 'collector.name': false } }}
              renderTopToolbarCustomActions={({ table }) => <Stack direction="row" spacing={1}></Stack>}
              renderDetailPanel={formDetails}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  alert('Clique no botão de expandir para ver os detalhes da pesagem');
                }
              })}
              muiExpandButtonProps={({ row, table }) => ({
                onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }) //set only this row to be expanded
              })}
            />
          )}
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
