import { useContext, useEffect, useState } from 'react';
import { AiOutlineLogout, AiOutlineMenu, AiTwotoneSetting } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import { baseImg } from '../App';
import { SubMenuContext, ToggleMenuContext } from '../contexts/AppContext';
import { getAuthUser, logout } from '../services/auth';
import { CompanyService } from '../services/CompanyService';
import { UserService } from '../services/UserService';

const NavCard = styled(Link)`
  text-decoration: none;
  color: #757d8a;
  width: 100%;

  div {
    width: 100%;
    background-color: ${(props) => (props.isActive ? '#FFFFFF' : 'none')};
    margin: 0px 0;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 16px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #5a6474;
    white-space: nowrap;
    overflow-x: hidden;
  }

  svg {
    min-width: 24px;
    min-height: 24px;
  }

  .logOut {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;

const SubNavCard = styled(Link)`
  text-decoration: none;
  color: #757d8a;
  width: 100%;
  background-color: none;

  div {
    width: 100%;
    background-color: ${(props) => (props.isActive ? '#FFFFFF' : 'none')};
    margin: 0px 0;
    padding: 12px 32px;
    display: flex !important;
    align-items: center;
    border-radius: 8px;
    gap: 16px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: 500;
    color: #5a6474;
    white-space: nowrap;
    overflow-x: hidden;
  }
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

const Container = styled.nav`
  height: 94vh;
  width: ${(props) => (props.isActive ? '271px' : '96px')};
  background: var(--bgColor-1);
  position: fixed;
  transition: 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 29px 20px 0px 20px;
  border-right: 1px solid #e1e3e6;
  box-shadow: var(--shadow-5);
  overflow-y: auto;

  .displayCards {
    width: 100%;
    vertical-align: top;
    flex: 1;
  }

  .profileCard {
    color: #757d8a;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .centerDiv {
    margin: 0 auto;
    width: ${(props) => (props.isActive ? 'auto' : '0')};
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    transition: 300ms;
  }

  .profileText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    gap: 0px;
  }

  .profileCard h1 {
    font-size: 16px;
  }

  .profileCard small {
    font-size: 09px;
    font-weight: normal;
  }

  .profileCard svg {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 31px;
    min-height: 31px;
    margin: ${(props) => (props.isActive ? '0 13px' : '0 13px')};
    cursor: pointer;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #fff;
  }
  .userContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

function NavigationBar(props) {
  const [navDisplay, setNavDisplay] = useContext(ToggleMenuContext);
  const [subCardDisplay, setSubCardDisplay] = useContext(SubMenuContext);
  const [companyId, setCompanyId] = useState('0');
  const [companies, setCompanies] = useState([]);

  const navCards = props.navCards;

  const location = useLocation().pathname;

  const authUser = getAuthUser();

  function permit(navCard) {
    if (navCard.users.includes('all') || navCard.users.includes(authUser?.role.name)) {
      if (authUser.company == null || !authUser.company) {
        return navCard;
      } else {
        if (navCard.companies.includes('all') || navCard.companies.filter((value) => authUser.company.type.includes(value))) {
          return navCard;
        }
      }
      return null;
    }
  }

  async function getCompanies() {
    let allCompanies = await CompanyService.getAllColetadoras();
    setCompanies(allCompanies);
  }
  const img = authUser.img ? baseImg + authUser.img : '';
  useEffect(() => {
    getCompanies();
    let companyId = authUser.company ? authUser.company.id : '0';
    setCompanyId(companyId);
  }, []);

  async function handleSelectCompany(e) {
    let company_id = e.target.value;
    let company = await CompanyService.getById(company_id);
    if (company.admin.id) UserService.impersonate(company.admin.id);
    else alert('Empresa não possui administrador selecionado');
  }

  function renderSubMenu(navCards, navDisplay, location) {
    try {
      navCards = navCards.filter((navCard) => {
        return permit(navCard);
      });
      return navCards.map((navCard, index) => (
        <SubNavCard
          key={'sub' + navCard.name}
          //  isOpen={navDisplay}
          isActive={navCard.url === location}
          to={navCard.url}
          title={navCard.name}>
          <div style={{ display: 'block' }}>
            {navCard.icon}
            <span> {navDisplay && navCard.name}</span>
          </div>
        </SubNavCard>
      ));
    } catch (error) {
      return '';
    }
  }

  function renderMenu(navCards, navDisplay, location) {
    try {
      navCards = navCards.filter((navCard) => {
        return permit(navCard);
      });
      return navCards.map((navCard, i) => (
        <div>
          <NavCard
            // isOpen={navDisplay}
            isActive={navCard.name === subCardDisplay || (navCard.url === location && !subCardDisplay)}
            key={'nav' + navCard.name}
            to={navCard.url}
            onClick={(e) => {
              navCard.name === subCardDisplay ? setSubCardDisplay('') : setSubCardDisplay(navCard.name);
            }}
            title={navCard.name}>
            <div>
              {navCard.icon}
              <span>{navDisplay && navCard.name}</span>
            </div>
          </NavCard>
          <div className="displayCards">{navCard.subCards && navCard.name === subCardDisplay ? renderSubMenu(navCard.subCards, navDisplay, location) : null}</div>
        </div>
      ));
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  return (
    <Container isActive={navDisplay}>
      <div className="profileCard">
        <div className="centerDiv">
          <Link to="/profile">
            <Avatar src={img} style={{ height: '100px', width: '100px' }} />
          </Link>
          <div className="profileText">
            <h1>{authUser?.name}</h1>
            <small style={{ textAlign: 'center' }}>{authUser?.email}</small>
            {/* <form onSubmit={(e) => e.preventDefault()}> */}
            {getAuthUser().role.name === 'Super Admin' ? (
              <select
                style={{ fontSize: '8px', border: 'none', textAlign: 'right', backgroundColor: 'var(--bgColor-1)', width: '100%', height: '18px', overflow: 'hidden' }}
                key="company_id"
                name="company_id"
                value={companyId}
                onChange={handleSelectCompany}>
                {companies &&
                  companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.fantasy_name}
                    </option>
                  ))}
                {getAuthUser().role.name === 'Super Admin' && (
                  <option key="0" value="0">
                    Nenhuma empresa
                  </option>
                )}
              </select>
            ) : (
              <small style={{ textAlign: 'center' }}>{getAuthUser().company?.fantasy_name}</small>
            )}
            {/* </form> */}
          </div>
        </div>
        <AiOutlineMenu
          key="menu"
          onClick={() => {
            setNavDisplay(!navDisplay);
          }}
        />
      </div>

      <div className="displayCards">{renderMenu(navCards, navDisplay, location)}</div>

      <div className="userContainer">
        <div className="line"></div>
        <NavCard
          //  isOpen={navDisplay}
          to="/profile">
          <div className="logOut">
            <AiTwotoneSetting />
            <span>Editar Perfil</span>
          </div>
        </NavCard>
        <NavCard
          //  isOpen={navDisplay}
          to="/login"
          onClick={logout}>
          <div className="logOut">
            <AiOutlineLogout />
            <span>Sair</span>
          </div>
        </NavCard>
      </div>
    </Container>
  );
}

export default NavigationBar;
