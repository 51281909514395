import styled, { css } from 'styled-components';

export const PageContainer = styled.div`
  text-align: center;
  min-width: 90%;
  max-height: calc(100vh - 3.5em);
  margin: auto;

  ${css`
    @media (min-width: 1400px) {
      min-width: 1080px;
      max-width: 1500px;
    }
  `}
`;

export const PageHeader = styled.div`
  // max-width: 80vw;
  max-height: 100vh;
  // min-width: 80vw;
`;
export const PageContent = styled.div`
  max-width: 80vw;
  min-height: 70vh;
  // min-width: 80vw;
`;

export const PageFooter = styled.div`
  max-width: 5vw;
  min-height: 3vh;
`;

export const PageTitle = styled.h2`
  text-align: center;
  color: var(--black-800);
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 141.176% */
`;

export const LoadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const Separator = styled.hr`
  border: 1px solid grey; /* Cor da linha branca */
`;
