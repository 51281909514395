import { useEffect, useState } from 'react';
import styled, { styled as sx } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { Grid, Select, MenuItem, Stack, InputLabel, FormControl, FormControlLabel, Button, DialogActions, Container, Typography, TextField, FormLabel, FormGroup, Checkbox } from '@mui/material';

import { IResidueState, blankResidueState } from '../../models/ResidueState';
import { ICompany } from '../../models/Company';

import { toast } from 'react-toastify';

import ResidueStateService from '../../services/ResidueStateService';
import { validateRequired } from '../../utils/validations';

import { getModelFromField } from '../../utils/MyLib';
import { ConfirmModal } from '../../components/ConfirmModal';
import { Dialog } from '@material-ui/core';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';

export default function ResidueStateForm() {
  const [residueState, setResidueState] = useState<IResidueState>(blankResidueState);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir o Estado de Residuo?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    try {
      let residueState = blankResidueState;
      if (Number(id)) {
        residueState = await ResidueStateService.getById(Number(id));
      }
      setResidueState(residueState);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(residueState: IResidueState) {
    let errors: any = {};
    if (!validateRequired(residueState.name)) errors.name = 'Obrigatorio';
    return errors;
  }

  async function save(residueState: IResidueState) {
    if (residueState.id) {
      if (await ResidueStateService.update(residueState)) {
        toast.success('Estado salvo com sucesso');
      }
    } else {
      let newResidueState = await ResidueStateService.create(residueState);
      if (newResidueState.id > 0) {
        residueState.id = newResidueState.id;
        toast.success('Estado salvo com sucesso');
      }
    }
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(residueState);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      save(residueState);
      navigate('/residue_states', { state: { reload: true } });
    }
  }

  function handleDeleteConfirmation(residueState: IResidueState) {
    setConfirmMessage('Deseja excluir "' + residueState.name + '" ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await ResidueStateService.delete(residueState.id)) {
      toast.success('Estado Resíduo apagado com sucesso');
      residueState.id = -1;
      navigate('/residue_states', { state: { reload: true } });
    }
  }

  //Get values on form inputs and put into the model ResidueState
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      default:
        setResidueState({ ...residueState, [field]: value });
        break;
    }
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="sm" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>CADASTRO DE ESTADO DE RESÍDUO</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/residue_states');
              }}
            />
          </Container>

          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Informações
                </Typography>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="residueState.id" label="Id" name="residueState.id" value={residueState.id} onChange={() => {}} error={errors.id} placeholder="Id" disabled />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="residueState.name" label="Nome" name="residueState.name" value={residueState.name} onChange={handleChange} error={errors.name} placeholder="Nome" />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField key="residueState.abreviation" label="Abreviação" name="residueState.abreviation" value={residueState.abreviation} onChange={handleChange} error={errors.abreviation} placeholder="Abreviação" />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                      <TextField
                        key="residueState.codInea"
                        label="Cód.Inea"
                        name="residueState.codInea"
                        value={residueState.codInea}
                        onChange={handleChange}
                        error={errors.codInea}
                        placeholder="Cód.Ineap"
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={residueState.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(residueState);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/residue_states');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>

          <ConfirmModal title="Deleção de estado" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
