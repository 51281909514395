import { Box, Button, FormControlLabel, FormGroup, Grid, LinearProgress, Stack, Switch, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState, useRef } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import DatePickerCarousel from '../../components/DatePickerCarousel';
import { PageContainer, PageContent, PageFooter, PageHeader, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import RouteEventCard from '../../components/RouteEventCard';
import { IRouteEvent, blankRouteEvent, routeEventStatus } from '../../models/RouteEvent';
import CompanyService from '../../services/CompanyService';
import NeighborhoodService from '../../services/NeighborhoodService';
import RouteEventsService from '../../services/RouteEventService';
import UserService from '../../services/UserService';
import VehicleService from '../../services/VehicleService';
import CollectEventsService from '../../services/CollectEventService';
import { INeighborhood } from '../../models/Neighborhood';
import { ICompany } from '../../models/Company';
import { IVehicle } from '../../models/Vehicle';
import { IUser } from '../../models/User';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

import './RouteEventPage.css';
import { ICollectEvent } from '../../models/CollectEvent';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const Separator = styled.hr`
  border: 1px solid var(--azul-bg, #f0f9fb); /* Cor da linha branca */
  background-color: var(--azul-bg, #f0f9fb);
  width: 100%;
  height: 4px;
  margin-bottom: 10px;
`;

export default function RouteEventPage() {
  const [routesEvents, setRoutesEvents] = useState<IRouteEvent[]>([]);
  const [allRoutesEvents, setAllRoutesEvents] = useState<IRouteEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [cities, setCities] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const [datesWithRoutes, setDatesWithRoutes] = useState<string[]>([]);
  const [typeView, setTypeView] = useState('card');
  let dateChoicedStr: string = searchParams.get('date') || dayjs().format('YYYY-MM-DD');
  let dateChoiced: Dayjs = dayjs(dateChoicedStr);

  let allNeighborhoods: INeighborhood[] = [];
  let allCompanies: ICompany[] = [];
  let allVehicles: IVehicle[] = [];
  let allUsers: IUser[] = [];
  let allCollectEvents: ICollectEvent[] = [];
  const [routeEvent, setRouteEvent] = useState<IRouteEvent>(blankRouteEvent);
  const tableInstanceRef = useRef<MRT_TableInstance<IRouteEvent>>(null);

  async function fetchData() {
    setLoading(true);

    let allRoutesEvents = await RouteEventsService.getAll();

    allCollectEvents = await CollectEventsService.getAll();
    let datesWithRoutes = allRoutesEvents.map((r) => r.date);
    setDatesWithRoutes(datesWithRoutes);
    allNeighborhoods = await NeighborhoodService.getAll();
    allVehicles = await VehicleService.getAll();
    allUsers = await UserService.getAll();
    allCompanies = await CompanyService.getAll();

    allRoutesEvents.map((re) => {
      try {
        re.city = allNeighborhoods.find((n) => n.id === re.schedule[0]?.neighborhood_id)?.city;
        re.state = allNeighborhoods.find((n) => n.id === re.schedule[0]?.neighborhood_id)?.state;
      } catch (e) {
        re.city = 'Cidade não encontrada';
        re.state = 'Estado não encontrado';
      }
      re.schedule.sort((a, b) => a.initial_time.localeCompare(b.initial_time));
      re.neighborhoods_name = re.schedule.map((s) => '[' + s.neighborhood_name + ']');
      re.qty = allCollectEvents.filter((ce) => ce.route_event_id === re.id).length;

      return re;
    });
    setAllRoutesEvents(allRoutesEvents);
    allRoutesEvents = allRoutesEvents.filter((r) => r.date === dateChoiced.format('YYYY-MM-DD'));
    let uniqueCities: Set<string> = new Set();
    let uniqueStates: Set<string | undefined> = new Set();
    let uniqueCompanies: Set<string | undefined> = new Set();
    allRoutesEvents.forEach((re) => {
      re.city && uniqueCities.add(re.city);
      uniqueStates.add(re.state);
      uniqueCompanies.add(re.company?.fantasy_name);
    });
    setLoading(false);
    setCities([...uniqueCities]);
    setRoutesEvents(allRoutesEvents);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function handleClickDate(date: Dayjs) {
    dateChoiced = date;
    fetchData();
  }

  function handleEdit(id: number) {
    navigate('/routes_events/' + id);
  }

  async function handleEditRow(row: MRT_Row<IRouteEvent>) {
    navigate(`/routes_events/${Number(row.id)}`);
  }

  function handleNew(): void {
    navigate('/routes/0');
  }

  function handleView(e: any) {
    setTypeView(typeView === 'card' ? 'grid' : 'card');
  }

  const columns = useMemo<MRT_ColumnDef<IRouteEvent>[]>(
    () => [
      { accessorKey: 'id', header: 'Id', size: 10 },
      // { accessorKey: 'company.fantasy_name', header: 'Empresa' },
      {
        accessorKey: 'name',
        header: 'Nome da Rota',
        size: 10
      },
      { accessorKey: 'company.fantasy_name', id: 'fantasy_name', header: 'Empresa', size: 10 },
      { accessorKey: 'city', header: 'Cidade', size: 10 },
      { accessorKey: 'collector.name', header: 'Operador', size: 10 },
      { accessorKey: 'date', header: 'Data', Cell: ({ row }) => dayjs(row.original.date).format('DD/MM/YYYY'), size: 10 },
      { accessorKey: 'status', header: 'Status', Cell: ({ row }) => routeEventStatus[row.original.status].name, size: 10 },
      { accessorKey: 'qty', header: 'Qtd.Coletas', size: 5 }
    ],

    []
  );

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader>
          <PageTitle>ROTAS AGENDADAS</PageTitle>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => {
                navigate('/routes');
              }}
              style={{ textTransform: 'none' }}>
              Painel de Rotas
            </Button>
            <Button variant="outlined" onClick={handleNew} style={{ textTransform: 'none' }}>
              Criar Rota
            </Button>
            <FormGroup>
              <FormControlLabel control={<Switch defaultChecked onClick={handleView} />} label="Card/Grid" />
            </FormGroup>
          </Stack>

          <Stack>
            {typeView === 'card' && (
              <DatePickerCarousel
                dateChoiced={dateChoiced}
                datesWithRoutes={datesWithRoutes}
                onChange={(date) => {
                  handleClickDate(date);
                }}
              />
            )}
          </Stack>
          <Separator />
          {loading && (
            <Box sx={{ width: '100%', alignContent: 'center' }}>
              <LinearProgress />
            </Box>
          )}
        </PageHeader>
        <PageContent>
          <Grid container spacing={0} padding={0}>
            {typeView === 'card' ? (
              <>
                {cities.map((city) => {
                  return (
                    <>
                      <Grid item sm={12} md={12}>
                        <h1 style={{ display: 'flex', padding: '5px' }}> {city}</h1>
                      </Grid>
                      <Grid container spacing={2} padding={2} columns={{ xs: 12, sm: 6, md: 4 }}>
                        {routesEvents
                          .filter((r) => r.city === city)
                          .map((routeEvent) => {
                            return (
                              <Grid item>
                                <RouteEventCard key={routeEvent.id} routeEvent={routeEvent} onClick={handleEdit} onView={() => navigate('/routes_events/' + routeEvent.id)} />
                              </Grid>
                            );
                          })}
                      </Grid>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                {allRoutesEvents.length > 0 && !loading && (
                  <MaterialReactTable
                    tableInstanceRef={tableInstanceRef}
                    getRowId={(row) => row.id.toString()}
                    localization={MRT_Localization_PT_BR}
                    columns={columns}
                    data={allRoutesEvents}
                    initialState={{ columnVisibility: { id: false, fantasy_name: true } }}
                    muiTableBodyRowProps={({ row }) => ({
                      onDoubleClick: (event) => {
                        handleEditRow(row);
                      }
                    })}
                  />
                )}
              </>
            )}
          </Grid>
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
