import React from 'react';
import { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';

import { INeighborhoodSchedule, blankNeighborhoodSchedule } from '../../models/Route';
import { INeighborhood } from '../../models/Neighborhood';

import { NeighborhoodService } from '../../services/NeighborhoodService';

import { validateHours } from '../../utils/validations';
import { getModelFromField } from '../../utils/MyLib';
import { SmallTextField } from '../../components/TextFields';
import InputMask from 'react-input-mask';

type Props = {
  initialValue: INeighborhoodSchedule;
  city: string;
  company_id: number;
  open: boolean;
  onSubmit: (neighborhoodSchedule: INeighborhoodSchedule) => void;
  onClose: () => void;
};
export default function RouteNeighborhoodModal({ open, initialValue, city, company_id, onClose, onSubmit }: Props) {
  const [allNeighborhoods, setAllNeighborhoods] = useState<INeighborhood[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [neighborhoodSchedule, setNeighborhoodSchedule] = useState<INeighborhoodSchedule>(blankNeighborhoodSchedule);

  async function fetchData() {
    let allNeighborhoods = await NeighborhoodService.getAll();
    allNeighborhoods = allNeighborhoods.sort((a, b) => a.neighborhood.localeCompare(b.neighborhood));
    if (city) {
      allNeighborhoods = allNeighborhoods.filter((n) => n.city === city);
    }
    if (company_id) {
      allNeighborhoods = allNeighborhoods.filter((n) => n.company_id === company_id);
    }

    setAllNeighborhoods(allNeighborhoods);
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, [open]);

  async function handleChange(e: any) {
    let { field } = getModelFromField(e.target.name);
    let value = e.target.value;
    if (field === 'neighborhood_id') {
      let neighborhood = allNeighborhoods.find((n) => n.id === value);
      if (neighborhood) {
        setNeighborhoodSchedule({ ...neighborhoodSchedule, [field]: value, neighborhood_name: neighborhood.neighborhood, city: neighborhood.city });
      }
    } else {
      setNeighborhoodSchedule({ ...neighborhoodSchedule, [field]: value });
    }
  }

  function validateErrors(neighborhoodSchedule: INeighborhoodSchedule) {
    let errors: any = {};
    if (!neighborhoodSchedule.neighborhood_id) errors.neighborhood_id = 'Obrigatorio';
    if (!validateHours(neighborhoodSchedule.initial_time)) errors.initial_time = 'Obrigatorio';
    if (!validateHours(neighborhoodSchedule.end_time)) errors.end_time = 'Obrigatorio';
    if (neighborhoodSchedule.initial_time && neighborhoodSchedule.end_time && neighborhoodSchedule.initial_time > neighborhoodSchedule.end_time)
      errors.end_time = 'Hora final deve ser maior que a inicial';
    return errors;
  }

  function handleSave(e: any) {
    e.preventDefault();
    const errors = validateErrors(neighborhoodSchedule);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      onSubmit(neighborhoodSchedule);
      setNeighborhoodSchedule(blankNeighborhoodSchedule);
      onClose();
    }
  }

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogContent sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Stack>
                <FormControl sx={{ maxWidth: 550, m: 1 }} margin="dense">
                  <InputLabel>Bairro</InputLabel>
                  <Select
                    key="neighborhoodSchedule.neighborhood_id"
                    label="Bairro"
                    name="neighborhoodSchedule.neighborhood_id"
                    value={neighborhoodSchedule.neighborhood_id}
                    onChange={handleChange}
                    placeholder="Bairro"
                    error={errors.neighborhood_id}>
                    {allNeighborhoods.map((n) => (
                      <MenuItem key={n.id} value={n.id}>
                        {n.neighborhood + ' - ' + n.city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Grid item xs={12} md={5}>
                <Stack>
                  <InputMask mask="99:99" name="neighborhoodSchedule.initial_time" value={neighborhoodSchedule.initial_time} onChange={handleChange} maskChar="">
                    <SmallTextField label="Hora Inicial" error={errors.initial_time} helperText={errors.initial_time} sx={{ maxWidth: 200 }} />
                  </InputMask>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack>
                  <InputMask mask="99:99" name="neighborhoodSchedule.end_time" value={neighborhoodSchedule.end_time} onChange={handleChange} maskChar="">
                    <SmallTextField label="Hora Final" error={errors.end_time} helperText={errors.end_time} sx={{ maxWidth: 200 }} />
                  </InputMask>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button color="secondary" onClick={handleSave} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
