/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { Button, Container, DialogActions, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import CardsContainerModal from '../../components/CardsContainerModal';
import { ConfirmModal } from '../../components/ConfirmModal';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import SubCardComponent from '../../components/SubCard';
import { ICollectEvent } from '../../models/CollectEvent';
import { blankMaterial, IMaterial } from '../../models/Material';
import { IComposition } from '../../models/Pack';
import { IRouteEvent } from '../../models/RouteEvent';
import { blankWeighing, IWeighing } from '../../models/Weighing';
import CollectEventService from '../../services/CollectEventService';
import PackService from '../../services/PackService';
import RouteEventsService from '../../services/RouteEventService';
import WeighingService from '../../services/WeighingService';
import { formatDate, getModelFromField } from '../../utils/MyLib';
import CollectMaterialModal from '../Collect/CollectMaterialModal';

const Separator = styled.hr`
  border: 1px solid lightgray; /* Cor da linha branca */
`;

export default function WeighingForm() {
  const [weighing, setWeighing] = useState<IWeighing>(blankWeighing);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir esta pesagem?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allRouteEvents, setAllRouteEvents] = useState<IRouteEvent[]>([]);
  const [modalMaterial, setModalMaterial] = useState(false);
  const [allCompositions, setAllCompositions] = useState<IComposition[]>([]);
  const [material, setMaterial] = useState<IMaterial>(blankMaterial);
  const [collectModalOperation, setCollectModalOperation] = useState('new');

  const navigate = useNavigate();

  let { id } = useParams();

  interface propState {
    selectedCollectEvents: ICollectEvent[];
    route_event_id: number;
  }
  const location = useLocation();
  let { selectedCollectEvents, route_event_id } = location.state as propState;

  function getTotalMaterial(materials: IMaterial[], totalWeight: number) {
    if (materials.length === 0) return totalWeight;
    let total = 0;
    materials.forEach((m) => {
      m.weight = Number(m.weight);
      total += m.weight || 0;
    });
    return total;
  }

  //Get all collectors
  async function fetchData() {
    try {
      let nid = Number(id);
      let weighing: IWeighing = { ...blankWeighing, id: nid, route_event_id: route_event_id, collectEvents: selectedCollectEvents, materials: [] };
      if (nid) weighing = await WeighingService.getById(Number(id));
      else {
        selectedCollectEvents.forEach((ce) => {
          ce.materials?.forEach((m) => {
            const material = weighing.materials && weighing.materials.find((mat) => mat.residue_id === m.residue_id);

            if (material) {
              material.weight += Number(m.weight);
            } else {
              weighing.materials?.push({
                residue_id: m.residue_id,
                container_id: m.container_id,
                quantity: 1,
                size: 'Pequena',
                weight: Number(m.weight) || 0,
                unit_id: m.unit_id,
                state_id: m.state_id,
                treatment_id: m.treatment_id,
                batch_number: '',
                residue_name: m.residue_name,
                container_name: m.container_name,
                unit_name: m.unit_name,
                unit_abreviation: m.unit_abreviation,
                state_name: m.state_name,
                treatment_name: m.treatment_name
              });
            }
          });
        });
      }

      weighing.totalWeight = getTotalMaterial(weighing.materials, weighing.totalWeight);

      setWeighing(weighing);

      let allRoutesEvents = [await RouteEventsService.getById(route_event_id)];
      let allCompositions = await possibleCompositions(route_event_id);
      setAllCompositions(allCompositions);
      setAllRouteEvents(allRoutesEvents);
      setWeighing(weighing);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      // toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(weighing: IWeighing) {
    let errors: any = {};
    if (!weighing.collectEvents || weighing.collectEvents.length === 0) errors.collectEvents = 'A pesagem deve possuir ao menos uma coleta';
    if (!weighing.route_event_id) errors.route_event_id = 'Obrigatório';
    if (!weighing.materials || weighing.materials.length === 0) errors.materials = 'Obrigatório';
    let totalWeight = getTotalMaterial(weighing.materials, weighing.totalWeight);
    if (totalWeight === 0) errors.totalWeight = 'Obrigatório';
    return errors;
  }

  async function saveAll(w: IWeighing) {
    if (w.id) {
      if (await WeighingService.update(w)) {
        toast.success('Pesagem salva com sucesso');
      }
    } else {
      let newWeighing = await WeighingService.create(w);
      if (newWeighing.id > 0) {
        w.id = newWeighing.id;
        toast.success('Pesagem criada com sucesso');
      }
    }
    const numberCollects = w.collectEvents.length;
    const materials = w.materials;
    materials.map((m) => {
      m.weight = m.weight / numberCollects;
      return m;
    });
    w.collectEvents.forEach(async (ce) => {
      ce.weighing_id = w.id;
      ce.status = 6;
      ce.materials = materials;
      await CollectEventService.update(ce);
    });
    setWeighing(w);
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(weighing);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      await saveAll(weighing);
      navigate('/weighings');
    }
  }

  function handleDeleteConfirmation(weighing: IWeighing) {
    setConfirmMessage('Deseja excluir a pesagem ' + weighing.id + ' ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    weighing.collectEvents.forEach((ce) => {
      ce.weighing_id = undefined;
      ce.materials &&
        ce.materials.forEach((m) => {
          m.weight = 0;
        });
      ce.status = 4;
      CollectEventService.update(ce);
    });
    if (await WeighingService.delete(weighing.id)) {
      toast.success('Pesagem apagada com sucesso');
      weighing.id = -1;
      navigate('/weighings');
    }
  }

  //Get values on form inputs and put into the model Weighing
  async function handleChange(e: any) {
    let { field } = getModelFromField(e.target.name);
    let value = e.target.value;
    if (field === 'totalWeight') {
      if (!Number(value)) {
        return;
      }
      let afterPoint = value.split('.')[1];
      if (afterPoint && afterPoint.length > 1) {
        return;
      }
    }
    setWeighing({ ...weighing, [field]: value });
  }

  function handleNewMaterial() {
    setCollectModalOperation('new');
    setMaterial(blankMaterial);
    setModalMaterial(true);
  }

  function handleEditMaterial(id: number) {
    let mat = weighing.materials.find((m) => m.residue_id === id);
    if (!mat) {
      return;
    }
    setCollectModalOperation('edit');
    setMaterial(mat);
    setModalMaterial(true);
  }

  function handleDeleteMaterial(id: number) {
    let materials = weighing.materials;
    materials = weighing.materials.filter((m) => m.residue_id !== id);
    setWeighing({ ...weighing, materials: materials, totalWeight: getTotalMaterial(materials, weighing.totalWeight) });
  }

  function handleIncludeMaterial(m: IMaterial): void {
    let newMaterials = weighing.materials;
    let material = weighing.materials.find((mat) => mat.residue_id === m.residue_id);

    if (material && m.weight) {
      if (collectModalOperation === 'new') material.weight += Number(m.weight);
      else material.weight = Number(m.weight);
      material.container_id = m.container_id;
      material.quantity = m.quantity;
      material.size = m.size;
      material.unit_id = m.unit_id;
      material.state_id = m.state_id;
      material.treatment_id = m.treatment_id;
      material.batch_number = m.batch_number;
      material.residue_name = m.residue_name;
      material.container_name = m.container_name;
      material.unit_name = m.unit_name;
      material.unit_abreviation = m.unit_abreviation;
      material.state_name = m.state_name;
      material.treatment_name = m.treatment_name;
    } else {
      newMaterials.push(m);
    }
    setErrors({});
    setWeighing({ ...weighing, materials: newMaterials, totalWeight: getTotalMaterial(newMaterials, weighing.totalWeight) });
  }

  async function possibleCompositions(route_event_id: number): Promise<IComposition[]> {
    let routeEvent = await RouteEventsService.getById(route_event_id);
    let allCompositions = await getComposition(routeEvent?.packs);
    return allCompositions;
  }

  async function getComposition(packs: number[] | undefined) {
    let composition: IComposition[] = [];
    let allPacks = await PackService.getAll();
    packs?.forEach((residue_id) => {
      let pack = allPacks.find((r) => r.id === residue_id);
      if (pack) {
        composition = [...composition, ...pack.composition];
      }
    });
    return composition;
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="sm" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>PESAGEM </PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/weighings');
              }}
            />
          </Container>

          <Separator />
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                {/* <Typography variant="h6" sx={{ width: '100%', padding: '16px 0 0 16px' }}>
                  Informações
                </Typography> */}
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{}} margin="dense">
                      <TextField key="weighing.id" label="Id" disabled name="weighing.id" value={weighing.id} onChange={handleChange} error={errors.id} placeholder="Id" />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{}} margin="dense" style={{ textAlign: 'left' }} size="small">
                      <InputLabel>Rota</InputLabel>
                      <Select
                        key="routeEvent.route_event_id"
                        label="Rotas"
                        name="weighing.route_event_id"
                        value={weighing.route_event_id}
                        onChange={handleChange}
                        error={errors.route_event_id}
                        placeholder="Rota">
                        {allRouteEvents.map((re) => (
                          <MenuItem key={'cl' + re.id} value={re.id}>
                            {formatDate(re.date) + ' - ' + re.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} textAlign={'start'}>
                  <CardsContainerModal height="175px" title={'Coletas '} openModal={() => console.log(true)} plusIcon="false">
                    {weighing.collectEvents &&
                      weighing.collectEvents.map((ce) => <SubCardComponent key={ce.id} id={ce.id} content={' ' + ce.id + ' - ' + ce.collect?.generator?.name} format={1} onClick={() => {}} />)}
                  </CardsContainerModal>
                </Grid>
                <Grid item xs={12} md={12} textAlign={'start'}>
                  <CardsContainerModal height="175px" title={'Materiais '} openModal={() => handleNewMaterial()} plusIcon="true" error={errors.materials || ''}>
                    {weighing.materials &&
                      weighing.materials.map((m) => (
                        <SubCardComponent
                          key={m.residue_id}
                          type={['delete']}
                          id={m.residue_id}
                          content={' ' + m.residue_name + ' - (' + m.quantity + ') ' + m.container_name + ' ' + m.size}
                          value={m}
                          format={2}
                          onDelete={handleDeleteMaterial}
                          onClick={handleEditMaterial}
                        />
                      ))}
                  </CardsContainerModal>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{}} margin="dense" error={errors.totalWeight}>
                      <TextField
                        key="weighing.totalWeight"
                        label="Peso Total"
                        name="weighing.totalWeight"
                        value={weighing.totalWeight}
                        error={errors.totalWeight}
                        onChange={handleChange}
                        placeholder="Peso Total"
                        disabled={weighing.materials.length > 0}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={weighing.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(weighing);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/weighings');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>
          <CollectMaterialModal
            initialValue={material}
            allCompositions={allCompositions}
            open={modalMaterial}
            origin="WeighingForm"
            onClose={() => setModalMaterial(false)}
            onSubmit={handleIncludeMaterial}
          />
          <ConfirmModal title="Deleção de Pesagem" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
