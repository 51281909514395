import { useEffect, useState } from 'react';
import styled, { styled as sx } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, PageTitle } from '../../components/GlobalStyleds';
import PageStructure from '../../components/PageStructure';
import { Grid, Select, MenuItem, Stack, InputLabel, FormControl, FormControlLabel, Button, DialogActions, Container, Typography, TextField, FormLabel, FormGroup, Checkbox } from '@mui/material';

import { IPositiveImpact, blankPositiveImpact } from '../../models/PositiveImpact';
import { ICompany } from '../../models/Company';

import { toast } from 'react-toastify';

import PositiveImpactService from '../../services/PositiveImpactService';
import { validateRequired } from '../../utils/validations';

import { getModelFromField } from '../../utils/MyLib';
import { ConfirmModal } from '../../components/ConfirmModal';
import { Dialog } from '@material-ui/core';
import { ReactComponent as CloseModal } from '../../assets/closeModalIcon.svg';
import { IResidue } from '../../models/Residue';
import ResidueService from '../../services/ResidueService';
import { ITreatment } from '../../models/Treatment';
import TreatmentService from '../../services/TreatmentService';

export default function PositiveImpactForm() {
  const [positiveImpact, setPositiveImpact] = useState<IPositiveImpact>(blankPositiveImpact);
  const [errors, setErrors] = useState<any>({});
  const [confirmMessage, setConfirmMessage] = useState(' Deseja excluir o Estado de Residuo?');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [allResidues, setAllResidues] = useState<IResidue[]>([]);
  const [allTreatments, setAllTreatments] = useState<ITreatment[]>([]);

  const navigate = useNavigate();

  let { id } = useParams();

  //Get all collectors
  async function fetchData() {
    try {
      let allResidues = await ResidueService.getAll();
      setAllResidues(allResidues);
      let allTreatments = await TreatmentService.getAll();
      setAllTreatments(allTreatments);
      let positiveImpact = blankPositiveImpact;
      if (Number(id)) {
        positiveImpact = await PositiveImpactService.getById(Number(id));
      }
      setPositiveImpact(positiveImpact);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    setErrors({});
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Preencha os campos corretamente!');
    }
  }, [errors]);

  function validateErrors(positiveImpact: IPositiveImpact) {
    let errors: any = {};
    if (!positiveImpact.residue_id) errors.name = 'Obrigatorio';
    if (!positiveImpact.treatment_id) errors.name = 'Obrigatorio';
    return errors;
  }

  async function save(positiveImpact: IPositiveImpact) {
    console.log(positiveImpact);
    if (positiveImpact.id) {
      if (await PositiveImpactService.update(positiveImpact)) {
        toast.success('Impacto salva com sucesso');
      }
    } else {
      let newPositiveImpact = await PositiveImpactService.create(positiveImpact);
      if (newPositiveImpact.id > 0) {
        positiveImpact.id = newPositiveImpact.id;
        toast.success('Impacto criada com sucesso');
      }
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    const errors = validateErrors(positiveImpact);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      console.log(positiveImpact);
      await save(positiveImpact);
      navigate('/positive_impacts', { state: { reload: true } });
    }
  }

  function handleDeleteConfirmation(positiveImpact: IPositiveImpact) {
    setConfirmMessage('Deseja excluir "' + positiveImpact.residue_name + '" ?');
    setShowConfirmationModal(true);
  }
  async function handleDelete() {
    setShowConfirmationModal(false);
    if (await PositiveImpactService.delete(positiveImpact.id)) {
      toast.success('Estado Resíduo apagado com sucesso');
      positiveImpact.id = -1;
      navigate('/positive_impacts', { state: { reload: true } });
    }
  }

  //Get values on form inputs and put into the model PositiveImpact
  async function handleChange(e: any) {
    let { model, field } = getModelFromField(e.target.name);
    let value = e.target.value;
    switch (field) {
      default:
        setPositiveImpact({ ...positiveImpact, [field]: value });
        break;
    }
  }

  return (
    <PageStructure>
      <PageContainer>
        <Dialog open={true} maxWidth="sm" disableEnforceFocus={true}>
          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <PageTitle>CADASTRO DE IMPACTO POSITIVO</PageTitle>
            <CloseModal
              onClick={() => {
                navigate('/positive_impacts');
              }}
            />
          </Container>

          <Container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ width: '100%', m: 1 }} margin="dense">
                      <TextField key="positiveImpact.id" label="Id" name="positiveImpact.id" value={positiveImpact.id} onChange={() => {}} error={errors.id} placeholder="Id" disabled />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
                      <InputLabel>Resíduo</InputLabel>
                      <Select
                        key="positiveImpact.residue_id"
                        label="Resíduo"
                        name="positiveImpact.residue_id"
                        value={positiveImpact.residue_id}
                        onChange={handleChange}
                        error={errors.residue_id}
                        placeholder="Resíduo">
                        {allResidues.map((r) => (
                          <MenuItem key={'r' + r.id} value={r.id}>
                            {r.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Stack>
                    <FormControl sx={{ m: 1, width: '100%' }} margin="dense" style={{ textAlign: 'left' }}>
                      <InputLabel>Tratamento</InputLabel>
                      <Select
                        key="positiveImpact.treatment_id"
                        label="Tratamento"
                        name="positiveImpact.treatment_id"
                        value={positiveImpact.treatment_id}
                        onChange={handleChange}
                        error={errors.treatment_id}
                        placeholder="Tratamento">
                        {allTreatments.map((r) => (
                          <MenuItem key={'r' + r.id} value={r.id}>
                            {r.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.avoid_co2"
                        label="CO2 Evitado"
                        name="positiveImpact.avoid_co2"
                        value={positiveImpact.avoid_co2}
                        onChange={handleChange}
                        placeholder="CO2 Evitado"
                        error={errors.avoid_co2}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.ref_avoid_co2"
                        label="Ref.CO2 Evitado"
                        name="positiveImpact.ref_avoid_co2"
                        value={positiveImpact.ref_avoid_co2}
                        onChange={handleChange}
                        placeholder="Ref.CO2 Evitado"
                        error={errors.ref_avoid_co2}
                      />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.water_saved"
                        label="Agua Evitado"
                        name="positiveImpact.water_saved"
                        value={positiveImpact.water_saved}
                        onChange={handleChange}
                        placeholder="Agua Evitado"
                        error={errors.water_saved}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.ref_water_saved"
                        label="Ref.Agua Evitado"
                        name="positiveImpact.ref_water_saved"
                        value={positiveImpact.ref_water_saved}
                        onChange={handleChange}
                        placeholder="Ref.Agua Evitado"
                        error={errors.ref_water_saved}
                      />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.energy_saved"
                        label="Energia salva"
                        name="positiveImpact.energy_saved"
                        value={positiveImpact.energy_saved}
                        onChange={handleChange}
                        placeholder="Energia salva"
                        error={errors.energy_saved}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.ref_energy_saved"
                        label="Ref.Energia salva"
                        name="positiveImpact.ref_energy_saved"
                        value={positiveImpact.ref_energy_saved}
                        onChange={handleChange}
                        placeholder="Ref.Energia salva"
                        error={errors.ref_energy_saved}
                      />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.oil_saved"
                        label="Petróleo"
                        name="positiveImpact.oil_saved"
                        value={positiveImpact.oil_saved}
                        onChange={handleChange}
                        placeholder="Petróleo"
                        error={errors.oil_saved}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.ref_oil_saved"
                        label="Ref.Petróleo"
                        name="positiveImpact.ref_oil_saved"
                        value={positiveImpact.ref_oil_saved}
                        onChange={handleChange}
                        placeholder="Ref.Petróleo"
                        error={errors.ref_oil_saved}
                      />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.minerals_saved"
                        label="Mineral"
                        name="positiveImpact.minerals_saved"
                        value={positiveImpact.minerals_saved}
                        onChange={handleChange}
                        placeholder="Mineral"
                        error={errors.minerals_saved}
                      />
                    </FormControl>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.ref_minerals_saved"
                        label="Ref.Mineral"
                        name="positiveImpact.ref_minerals_saved"
                        value={positiveImpact.ref_minerals_saved}
                        onChange={handleChange}
                        placeholder="Ref.Mineral"
                        error={errors.ref_minerals_saved}
                      />
                    </FormControl>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.mineral_name"
                        label="Nome do Mineral"
                        name="positiveImpact.mineral_name"
                        value={positiveImpact.mineral_name}
                        onChange={handleChange}
                        placeholder="Nome do Mineral"
                        error={errors.mineral_name}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12} m={1}>
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField key="positiveImpact.price" label="Preço" name="positiveImpact.price" value={positiveImpact.price} onChange={handleChange} placeholder="Preço " error={errors.price} />
                    </FormControl>
                    <FormControl sx={{ width: '50%', m: 1 }} margin="dense">
                      <TextField
                        key="positiveImpact.ref_price"
                        label="Ref.Preco"
                        name="positiveImpact.ref_price"
                        value={positiveImpact.ref_price}
                        onChange={handleChange}
                        placeholder="Ref.Preco"
                        error={errors.ref_price}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>

              <DialogActions sx={{ p: '1.25rem' }}>
                <Button
                  color="warning"
                  disabled={positiveImpact.id === 0}
                  onClick={() => {
                    handleDeleteConfirmation(positiveImpact);
                  }}>
                  Apagar
                </Button>
                <Button
                  onClick={() => {
                    navigate('/positive_impacts');
                  }}>
                  Voltar
                </Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                  Salvar
                </Button>
              </DialogActions>
            </form>
          </Container>

          <ConfirmModal title="Deleção do Impacto do  Resíduo" message={confirmMessage} isOpen={showConfirmationModal} onConfirm={handleDelete} onCancel={() => setShowConfirmationModal(false)} />
        </Dialog>
      </PageContainer>
    </PageStructure>
  );
}
