import { ICompany, blankCompany } from './Company';

export interface IVehicle {
  id: number;
  name: string;
  type: string;
  description: string;
  capacity: number;
  fuel: string;
  consumption: number;
  img?: string;
  status?: number; //novo
  license_plate?: string; //novo

  company_id: number;
  company?: ICompany;

  created_at?: Date;
  updated_at?: Date;
}

export const blankVehicle: IVehicle = {
  id: 0,
  name: '',
  type: '',
  description: '',
  capacity: 0,
  fuel: '',
  consumption: 0,
  img: '',
  status: 0,
  license_plate: '',

  company_id: 0,
  company: blankCompany
};

export const vehicleStatus = [
  { id: 0, name: 'Em operação', color: '#35A1B7' }, //35A1B7
  { id: 1, name: 'Em manutenção', color: '#730E18' } //730E18
];

export const vehicleProps = Object.keys(blankVehicle);
