import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { ICollectEvent, collectEventStatus } from '../../models/CollectEvent';
import { Chip } from '@mui/material';
import useCollectStore from '../../contexts/CollectStore';
import CollectEventService from '../../services/CollectEventService';
import { CelebrationOutlined } from '@mui/icons-material';
import { Draggable } from '@hello-pangea/dnd';

const SubCardContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin: 0.5px;
  width: 100%;
  border-radius: var(--borderRadius-m);
  box-shadow: var(--shadow-1);
  background: ${(props) => (props.selected ? 'var(--bgColor-2)' : 'var(--bgColor-1)')};
  border: 1px solid ${(props) => (props.selected ? 'black' : 'var(--bgColor-1)')};
`;

export const SubCardText = styled.p`
  font-size: 12px;
  font-weight: 700;
`;

type Props = {
  ce: ICollectEvent;
  index: number;
  content?: string;
  status: number;
  onDoubleClick: (id: number) => void;
};

export default function CollectEventSubCard({ ce, index, content, status, onDoubleClick }: Props) {
  const [collect_id, setSelectedCollect, unsetSelectedCollect] = useCollectStore((state) => [state.collect_id, state.setSelectedCollect, state.unsetSelectedCollect]);
  const [collectEvent, setCollectEvent] = useState(ce);
  // useEffect(() => {
  //   CollectEventService.getById(collectEvent.id).then((ce) => {
  //     if (ce.sequence !== collectEvent.sequence) {
  //       collectEvent.sequence = ce.sequence;
  //     }
  //   });
  // }, [collectEvent.sequence]);

  function handleDoubleClick() {
    onDoubleClick(collectEvent.id);
  }
  function handleClick() {
    if (collectEvent.id === collect_id) {
      unsetSelectedCollect();
      return;
    }
    setSelectedCollect(collectEvent.id);
  }
  async function sort() {
    let ce = collectEvent;
    if (ce.sequence > 1) {
      ce.sequence = ce.sequence - 1;
      CollectEventService.update(ce);
      setCollectEvent(ce);
    }
  }
  return (
    <Draggable draggableId={ce.id + ''} index={index}>
      {(provided) => (
        <SubCardContainer onDoubleClick={handleDoubleClick} selected={collectEvent.id === collect_id} ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
          <div style={{ display: 'flex' }}>
            {/* <ArrowDropUp sx={{ color: 'var(--primary-400)', cursor: 'pointer' }} onClick={() => sort()} /> */}
            <SubCardText onClick={handleClick}>{content}</SubCardText>
          </div>
          <Chip onClick={handleClick} label={collectEventStatus[status].name} style={{ background: collectEventStatus[status].color, color: 'white' }} />
        </SubCardContainer>
      )}
    </Draggable>
  );
}
