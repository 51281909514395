export interface IMaterial {
  residue_id: number;
  container_id: number;
  size: string;
  quantity: number;
  weight: number;
  unit_id: number;
  state_id: number;
  treatment_id: number;
  batch_number?: string;

  residue_name?: string;
  container_name?: string;
  unit_name?: string;
  unit_abreviation?: string;
  state_name?: string;
  treatment_name?: string;
}

export const blankMaterial: IMaterial = {
  residue_id: 0,
  container_id: 1,
  size: 'Pequena',
  quantity: 1,
  weight: 0,
  unit_id: 1,
  state_id: 1,
  treatment_id: 1,
  batch_number: '',

  // These are not part of the form
  residue_name: '',
  container_name: '',
  unit_name: '',
  unit_abreviation: '',
  state_name: '',
  treatment_name: ''
};
