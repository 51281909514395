import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  display: 'flex',
  borderWidth: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  cursor: 'pointer',
  backgroundColor: '#C4CCDF',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

type AvatarUploadProps = {
  src: string;
  imgDefault?: string;
  width?: number;
  height?: number;
  radius?: number;
  handleUpload: (files: []) => void;
};

export function AvatarUpload({ src, imgDefault, width, height, radius, handleUpload }: AvatarUploadProps) {
  const [files, setFiles] = useState<(File & { preview: string })[]>([]);
  const [defaultImg, setDefaultImg] = useState<string>('');
  useEffect(() => {
    setDefaultImg(imgDefault || '/assets/Man_Silhouette.png');
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length !== 0) {
        setFiles(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
        handleUpload(acceptedFiles);
      }
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: { 'image/*': [] }
  });

  const baseStyleWithRadius = {
    ...baseStyle,
    borderRadius: radius
  };

  const thumbsContainer = {
    width: '100%',
    height: '100%',
    borderRadius: radius,
    objectPosition: 'center'
  };

  const style = useMemo(
    () => ({
      ...baseStyleWithRadius,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <img style={thumbsContainer} src={file.preview} alt={file.name} />
    </div>
  ));

  useEffect(
    () => () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.preview);
      });
    },
    [files]
  );

  return (
    <section style={{ margin: '1rem', width: width, height: height }}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />

        {files.length > 0 && thumbs}
        {files.length === 0 && src.length !== 0 && <img style={thumbsContainer} src={src} alt="" />}
        {files.length === 0 && src.length === 0 && <img style={thumbsContainer} src={process.env.PUBLIC_URL + defaultImg} alt=" " />}
      </div>
    </section>
  );
}
