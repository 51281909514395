import './Autentication.css';
import logo from '../../assets/logo3.png';
import { LoginTextField } from '../../components/TextFields';

import { useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../utils/validations';

export default function ForgetPasswordPage() {
  const navigate = useNavigate();
  interface IForm {
    email: string;
  }

  const [formData, setFormData] = useState<IForm>({ email: '' });
  const [errors, setErrors] = useState<any>({});

  function validateErrors(formData: IForm) {
    let errors: any = {};
    if (!validateEmail(formData.email)) errors.email = 'Email invalido';
    return errors;
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const errors = validateErrors(formData);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await toast.promise(api.post('users/pw', formData), {
          pending: 'Aguarde...',
          success: 'Instruções enviadas para o email informado!',
          error: 'Email não encontrado!'
        });
        navigate('/confirm-password');
      } catch (error) {}
    }
  }
  async function handleChange(e: any) {
    let field = e.target.name;
    let value = e.target.value;
    setFormData({ ...formData, [field]: value });
  }

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit}>
        <div className="login-container">
          <img className="login-img" src={logo} alt="Siri Logo"></img>
          <h1>Esquecí a senha</h1>
          <p>Informe um email e você receberá nele as instruções para recuperar a senha! </p>

          <LoginTextField key="email" label="Email" name="email" value={formData.email} onChange={handleChange} error={errors.email} helperText={errors.email} />

          <button type="submit" className="login-button">
            REDEFINIR SENHA
          </button>
          <a href="login">Retornar</a>
        </div>
      </form>
    </div>
  );
}
