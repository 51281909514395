export interface IResidueClass {
  id: number;
  name: string;
  codInea: number;
}

export const blankResidueClass: IResidueClass = {
  id: 0,
  name: '',
  codInea: 0
};

export const residueClassProps = Object.keys(blankResidueClass);
