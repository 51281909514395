import { useEffect, useMemo, useState, useRef } from 'react';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_Row, type MRT_TableInstance } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Download } from '@mui/icons-material';
import { IRoute } from '../../models/Route';
import PageStructure from '../../components/PageStructure';
import { PageContainer, PageContent, PageHeader, PageFooter, PageTitle, LoadWrapper } from '../../components/GlobalStyleds.jsx';
import RouteService from '../../services/RouteService';
import { ICompany } from '../../models/Company';
import ReactLoading from 'react-loading';
import { Typography, Stack, Button } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

import 'dayjs/locale/pt-br';

import { mkConfig, generateCsv, download } from 'export-to-csv';
import CompanyService from '../../services/CompanyService';
import { useNavigate } from 'react-router-dom';
import { INeighborhood } from '../../models/Neighborhood';
import NeighborhoodService from '../../services/NeighborhoodService';
import { weekday } from '../../utils/MyLib';
import CardsContainer from '../../components/CardsContainer';

export default function RoutePage() {
  const [routesData, setRoutesData] = useState<IRoute[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const tableInstanceRef = useRef<MRT_TableInstance<IRoute>>(null);
  const [rowSelection, setRowSelection] = useState({});
  let cardChoiced = 0;
  let allRoutes: IRoute[] = [];
  let allCompanies: ICompany[] = [];
  let allNeighborhoods: INeighborhood[] = [];
  const [cards, setCards] = useState([
    { id: 0, title: 'Todas as Rotas', content: 0 },
    { id: 1, title: 'Rotas Recorrentes', content: 0 },
    { id: 2, title: 'Rotas Extraordinárias', content: 0 },
    { id: 3, title: 'Estados', content: 0 },
    { id: 4, title: 'Cidades', content: 0 },
    { id: 5, title: 'Empresas ', content: 0 }
  ]);

  async function fetchData() {
    setLoading(true);
    allCompanies = await CompanyService.getAll();
    allNeighborhoods = await NeighborhoodService.getAll();
    allRoutes = await RouteService.getAll();
    allRoutes.map((r) => {
      r.company_name = allCompanies.find((c) => c.id === r.company_id)?.fantasy_name;
      try {
        r.city = allNeighborhoods.find((n) => n.id === r.schedule[0].neighborhood_id)?.city;
        r.state = allNeighborhoods.find((n) => n.id === r.schedule[0].neighborhood_id)?.state;
      } catch (e) {
        r.city = 'Cidade não encontrada';
        r.state = 'Estado não encontrado';
      }
      r.schedule.sort((a, b) => a.initial_time.localeCompare(b.initial_time));
      r.neighborhoods_name = r.schedule.map((s) => '[' + s.neighborhood_name + ']');

      if (r.recurrent) {
        r.days = r.weekdays.map((s) => weekday[s]).join(', ');
      } else {
        r.days = r.dates.map((s) => s).join(', ');
      }
      return r;
    });
    let uniqueCities = new Set();
    let uniqueStates = new Set();
    let uniqueCompanies = new Set();
    cards[0].content = allRoutes.length;
    cards[1].content = allRoutes.filter((r) => r.recurrent).length;
    cards[2].content = allRoutes.filter((r) => !r.recurrent).length;
    allRoutes.forEach((r) => {
      uniqueCities.add(r.city);
      uniqueStates.add(r.state);
      uniqueCompanies.add(r.company_name);
    });
    cards[3].content = uniqueCities.size;
    cards[4].content = uniqueStates.size;
    cards[5].content = uniqueCompanies.size;
    allRoutes = cardChoiced === 1 ? allRoutes.filter((r) => r.recurrent) : allRoutes;
    allRoutes = cardChoiced === 2 ? allRoutes.filter((r) => !r.recurrent) : allRoutes;
    setCards([...cards]);
    setLoading(false);
    setRoutesData(allRoutes);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function handleClickFilterCard(id: number) {
    cardChoiced = id;
    fetchData();
  }

  async function handleEditRow(row: MRT_Row<IRoute>) {
    navigate(`/routes/${Number(row.id)}`);
  }

  function handleNew(): void {
    navigate('/routes/0');
  }

  //TODO: Implementar exportação
  function handleExport() {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const data = routesData.map((r) => {
      return {
        id: r.id,
        name: r.name,
        company: r.company_name,
        city: r.city,
        neighborhoods: r.neighborhoods_name,
        schedule: r.days
      };
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  async function handleActive(e: any) {
    for (var prop in rowSelection) {
      let route = await RouteService.getById(Number(prop));
      route.active = e.target.name === 'btnActive' ? true : false;
      await RouteService.update(route);
    }
    fetchData();
  }

  const columns = useMemo<MRT_ColumnDef<IRoute>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        size: 5
      },
      {
        accessorKey: 'active',
        header: 'Status',
        Cell: ({ row }) => (row.original.active ? <CheckCircleOutlineSharpIcon sx={{ color: 'green' }} /> : <span />),
        size: 20
      },

      {
        accessorKey: 'name',
        id: 'name', //id is still required when using accessorFn instead of accessorKey
        header: 'Nome da Rota'
      },
      {
        accessorKey: 'company.fantasy_name',
        header: 'Empresa'
      },
      {
        accessorKey: 'city',
        header: 'Cidade'
      },
      {
        accessorKey: 'days',
        header: 'Agenda'
      },
      {
        accessorKey: 'neighborhoods_name',
        header: 'Bairros'
      }
    ],

    []
  );
  // Define a custom function to format the date

  return (
    <PageStructure>
      <PageContainer>
        <PageHeader style={{ position: 'relative' }}>
          <PageTitle>PAINEL DE ROTAS</PageTitle>
          <Button
            size="small"
            sx={{
              position: 'absolute',
              top: '20%',
              right: 0,
              bottom: 0,
              transform: 'translateY(-50%)',
              width: 150,
              height: 50
            }}
            color="primary"
            onClick={() => navigate('/routes_events')}
            variant="contained">
            Eventos de Rota
          </Button>
          <CardsContainer
            cards={cards}
            choiced={cardChoiced}
            onChange={(id) => {
              handleClickFilterCard(id);
            }}></CardsContainer>
        </PageHeader>
        <PageContent>
          {routesData.length === 0 && (
            <Stack direction="column" sx={{ marginTop: '100px' }} justifyContent="center" alignItems="center" spacing={0}>
              <Typography variant="h4">Nenhuma rota encontrado</Typography>

              <PersonSearchIcon sx={{ fontSize: 120 }} />

              <Button
                size="medium"
                sx={{
                  width: 300,
                  height: 50
                }}
                color="primary"
                onClick={handleNew}
                variant="contained"
                startIcon={<AccountCircle />}>
                Criar Rota
              </Button>
            </Stack>
          )}

          {loading && (
            <LoadWrapper>
              <ReactLoading type="spin" color="#4084F8" height={80} width={80} />
            </LoadWrapper>
          )}
          {routesData.length > 0 && !loading && (
            <MaterialReactTable
              tableInstanceRef={tableInstanceRef}
              getRowId={(row) => row.id.toString()}
              localization={MRT_Localization_PT_BR}
              columns={columns}
              data={routesData}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              initialState={{ columnVisibility: { id: false, city: false } }}
              muiTableBodyRowProps={({ row }) => ({
                onDoubleClick: (event) => {
                  handleEditRow(row);
                }
              })}
              renderTopToolbarCustomActions={({ table }) => (
                <Stack direction="row" spacing={1}>
                  <Button color="primary" onClick={handleNew} variant="contained" startIcon={<AccountCircle />}>
                    Novo
                  </Button>
                  <Button name="btnActive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Ativar
                  </Button>
                  <Button name="btnInactive" color="secondary" onClick={handleActive} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Desativar
                  </Button>

                  <Button color="secondary" onClick={handleExport} startIcon={<Download />} variant="contained" disabled={Object.keys(rowSelection).length ? false : true}>
                    Exportar
                  </Button>
                </Stack>
              )}
            />
          )}
        </PageContent>
        <PageFooter></PageFooter>
      </PageContainer>
    </PageStructure>
  );
}
