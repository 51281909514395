import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ flexdir }) => (flexdir ? flexdir : 'column')};
  flex: ${({ flex }) => (flex ? flex : '')};
  padding: ${({ pv }) => (pv ? pv : 0)} ${({ ph }) => (ph ? ph : 0)};
  width: ${({ width }) => (width ? width : 'auto')};
  max-width: ${({ mwidth }) => (mwidth ? mwidth : 'auto')};
  max-height: ${({ mheight }) => (mheight ? mheight : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'auto')};
  background-color: ${({ color }) => (color ? color : 'tramsparent')};
  flex-wrap: wrap;
  min-width: 260px;
  overflow-y: ${({ overflow }) => (overflow ? overflow : 'visible')};
`;
const BasicContainer = ({ children, ph, pv, flexdir, width, justify, alignItems, color, height, mheight, flex, mwidth, overflow }) => {
  return (
    <Container
      ph={ph}
      pv={pv}
      flexdir={flexdir}
      width={width}
      justify={justify}
      alignitems={alignItems}
      color={color}
      height={height}
      flex={flex}
      mwidth={mwidth}
      mheight={mheight}
      overflow={overflow}>
      {children}
    </Container>
  );
};

export default BasicContainer;
